import React from 'react';
import { GET } from '../frameworks/HttpClient';
import { Toast } from '../frameworks/Toast';


export default function useDropdownItem ({url, manual=false, text_renderer=(item) => item.name}) {
  const [response, setResponse] = React.useState([]);

  const fetchData = async () => {
    try {
      const response = await GET(url);
      setResponse(response.data.map(item => {
        return {
          ...item, 
          key: item.id, 
          value: item.id, 
          text: text_renderer(item)
        }
      }))
    } catch (error) {
      Toast.error(error.errorMessages);
    }
  };

  React.useEffect(() => {
    if (!manual) {
      fetchData();
    }
  }, [])

  return { response, fetchData };
}