import React from 'react';
import { Grid, Header, Icon, Menu, Pagination, Segment } from 'semantic-ui-react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import { Line } from 'react-chartjs-2';
import * as _ from 'lodash';
import { formatComma } from '../../frameworks/Util';
import { COLOR_SET } from '../../constance/utils';
import propTypes from 'prop-types';
import { Media } from '../../AppMedia';
import Swipable from '../common/Swipable';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);


const Widget = (props) => {
  const {
    data,
    title,
    value,
    icon,
    color,
    labelKey,
    datasetKey,
    unit,
  } = props

  const [chartData, setChartData] = React.useState({ datasets: [] });
  const chartRef = React.useRef();
  const options = {
    responsive: true,
    aspectRatio: 4,
    layout: { padding: 0 },
    plugins: {
      legend: { display: false },
      title: { display: false },
      tooltip: {
        callbacks: { label: (context) => `${context.formattedValue} ${unit}` }
      }
    },
    scales: {
      x: { display: false },
      y: { display: false },
    }
  }

  const createGradient = (ctx, area) => {
    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
    gradient.addColorStop(0.4, COLOR_SET[color].backgroundColor)
    gradient.addColorStop(0, COLOR_SET['white'].noneColor)
    return gradient
  }

  const renderRecentInformation = () => {
    return  (
      <div style={{ textAlign: 'center' }}>
        <span style={{fontSize: 36, fontWeight: 'bold', color: color ? COLOR_SET[color].code : '#000000' }}>
          {formatComma(value)}
        </span> {unit}
      </div>
    );
  }

  React.useEffect(() => {
    const chart = chartRef.current;
    if (!chart) {
      return;
    }
    const labels = data.map(e => _.get(e, labelKey, ''))
    const datasets = data.map(e => _.get(e, datasetKey, 0))
    setChartData({
      labels: labels,
      datasets: [{
        data: datasets,
        borderColor: COLOR_SET[color].lineColor,
        backgroundColor: createGradient(chart.ctx, chart.chartArea),
        pointBackgroundColor: COLOR_SET[color].lineColor,
        pointRadius: 4,
        pointHitRadius: 10,
        pointHoverRadius: 8,
        lineTension: 0,
        fill: true,
      }]
    });
  }, [data])

  return (
    <Segment style={{ padding: 0 }}>
      <Header textAlign='left' style={{paddingTop: '1em', paddingLeft: '1em'}}>
        <Icon name={icon} color={color}/>
        {title}
      </Header>
      {renderRecentInformation()}
      <Line
        ref={chartRef}
        data={chartData}
        options={options}/>
    </Segment>
  )
}


export default function LineWidgets(props) {
  const {
    data,
    widgets,
  } = props;

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [lineData, setLineData] = React.useState([])

  React.useEffect(() => { setLineData(_.reverse(data)) }, [data])

  return (
    <>
      <Media at='sm' >
        <Swipable
          style={{ marginBottom: '1rem' }}
          onSwipeLeft={() => setActiveIndex(i => (i < widgets.length-1) ? i+1 : i)}
          onSwipeRight={() => setActiveIndex(i => (i > 0) ? i-1 : 0)}
        >
          <Menu secondary>
            {widgets.map((widget, index) => (
              <Menu.Item
                name={widget.title}
                icon={widget.icon}
                color={widget.color}
                active={index === activeIndex}
                onClick={() => setActiveIndex(index)}/>
            ))}
          </Menu>
          {widgets.map((widget, index) => activeIndex === index && (
            <Widget data={data} {...widget}/>
          ))}
        </Swipable>
      </Media>
      <Media greaterThanOrEqual='md'>
        <Grid columns={widgets.length} stackable>
          {widgets.map(widget => (
            <Grid.Column>
              <Widget data={lineData} {...widget}/>
            </Grid.Column>
          ))}
        </Grid>
      </Media>
    </>
  )
}


LineWidgets.defaultProps = {
  data: [],
  widgets: [],
}

LineWidgets.propsType = {
  data: propTypes.array,
  widgets: propTypes.array,
}