export const StoreModel = {
  id: null,
  name: '',
  branch: '',
  tax_id: '',
  telephone: '',
  email: '',
  promptpay_account_code: '',
  bank_account_code: '',
  bank_account_name: '',
  tax_rate: '',
  tax_type: '',
  pos_id: '',
  building_name: '',
  room_no: '',
  floor: '',
  village_name: '',
  address_no: '',
  moo: '',
  soi: '',
  road: '',
  province: '',
  district: '',
  sub_district: '',
  zip_code: '',
  logo: '',
  receipt_header: '',
  receipt_footer: '',
  is_active: true,
  use_staff: false
}

export const TaxTypeChoices = [
  { key: 'include', value: 'include vat', text: 'รวมอยู่ในราคา'},
  { key: 'no', value: 'no vat', text: 'ไม่มี'},
]

export const BankChoices = [
  { key: '006', value: '006', text: 'ธนาคารกรุงไทย'},
  { key: '002', value: '002', text: 'ธนาคารกรุงเทพ'},
  { key: '014', value: '014', text: 'ธนาคารไทยพาณิชย์'},
  { key: '004', value: '004', text: 'ธนาคารกสิกรไทย'},
  { key: '025', value: '025', text: 'ธนาคารกรุงศรีอยุธยา'},
  { key: '011', value: '011', text: 'ธนาคารทหารไทย'},
  { key: '065', value: '065', text: 'ธนาคารธนชาต'},
  { key: '030', value: '030', text: 'ธนาคารออมสิน'},
  { key: '034', value: '034', text: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร'},
  { key: '069', value: '069', text: 'ธนาคารเกียรตินาคิน'},
  { key: '022', value: '022', text: 'ธนาคารซีไอเอ็มบี'},
  { key: '033', value: '033', text: 'ธนาคารอาคารสงเคราะห์'},
  { key: '073', value: '073', text: 'ธนาคารแลนด์แอนด์เฮ้าส์'},
  { key: '066', value: '066', text: 'ธนาคารอิสลาม'},
  { key: '024', value: '024', text: 'ธนาคารยูโอบี'},
  { key: '067', value: '067', text: 'ธนาคารทิสโก้'},
  { key: '035', value: '035', text: 'ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย'},
  { key: '070', value: '070', text: 'ธนาคารไอซีบีซี'},
  { key: '071', value: '071', text: 'ธนาคารไทยเครดิตเพื่อรายย่อย'},
  { key: '098', value: '098', text: 'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย'},
]