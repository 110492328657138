export const ReportSummaryTodayModel = {
  total_gain: '',
  total_discount: '',
  total_transaction: '',
  total_product_count: '',
  avg_sales: '',
  avg_product_count: '',
  ratio: {
    income_type: [],
    most_valuable_categories: [],
  }
}