import React from 'react';
import { Icon, Image } from 'semantic-ui-react';
import logo from '../../logo.png';

export default function CustomSearchResult ({ image, price, title, description }) {
  return [
    (
      <div key='image' className='image'>
        {image && <Image src={image}/>}
      </div>
    ),
    <div key='content' className='content'>
      {price && <div className='price'>{price}</div>}
      {title && <div style={{ fontWeight: 'bold' }}>{title}</div>}
      {description && <div className='description'>{description}</div>}
    </div>,
  ]
}