export const URL_AUTH = {
	API_TOKEN_AUTH: "/api/users/api-token-auth/",
	OFFICER_API_TOKEN_AUTH: "/api/users/officer-api-token-auth/",
	VERIFY_OTP: "/api/users/verify-otp/",
};

export const URL_COMMON = {
	PROVINCE: "/api/common/province/",
	DISTRICT: "/api/common/district/",
	SUB_DISTRICT: "/api/common/sub-district/",
	USER_MANUAL: "/api/common/user-manual/",
}

export const URL_CUSTOMER = {
	CUSTOMER: "/api/customer/customer/",
	CUSTOMER_TAX_INVOICE: "/api/customer/customer-tax-invoice-address/",
}

export const URL_PRODUCT = {
	PRODUCT: "/api/product/product/",
	PRODUCT_CATEGORY: "/api/product/product-category/",
	PRODUCT_BY_BARCODE: "/api/product/product-barcode/",
	AMOUNT_LOG: "/api/product/product-amount-log/",
	OFFICER_PRODUCT: "/api/product/officer-product/",
	OFFICER_PRODUCT_CATEGORY: "/api/product/officer-product-category/",
	PRODUCT_LINK: "/api/product/product-link/"
}

export const URL_STOCK = {
	STOCK_INPUT_TRANSACTION: "/api/stock/stock-input-transaction/",
	STOCK_EDIT_TRANSACTION: "/api/stock/stock-edit-transaction/",
	STOCK_OUTPUT_TRANSACTION: "/api/stock/stock-output-transaction/",
}

export const URL_SALE = {
	SALE_TRANSACTION: "/api/sale/sale-transaction/",
	DISABLE_SALE_TRANSACTION: "/api/sale/disable-sale-transaction/",
	TAX_INVOICE: "/api/sale/tax-invoice/",
	SALE_PRODUCT: "/api/sale/sale-product/",
}

export const URL_STORE = {
	STORE: "/api/store/store/",
	OFFICER_STORE: "/api/store/officer-store/",
	STORE_UPDATE_STAFF: "/api/store/store-update-staff/",
	STORE_TYPE: "/api/store/store-type/",
}

export const URL_REPORT = {
	REPORT_LINE_CHART: "/api/report/reports-line-chart/",
	REPORT_PIE_CHART: "/api/report/reports-pie-chart/",
	REPORT_TODAY: "api/report/reports-today/",
	SHARE_REPORT_SUMMARY: "api/report/share-report-summary/",
	SHARE_REPORT_DATE_SUMMARY: "api/report/share-report-date-summary/",
	SHARE_PRODUCT_SUMMARY: "api/report/share-product-summary/",
	SHARE_SALE_SUMMARY: "api/report/share-sale-summary/",
	SHARE_TAX_INVOICE: "api/report/share-tax-invoice/",
	SHARE_SALE_REPORT: "api/report/share-sales-report/",
	SHARE_SALE_TAX_REPORT: "api/report/share-sales-tax-report/",
	SHARE_STAFF_SHIFT_REPORT: "api/report/share-staff-shift-report/",
	SHARE_SALE_PRODUCT_REPORT: "api/report/sale-product-report/",
	SHARE_SALE_CATEGORY_REPORT: "api/report/sale-category-report/",
	SHARE_SALE_EMPLOYEE_REPORT: "api/report/sale-employee-report/",
	SHARE_PURCHASE_TYPE_REPORT: "api/report/purchase-type-report/"
}

export const URL_STAFF = {
	STAFF: "/api/staff/staff/",
	LIST: "/api/staff/list/",
	AUTHENTICATE: "/api/staff/authenticate/",
	STAFF_UPDATE_PIN: "/api/staff/staff-update-pin/",
	STAFF_SHIFT: "/api/staff/staff-shift/"
}

export const URL_AOT_RR = {
	AOT_RR: "/api/aot-rr/aot-rr/",
	AOT_RR_TRANSCATION: "/api/aot-rr/transaction",
}
