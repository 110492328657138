import React from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";
import LoginRedirectScreen from '../screens/LoginRedirectScreen';
import LoginScreen from '../screens/LoginScreen';
import MainScreen from '../screens/MainScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import OfficerLoginScreen from '../screens/OfficerLoginScreen';
import OfficerMainScreen from '../screens/OfficerMainScreen';
import CustomerListView from '../views/CustomerListView';
import ReportView from '../views/ReportView';
import SaleTransactionView from '../views/SaleTransactionView';
import StockListView from '../views/StockListView';
import StoreSettingView from '../views/StoreSettingView';
import OfficerOutlet from './OfficerOutlet';
import PrivateOutlet from './PrivateOutlet';
import TaxInvoiceListView from '../views/TaxInvoiceListView';
import AOTRRView from '../views/AOTRRView';
import { createBrowserHistory } from "history";
import ProductView from '../views/ProductViews';


export const PATH = {
  LOGIN: "/login",
  LOGIN_REDIRECT: "/redirect",
  OFFICER_LOGIN: "/officer/login",
  MAIN: "/",
  PRODUCT: "/product",
  CUSTOMER: "/customer",
  STOCK: "/stock",
  SALE: "/sale",
  PROMOTION: "/promotion",
  SETTING: "/setting",
  REPORT: "/report",
  OFFICER: "/officer",
  TAX_INVOICE: "/tax-invoice",
  AOT_RR: "/aot-rr",
}

export default function CustomRoutes(){
  const history = createBrowserHistory();
  const navigate = useNavigate();

  React.useEffect(() => {
    if(history.action === 'POP') {
      navigate(1)
    }
  }, [history])

  return (
    <Routes>
      <Route exact path={PATH.LOGIN} element={<LoginScreen/>} />
      <Route exact path={`${PATH.LOGIN_REDIRECT}/:username`} element={<LoginRedirectScreen/>} />
      <Route exact path={PATH.OFFICER_LOGIN} element={<OfficerLoginScreen/>} />
      <Route path={PATH.MAIN} element={<PrivateOutlet/>}>
        <Route path='' element={<MainScreen/>} />
        <Route path={PATH.PRODUCT} element={<ProductView/>} />
        <Route path={PATH.CUSTOMER} element={<CustomerListView/>} />
        <Route path={PATH.STOCK} element={<StockListView/>} />
        <Route path={PATH.SALE} element={<SaleTransactionView/>} />
        <Route path={PATH.PROMOTION} element={<SaleTransactionView/>} />
        <Route path={PATH.SETTING} element={<StoreSettingView/>} />
        <Route path={PATH.TAX_INVOICE} element={<TaxInvoiceListView/>} />
        <Route path={PATH.REPORT} element={<ReportView/>} />
        <Route path={PATH.AOT_RR} element={<AOTRRView/>} />
      </Route> 
      <Route path={PATH.OFFICER} element={<OfficerOutlet/>}>
        <Route path='' element={<OfficerMainScreen/>} />
      </Route>
      <Route path='*' element={<NotFoundScreen/>}/>
    </Routes>
  )
}