import React from "react";
import propTypes from "prop-types";
import { Button, Form } from "semantic-ui-react";


const CustomFileField = React.forwardRef((props, ref) => {
  const {
    isBase64,
    style,
    width,
    label,
    error,
    value,
    onChange,
    readOnly,
  } = props;

	const fileInputRef = React.useRef();

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    })
  }  

  return (
    <Form.Field style={{ ...style }} width={width}>
      <label>{label}</label>
      <input
        ref={fileInputRef}
        hidden
        type='file'
        onChange={async (e) => {
          if(isBase64) {
            const name = e.target.files[0].name;
            const file = await toBase64(e.target.files[0]);
            onChange({ file, name })
          } else {
            onChange(e.target.files[0])
          }
        }}/>
      <Form.Input
        action={
          <Button
            color='blue'
            labelPosition='right'
            icon='upload'
            content='เลือกไฟล์'
            type='button'
            disabled={readOnly}
            onClick={() => fileInputRef.current ? fileInputRef.current.click() : null}/>
        }
        readOnly
        error={error}
        value={value}
        placeholder={"กรุณาเลือกไฟล์..."}/>
    </Form.Field>
  )
});

export default React.memo(CustomFileField);

CustomFileField.defaultProps = {
  isBase64: false,
  style: {},
  width: null,
  label: "",
  error: null,
  value: null,
	onChange: () => null,
  readOnly: false,
}

CustomFileField.propTypes = {
  isBase64: propTypes.bool,
  style: propTypes.object,
  width: propTypes.string,
  label: propTypes.string,
  error: propTypes.string,
  value: propTypes.string,
	onChange: propTypes.func,
  readOnly: propTypes.bool,
}
