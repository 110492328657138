import React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { URL_COMMON } from '../../constance/urls';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ShareDocumentModal from './ShareDocumentModal';


export default function ManualButton(props) {
  const {
    isOfficer,
  } = props;

  const { size } = useWindowDimensions();

  const [shareManualOpen, setShareManualOpen] = React.useState(false);

  return (
    <>
      <ShareDocumentModal
        title='รับคู่มือการใช้งาน'
        open={shareManualOpen}
        onClose={() => setShareManualOpen(false)}
        url={`${URL_COMMON.USER_MANUAL}${isOfficer ? 'officer' : 'user'}/`}/>
      <Popup
        inverted
        trigger={
          <Button
            style={{ zIndex: 9999, position: 'fixed', bottom: '1rem', right: '1rem' }}
            circular
            icon='question'
            size='mini'
            onClick={() => setShareManualOpen(true)}/>
        }
        content={`คู่มือสำหรับ${isOfficer ? 'ผู้เชี่ยวชาญ' : 'ผู้ใข้งาน'}`}
        position='top left'/>
    </>
  )
}