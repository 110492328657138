import React from 'react';
import { Header, Icon, Segment, Menu, Button, Dimmer, Loader } from 'semantic-ui-react';
import { Media } from '../../AppMedia';
import * as _ from 'lodash';
import propTypes from 'prop-types';
import { COLOR_SET, TH_MONTH } from '../../constance/utils';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import { Line } from 'react-chartjs-2';
import useListItem from '../../hooks/useItemList';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);


export default function LineSegment(props) {
  const {
    fetchURL,
    timeScale,
    menus
  } = props;

  const { response, isLoading, errorMessages, fetchData } = useListItem({
    url: fetchURL,
    usePagination: true,
    params: { time_scale: timeScale },
  });

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedMenu, setSelectedMenu] = React.useState({});
  const [chartData, setChartData] = React.useState({ datasets: [] });
  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [labels, setLabels] = React.useState([]);
  const [datasets, setDatasets] = React.useState([]);

  const color = _.get(selectedMenu, 'color', 'black');
  const labelKey = _.get(selectedMenu, 'labelKey', '');
  const datasetKey = _.get(selectedMenu, 'datasetKey', '');
  const hasNext = _.get(response, 'links.next', null) ? true : false
  const hasPrevious = _.get(response, 'links.previous', null) ? true : false

  const chartRef = React.useRef();
  const options = {
    responsive: true,
    aspectRatio: 3,
    layout: { padding: 0 },
    plugins: {
      legend: { display: false },
      title: { display: false },
      tooltip: {
        callbacks: { label: (context) => `${context.formattedValue} ${selectedMenu.unit}` }
      }
    },
    scales: {
      x: { display: false },
      // y: { display: false },
    }
  }

  const handlePageChange = (page) => {
    fetchData({page})
    setPage(page);
  }

  const drawChart = () => {
    const chart = chartRef.current;
    if (!chart) {
      return;
    }

    setChartData({
      labels: labelKey === 'date' ? convertTimeLabel(labels) : labels,
      datasets: [{
        data: datasets,
        borderColor: COLOR_SET[color].lineColor,
        backgroundColor: createGradient(chart.ctx, chart.chartArea),
        pointBackgroundColor: COLOR_SET[color].lineColor,
        pointRadius: 4,
        pointHitRadius: 10,
        pointHoverRadius: 8,
        lineTension: 0,
        fill: true,
      }]
    });
  }

  const createGradient = (ctx, area) => {
    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
    gradient.addColorStop(0.4, COLOR_SET[color].backgroundColor)
    gradient.addColorStop(0, COLOR_SET['white'].noneColor)
    return gradient
  }

	const renderSubtitle = () => {
    let subTitleLabels = [_.get(labels, 0, ''), _.get(labels, _.lastIndexOf(labels)-1, '')]
    if (labelKey === 'date') {
      let timeScaleRender = '';
      subTitleLabels = convertTimeLabel(subTitleLabels);
      if (timeScale === 'week') {
        subTitleLabels[0] = _.get(_.get(subTitleLabels, 0, '').split(' - '), 0, '')
        subTitleLabels[1] = _.get(_.get(subTitleLabels, 1, '').split(' - '), 1, '')
      }
      switch(timeScale) {
        case 'day': timeScaleRender = 'รายวัน';break;
        case 'week': timeScaleRender = 'รายสัปดาห์';break;
        case 'month': timeScaleRender = 'รายเดือน';break;
        case 'year': timeScaleRender = 'รายปี';break;
      }
      return (
        <div>
          <div>{timeScaleRender}</div>
          {`${subTitleLabels[0]} - ${subTitleLabels[1]}`}
        </div>  
      )
    }
    return `${subTitleLabels[0]} - ${subTitleLabels[1]}`
  }

  const convertTimeLabel = (labels) => {
    if (timeScale === 'day') {
      return labels.map(element => {
        const [year, month, day] = element.split('-');
        const pointDate = new Date(year, month-1, day);
        return pointDate.toLocaleDateString('en-GB')
      });
    } else if (timeScale === 'week') {
      return labels.map(element => {
        const [year, month, day] = element.split('-');
        const pointDate = new Date(year, month-1, day);
        const today = new Date();
        const beforeNextPoint = new Date(year, month-1, day);
        beforeNextPoint.setDate(beforeNextPoint.getDate() + (7 - beforeNextPoint.getDay()) % 7); //next sunday
        if (beforeNextPoint > today) {
          beforeNextPoint.setDate(today.getDate() - 1)
        }
        return `${pointDate.toLocaleDateString('en-GB')} - ${beforeNextPoint.toLocaleDateString('en-GB')}`
      });
    } else if (timeScale === 'month') {
      return labels.map(element => {
        const [year, month, day] = element.split('-');
        const pointDate = new Date(year, month-1, day)
        return `${TH_MONTH[pointDate.getMonth()]} ${pointDate.getFullYear()}`
      });
    } else if (timeScale === 'year') {
      return labels.map(element => {
        const [year, month, day] = element.split('-');
        const pointDate = new Date(year, month-1, day)
        return `${pointDate.getFullYear()}`
      });
    }
	}

  React.useEffect(() => {
    fetchData({ time_scale: timeScale })
  }, [timeScale])

  React.useEffect(() => {
    setData(_.reverse(_.get(response, 'results', [])))
  }, [response])

  React.useEffect(() => {
    if (menus.length) {
      setSelectedMenu(_.get(menus, selectedIndex))
    }
  }, [menus, selectedIndex])

  React.useEffect(() => {
    setLabels(data.map(e => _.get(e, labelKey, '')))
    setDatasets(data.map(e => _.get(e, datasetKey, '')))
  }, [data, selectedMenu])

  React.useEffect(() => drawChart(), [datasets, labels])

  return (
    <div>
      <Segment>
        <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
        <Media at='sm'>
          <Menu secondary style={{ justifyContent: 'space-evenly' }}> 
            <Menu.Item
              icon='left arrow'
              onClick={() => {
                setSelectedIndex(i => (i > 0) ? i-1 : menus.length-1)
              }}/>
            <Menu.Item
              name={_.get(menus, `${selectedIndex}.title`)}
              color={_.get(menus, `${selectedIndex}.color`)}
              icon={_.get(menus, `${selectedIndex}.icon`)}
              active/>
            <Menu.Item
              icon='right arrow'
              onClick={() => {
                setSelectedIndex(i => (i < menus.length-1) ? i+1 : 0)
              }}/>
          </Menu>
          <Header>
            <Header.Subheader>
              {renderSubtitle()}
            </Header.Subheader>
          </Header>
          <Line
            ref={chartRef}
            data={chartData}
            options={options}/> 
        </Media>
        <Media greaterThanOrEqual='md'>
          <Menu attached='top' tabular>
            { menus.map(menu => 
              <Menu.Item
                name={menu.datasetKey}
                active={menu.datasetKey === _.get(selectedMenu, 'datasetKey', '')}
                onClick={() => setSelectedMenu({...menu})}>
                <Header as='h4'>
                  <Icon color={menu.color}  name={menu.icon}/>
                  {menu.title}
                </Header>
              </Menu.Item>
            )}
          </Menu>
          <Segment attached='bottom'>
            <Header>
              <Header.Subheader>
                {renderSubtitle()}
              </Header.Subheader>
            </Header>
            <Line
              ref={chartRef}
              data={chartData}
              options={options}/>
            <div style={{ textAlign: 'center' }}>
              <Button
                color='blue'
                disabled={!hasNext}
                labelPosition='left'
                icon='chevron left'
                content='ก่อนหน้า'
                onClick={() => handlePageChange(page+1)}/>
              <Button
                color='blue'
                disabled={!hasPrevious}
                labelPosition='right'
                icon='chevron right'
                content='ถัดไป'
                onClick={() => handlePageChange(page-1)}/>
            </div>
          </Segment>
        </Media>
      </Segment>
    </div>
  )
}


LineSegment.defaultProps = {
  fetchURL: '',
  timeScale: 'day',
  menus: [],
}

LineSegment.propTypes = {
  fetchURL: propTypes.string,
  timeScale: propTypes.string,
  menus: propTypes.array,
}