import React from "react";
import Cookies from 'js-cookie'
import { Navigate, Outlet } from "react-router-dom";
import { PATH } from "./CustomRoutes";
import * as _ from 'lodash';


export default function OfficerOutlet() {
  const getUserGroups = () => {
    try {
      const items = _.get(JSON.parse(Cookies.get('user')), 'groups', [])
      return items != null ? items : [] 
    } catch(error){
      Cookies.remove('user')
      return []
    }  
  }

  const auth = Cookies.get('token') != null;
  const groups = getUserGroups()
  return auth && groups.includes('officer') ? <Outlet /> : <Navigate to={PATH.OFFICER_LOGIN} />;
}
