import React from 'react';
import ReactTable from 'react-table-v6';


export default function CustomReactTable(props) {

  return (
		<ReactTable
			noDataText='ไม่มีข้อมูล'
			defaultPageSize={10}
			showPagination={true}
			showPageSizeOptions={false}
			className='-striped -highlight'
			getTdProps={() => ({
				style: { whiteSpace: 'unset', textAlign: 'center' }
			})}
			{...props}
			style={{borderRadius: 5, ...props.style}}
		/>
	)
}