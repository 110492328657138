import Cookies from 'js-cookie';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { URL_AUTH, URL_STAFF } from '../constance/urls';
import { GET, POST } from '../frameworks/HttpClient';
import { PATH } from '../routes/CustomRoutes';
import { useStoreContext } from '../hooks/StoreProvider';


export default function LoginRedirectScreen() {
  
  const navigate = useNavigate();
  const { username } = useParams();
  const [searchParams, _] = useSearchParams();
  const { setStaff, removeStoreSetting } = useStoreContext();

  const injectStaff = async () => {
    try {
      if (searchParams.get('staff')) {
        const staffResponse  = await GET(`${URL_STAFF.STAFF}${searchParams.get('staff')}/`)
        await setStaff(staffResponse.data)
      }
    } catch {}
  }

  const handleLogin = async () => {
    try {
      await removeStoreSetting(); 

      const response = await POST(URL_AUTH.API_TOKEN_AUTH, {
        'username': username
      })
      Cookies.set('token', response.data.token, { expires: 30 })
      Cookies.set('user', JSON.stringify(response.data.user), { expires: 30 })

      await injectStaff();
      redirectScreen();
    } catch {
      navigate(PATH.LOGIN);
    }
  }

  const redirectScreen = () => {
    const path = searchParams.get('path')
    if (path === 'main') {
      navigate(PATH.MAIN);
    } else if (path) {
      navigate(`/${path}`);
    } else {
      navigate(PATH.PRODUCT);
    }
  }

  React.useEffect(() => {
    handleLogin();
  }, [])

  return (
    <div>
      <Dimmer active inverted><Loader inverted content='Authenticating'/></Dimmer>
    </div>
  )
}