import React from 'react';
import { Icon, Card, List, Divider, Header, Pagination } from 'semantic-ui-react';
import propTypes from 'prop-types';
import * as _ from 'lodash';


const ItemList = ({data, content}) => {
  return (
    <Card.Group>
      {data.results.map((item, idx) => (
        <Card key={`item-${idx}`} fluid onClick={() => content.onClick(item)}>
          <Card.Content>
            {
              content.header &&
              <Card.Header textAlign='left'>
                { content.header.icon && 
                  <Icon name={content.header.icon}/>
                }
                { content.header.detail ? 
                  content.header.detail(item) : 
                  _.get(item, content.header.accessor)
                }
              </Card.Header>
            }
            <Card.Description textAlign='left'>
              <List>
                { content.descriptions.map((description, idxx) => {
                    return (
                      <List.Item key={`description-${idxx}`}>
                        <List.Content floated='left'>
                        { description.icon && 
                          <Icon name={description.icon}/>
                        }
                          {description.header}
                        </List.Content>
                        <List.Content floated='right'>{description.detail ? description.detail(item) : _.get(item, description.accessor) }</List.Content>
                      </List.Item>
                    )
                  }) 
                }                
              </List>
            </Card.Description>
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  )
}


const MobileList = React.forwardRef((props, ref) => {

  const {
    content,
    data,
    onPageChange,
  } = props;

  const [page, setPage] = React.useState(1);

  React.useImperativeHandle(ref, () => ({
    initialPage: () => setPage(1),
  }))

  React.useEffect(() => {
    onPageChange(page);
    window.scrollTo(0, 0)
  }, [page])

  return (
    <div>
      <Divider hidden/>
      { !data.total &&
        <Header disabled>
          <Icon name='search'/>
          ไม่พบข้อมูล
        </Header>
      }
      <ItemList
        data={data}
        content={content}/>
     
      <div style={{ textAlign: 'center' }}>
        <Divider hidden/>
        <Pagination
          activePage={page}
          siblingRange='1'
          // firstItem={null}
          // lastItem={null}
          ellipsisItem={null}
          boundaryRange='0'
          totalPages={data.pages}
          onPageChange={(_, data) => {
            setPage(data.activePage);
          }}/>
      </div>
    </div>
  );
});

export default React.memo(MobileList);