import React from "react";
import PropTypes from "prop-types";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import { Media } from "../../AppMedia"
import MobileList from "../common/MobileList"
import PaginationTable from "../common/PaginationTable";
import useListItem from "../../hooks/useItemList"
import { getColumnsList } from "../../frameworks/Util";
import { URL_STAFF } from "../../constance/urls";

const UserList = React.forwardRef((props, ref) => {
	const [page, setPage] = React.useState(1)
	const { response, isLoading, errorMessages, fetchData, fetched } = useListItem({ 
    url: URL_STAFF.STAFF,
    params: { page: page },
    manual: true,
    usePagination: true
  });

	React.useImperativeHandle(ref, () => ({
    reload: () => fetchData(),
  }))

	React.useEffect(() => {
		if ((fetched > 0) && response && response.results) {
			const results = response.results
			props.onStaffChange(results)
		}

	}, [response, fetched])

	const columns = React.useMemo(() => [
    ...getColumnsList([
			{ Header: 'ชื่อ', accessor: 'first_name' },
			{ Header: 'สกุล', accessor: 'last_name' },
			{ 
				Header: 'หน้าที่', 
				accessor: 'role', 
				width: 100, 
				Cell: ({value}) => (value === "mgr") ? "ผู้จัดการ" : "พนักงาน" 
			},
			{ Header: '', accessor: 'id',
				Cell: ({value, original}) => (
					<div>
						<Button
							basic
							icon='refresh'
							color='blue'
							content='Reset PIN'
							onClick={() => props.onResetPIN(original)}/>
						<Button
							basic
							icon='edit outline'
							color='yellow'
							content='แก้ไข'
							onClick={() => props.onEdit(original)}/>
					</div>
				)},
    ])
  ]);

	const content = {
		header: {
			icon: 'user outline',
			accessor: 'first_name',
		},
		descriptions: [
			{
				icon: 'tag',
        header: 'หน้าที่',
        detail: (item) => (item.role === "mgr") ? "ผู้จัดการ" : "พนักงาน",
			},
			{
				detail: (item) => (
					<div>
						<Button
							basic
							icon='refresh'
							color='blue'
							content='Reset PIN'
							onClick={() => props.onResetPIN(item)}/>
						<Button
							basic
							icon='edit outline'
							color='yellow'
							content='แก้ไข'
							onClick={() => props.onEdit(item)}/>
					</div>
				)
			},
		],
		onClick: (item) => null
	};

	return (
		<div>
			<Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
			
      <Media at='sm'>
        <MobileList
          data={response}
          content={content}
          onPageChange={(page) => setPage(page)}/>
        </Media>
      <Media greaterThanOrEqual='md'>
        <PaginationTable
					style={{marginTop: 8}}
          columns={columns}
          data={response}
          onPageChange={(page) => setPage(page)}/>
      </Media>
		</div>
	)
})

UserList.defaultProps = {
	onStaffChange: (data) => {}
}

UserList.propsType = {
	onStaffChange: PropTypes.func,
}

export default React.memo(UserList);