import { useFormik } from 'formik';
import React from 'react';
import { Button, Dimmer, Form, Icon, Loader, Message, Modal } from 'semantic-ui-react';
import { GET } from '../../frameworks/HttpClient';
import { Toast } from '../../frameworks/Toast';
import CustomInputField from './CustomInputField';
import { URL_STORE } from '../../constance/urls';
import * as Yup from 'yup';
import * as _ from 'lodash';


export default function ShareDocumentModal(props) {
  const {
    open,
    onClose,
    title,
    url,
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required('กรุณากรอกข้อมูล').email('กรอกอีเมลให้ถูกต้อง'),
    }),
    onSubmit: async ({ email }) => {
      setIsLoading(true);
      setErrorMessages(null);
      try {
        await GET(`${url}?email=${email}`)
        Toast.success('ระบบกำลังส่งรายงานให้ทางอีเมลที่กรอกไว้')
        onClose();
      } catch(error) {
        setErrorMessages(error.errorMessages);
      } finally {
        setIsLoading(false)
      }
    }
  })

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await GET(URL_STORE.STORE);
      formik.setFieldValue('email', _.get(response, 'data.email', ''))
    } catch { } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if(open) {
      formik.resetForm();
      fetchData();
    }
  }, [open])

  return (
    <Modal
      open={open}
      onClose={onClose}>
      <Modal.Header><Icon name='file outline alternate'/>{title ? title : 'แชร์เอกสาร'}</Modal.Header>
      <Modal.Content>
        <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
        { errorMessages && <Message error>{errorMessages}</Message> }
        <Form>
          <CustomInputField
            isForm
            name='email'
            label='อีเมล'
            icon='mail'
            placeholder='อีเมล'
            value={formik.values.email}
            error={formik.errors.email}
            onChange={formik.handleChange}/>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button icon='cancel' content='ยกเลิก' onClick={onClose}/>
        <Button icon='mail' color='blue' content='ส่งอีเมล' onClick={formik.handleSubmit}/>
      </Modal.Actions>
    </Modal>
  )
}