import React from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Accordion, Button, Form, Icon } from 'semantic-ui-react';
import CustomInputField from '../common/CustomInputField';
import * as _ from 'lodash';
import moment from 'moment';
import QRCodeReaderModal from '../common/QRCodeReaderModal';
import { Media } from '../../AppMedia';


export default function StockFilter(props) {
  const {
    filter,
    setFilter,
    fetchData,
  } = props;

  const [dateRange, setDateRange] = React.useState([]);
  const [filterActive, setFilterActive] = React.useState(false);
  const [openQRCodeModal, setOpenQRCodeModal] = React.useState(false);

  const handleFilterChange = (_, data) => {
    setFilter((prevFilter) => ({ ...prevFilter, [data.name]: data.value }))
  }

  const filterElements = () => (
    <>
      <SemanticDatepicker
        datePickerOnly={true}
        maxDate={new Date().setHours(23, 59, 59, 999)}
        style={{ width: '100%' }}
        locale='en-US'
        type='range'
        showToday={true}
        format='DD/MM/YY'
        placeholder='เลือกช่วงเวลา...'
        value={dateRange}
        onChange={(_, data) => setDateRange(data.value)}/>
      <Form.Field >
        <CustomInputField
          name='search'
          placeholder='ค้นหาตามชื่อ barcode...'
          value={_.get(filter, 'search')}
          action={
            <Button
              color='blue'
              icon='qrcode'
              type='button'
              onClick={() => setOpenQRCodeModal(true)} />
          }
          onChange={handleFilterChange}/>
      </Form.Field>
      <Form.Button color='blue' icon='search' content='ค้นหา' onClick={() => fetchData()}/>
      </>
  )

  React.useEffect(() => {
    handleFilterChange(null, { name: 'date_after', value: _.get(dateRange, 0, false) ? moment(dateRange[0]).format('YYYY-MM-DD') : null })
    handleFilterChange(null, { name: 'date_before', value: _.get(dateRange, 1, false) ? moment(dateRange[1]).format('YYYY-MM-DD') : null })
  }, [dateRange])

  return (
    <>
      <QRCodeReaderModal 
        open={openQRCodeModal}
        onClose={() => setOpenQRCodeModal(false)}
        onQRCodeSuccess={(text) => {
          handleFilterChange(null, {name: 'search', value: text})
          setOpenQRCodeModal(false)
        }}/>

      <Media lessThan='lg'>
        <Accordion fluid>
          <Accordion.Title 
            active={filterActive}
            onClick={() => setFilterActive(state => !state)}>
            <Icon name='dropdown' />
            ค้นหา
          </Accordion.Title>
          <Accordion.Content active={filterActive}>
            <Form>
              <Form.Group widths='equal'>
                {filterElements()}
              </Form.Group>
            </Form>
          </Accordion.Content>
        </Accordion>
      </Media>
      <Media greaterThan='md'>
        <Form>
          <Form.Group inline unstackable>
            <Form.Field label='ค้นหา' />
            {filterElements()}
          </Form.Group>
        </Form>
      </Media>
    </>
  )
}