import { useFormik } from "formik";
import React from "react";
import { Form, Grid, Message, Segment, Image, Button, Tab } from 'semantic-ui-react'
import logo from '../logo.png';
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { POST } from "../frameworks/HttpClient";
import { URL_AUTH } from "../constance/urls";
import Cookies from "js-cookie";
import { PATH } from "../routes/CustomRoutes";
import QRCodeReader from "../components/common/QRCodeReader";
import CustomInputField from "../components/common/CustomInputField";
import * as _ from 'lodash';


export default function LoginScreen() {

  const navigate = useNavigate();
  const qrCodeReaderRef = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const panes = [
    { menuItem: 'OTP', render: renderLoginOTP },
    { menuItem: 'รหัสประจำเครื่อง', render: renderLoginQRCode },
  ]

  function renderLoginOTP() {
    return(
      <Tab.Pane>
        {errorMessages && <Message error>{errorMessages}</Message>}
        <Form>
          <CustomInputField
            isForm
            action={{
              color: 'blue',
              labelPosition: 'right',
              icon: 'sign-in',
              content: 'เข้าใช้งาน',
              type: 'submit',
              loading: isLoading,
              onClick: otpFormik.handleSubmit
            }}
            fluid
            name='otp_no'
            icon='key'
            iconPosition='left'
            placeholder='รหัสเข้าใช้งาน'
            value={otpFormik.values.otp_no}
            error={otpFormik.errors.otp_no}
            onChange={otpFormik.handleChange}
          />
        </Form>
      </Tab.Pane>
    )
  }

  function renderLoginQRCode() {
    return(
      <Tab.Pane>
        {errorMessages && <Message error>{errorMessages}</Message>}
        <QRCodeReader
          ref={qrCodeReaderRef}
          qrCodeSuccessCallback={async (decodedText, result) =>  {
            let splitedText = _.split(decodedText, '/')
            const username = _.get(splitedText, splitedText.length-2, '')
            qrCodeReaderRef.current.pause(true);
            setIsLoading(true);
            try {
              const response = await POST(URL_AUTH.API_TOKEN_AUTH, {
                "username": username
              })
              await qrCodeReaderRef.current.cleanup();
              Cookies.set('token', response.data.token, { expires: 30 })
              Cookies.set('user', JSON.stringify(response.data.user), { expires: 30 })
              navigate(PATH.MAIN)
            } catch (error) {
              setErrorMessages(error.errorMessages);
              qrCodeReaderRef.current.resume();
            } finally {
              setIsLoading(false);
            }
          }}
        />
      </Tab.Pane>
    )
  }

  const otpFormik = useFormik({
    initialValues: { otp_no: '' },
    validationSchema: Yup.object().shape({
      otp_no: Yup.string().required('กรุณากรอกรหัสเข้าใช้งาน')
    }),
    onSubmit:  async (values) => {
      setIsLoading(true);
      try {
        const response = await POST(URL_AUTH.VERIFY_OTP, values)
        Cookies.set('token', response.data.token, { expires: 30 })
        Cookies.set('user', JSON.stringify(response.data.user), { expires: 30 })
        navigate(PATH.MAIN)          
      } catch(error) {
        setErrorMessages(error.errorMessages);
      } finally {
        setIsLoading(false);
      }
    }
  });

  return (
		<div className='login-form'>
      <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}
      </style>
			<Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
				<Grid.Column style={{ maxWidth: "400px" }}>
          <Image src={logo} size='small' centered/>
          <Segment>
            <Tab panes={panes}/>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  )
}
