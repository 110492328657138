import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Grid, Image, Message, Segment, Header, Button } from 'semantic-ui-react';
import logo from '../logo.png';
import * as Yup from 'yup';
import { POST } from "../frameworks/HttpClient";
import { PATH } from "../routes/CustomRoutes";
import Cookies from "js-cookie";
import { URL_AUTH } from '../constance/urls';
import CustomInputField from '../components/common/CustomInputField';


export default function OfficerLoginScreen(props) {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [toggleShowPassword, setToggleShowPassword] = React.useState(false);

  const formik = useFormik({
    initialValues: { 
      username: '',
      password: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      username: Yup.string().required('กรุณากรอกบัญชีผู้ใช้งาน'),
      password: Yup.string().required('กรุณากรอกรหัสเข้าใช้งาน')
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      setErrorMessages(null);      
      try {
        const response = await POST(URL_AUTH.OFFICER_API_TOKEN_AUTH, values);
        Cookies.set('token', response.data.token, { expires: 7 })
        Cookies.set('user', JSON.stringify(response.data.user), { expires: 7 })
        navigate(PATH.OFFICER)
      } catch(error) {
        setErrorMessages(error.errorMessages);
      } finally {
        setIsLoading(false);
      }
    }
  });

  return (
		<div className='login-form'>
      <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}
      </style>
			<Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
				<Grid.Column style={{ maxWidth: "400px" }}>
          <Image src={logo} size='small' centered/>
          <Header as='h2'>Officer Login</Header>
          <Segment>
            {errorMessages && <Message error>{errorMessages}</Message>}
            <Form>
              <CustomInputField
                isForm
                fluid
                name='username'
                icon='user'
                iconPosition='left'
                placeholder='ชื่อผู้ใช้'
                value={formik.values.username}
                error={formik.errors.username}
                onChange={formik.handleChange}/>
              <CustomInputField
                isForm
                fluid
                name='password'
                icon='lock'
                iconPosition='left'
                placeholder='รหัสผ่าน'
                action={
                  <Button
                    basic
                    type='button'
                    icon={toggleShowPassword ? 'eye' : 'eye slash'}
                    onClick={() => setToggleShowPassword(prevState => !prevState)}/>
                }
                actionPosition='right'
                type={toggleShowPassword ? 'text' : 'password'}
                value={formik.values.password}
                error={formik.errors.password}
                onChange={formik.handleChange}/>
              <Form.Button
                color='blue'
                fluid
                size='large'
                type='submit'
                content='เข้าสู่ระบบ'
                loading={isLoading}
                onClick={formik.handleSubmit}/>
            </Form>
          </Segment>
        </Grid.Column>        
      </Grid>
    </div>
  )
}
