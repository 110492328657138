import React from 'react';
import * as _ from 'lodash';
import Cookies from 'js-cookie';
import { GET } from '../frameworks/HttpClient';
import { URL_STORE } from '../constance/urls';


const StoreContext = React.createContext({});

export function useStoreContext() {
  return React.useContext(StoreContext);
}

const staffExcludeFields = ['pin', 'created', 'created_by', 'updated', 'updated_by']
const defaultStaffModel = {
  id: null,
  first_name: '',
  role: '',
  last_name: '',
  is_manager: false,
  edit_store: false,
  edit_product: false,
  edit_customer: false,
  input_stock: false,
  output_stock: false,
  show_sale_transaction: false,
  show_dashboard: false,
  export_report: false,
}
const defaultModel = {
  use_tax_invoice: false,
  use_staff: false,
  active_staffs: 0,
  use_image_product: false,
  staff: defaultStaffModel
};

export default function StoreProvider({ children }) {
  // global state สำหรับใช้จัดการ feature ที่ขึ้นกับ role staff
  // component นี้ ถูกเรียกบน App.js ให้ component ล่าง ๆ เรียก useContext ได้
  // component อื่น ๆ เรียกใช้ state จาก useStoreContext
  // เช่น const { setting, setStaff, fetchStoreSetting, removeStoreSetting } = useStoreContext();

  const [setting, setSetting] = React.useState(
    Cookies.get('nubman-setting') ? 
      { ...defaultModel, ...JSON.parse(Cookies.get('nubman-setting')) } : 
      { ...defaultModel }
  )

  const updateStoreState = (payload) => {
    const newState = { ...setting, ...payload };
    setSetting(newState);
    Cookies.set('nubman-setting', JSON.stringify(newState), { expires: 60 });
  }

  const setStaff = (staff) => {
    const staffData = Object.keys(staff)
      .filter(key => !staffExcludeFields.includes(key))
      .reduce((obj, key) => Object.assign(obj, { [key]: staff[key] }), {})
    updateStoreState({staff: staffData})
  }

  const enableUseStaff = (staff=null) => {
    updateStoreState({ use_staff: true, staff: staff ? staff : setting.staff })
  }

  const disableUseStaff = () => {
    updateStoreState({ use_staff: false, staff: {...defaultStaffModel} })
  }

  const fetchStoreSetting = async () => {
    try {
      const response = await GET(URL_STORE.STORE)
      const data = response.data
      updateStoreState({
        use_tax_invoice: _.get(data, 'tax_type', 'no vat') != 'no vat',
        use_staff: _.get(data, 'use_staff', false) && _.get(data, 'active_staffs', 0) > 0,
        active_staffs: _.get(data, 'active_staffs', 0),
        use_image_product: _.get(data, 'use_image_product', false),
      })
    } catch { }
  }

  const removeStaff = () => {
    setStaff({staff: {...defaultStaffModel}})
  }

  const removeStoreSetting = () => {
    updateStoreState({ ...defaultModel });
    Cookies.remove('nubman-setting');
  }

  return (
    <StoreContext.Provider value={{
      setting, setStaff, fetchStoreSetting,
      enableUseStaff, disableUseStaff,
      removeStoreSetting, removeStaff 
    }}>
      {children}
    </StoreContext.Provider>
  )
}