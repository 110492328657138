import React from "react";
import { URL_REPORT, URL_STAFF } from "../../constance/urls";
import * as _ from "lodash";
import useListItem from "../../hooks/useItemList";
import moment from "moment";
import { dateTimeToString, formatComma, getColumnsList } from "../../frameworks/Util";
import { Button, Dimmer, Form, Loader, Message } from "semantic-ui-react";
import PaginationTable from "../common/PaginationTable";
import { Media } from "../../AppMedia";
import MobileList from "../common/MobileList";
import ShareReportModal from "./ShareReportModal";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import StaffShiftReportModal from "./StaffShiftReportModal";


export default function StaffShiftReport(props) {

  const [dateRange, setDateRange] = React.useState([]);
  const [shareReportOpen, setShareReportOpen] = React.useState(false);
  const [detailTarget, setDetailTarget] = React.useState(null);
  const { response, isLoading, errorMessages, fetchData } = useListItem({ 
    url: URL_STAFF.STAFF_SHIFT,
    params: {
      date_after: _.get(dateRange, 0, false) ? moment(dateRange[0]).format('YYYY-MM-DD') : null,
			date_before: _.get(dateRange, 1, false) ? moment(dateRange[1]).format('YYYY-MM-DD') : null,
    },
    usePagination: true,
  });

  const columns = React.useMemo(() => [
    ...getColumnsList([
			{ Header: 'พนักงาน', accessor: 'staff_name', },
			{ Header: 'เริ่มกะ', accessor: 'start_time', Cell: ({value}) => dateTimeToString(value) },
			{ Header: 'ออกกะ', accessor: 'end_time', Cell: ({value}) => dateTimeToString(value) },
			{ Header: 'รายได้ทั้งหมด (บาท)', accessor: 'summary.total_gain', Cell: ({value}) => formatComma(value) },
			{ Header: 'ส่วนลด (บาท)', accessor: 'summary.total_discount', Cell: ({value}) => formatComma(value) },
			{ Header: 'กำไร (บาท)', accessor: 'summary.total_profit', Cell: ({value}) => formatComma(value, true) },
			{ Header: 'จำนวนใบเสร็จ', accessor: 'summary.total_transaction', Cell: ({value}) => formatComma(value) },
			{ Header: 'จำนวนสินค้าที่ขายได้', accessor: 'summary.total_product_count', Cell: ({value}) => formatComma(value) },
      { Header: 'ราคารวมสินค้าไม่มีภาษี', accessor: 'summary.total_no_vat', Cell: ({value}) => formatComma(value) },
      { Header: 'ราคารวมสินค้ามีภาษี', accessor: 'summary.total_include_vat', Cell: ({value}) => formatComma(value) },
      { Header: '', accessor: 'id', width: 80, Cell: ({value, original}) => 
        <Button color='blue' basic icon='file outline alternate' onClick={() => setDetailTarget(value)}/> }
    ])
  ]);

  const content = {
    header: {
      icon: 'user',
      header: 'พนักงาน',
      accessor: 'staff_name'
    },
		descriptions: [
			{ header: 'เริ่มกะ', icon: 'sign in alternate', detail: (item) => dateTimeToString(item.start_time) },
			{ header: 'ออกกะ', icon: 'sign out alternate', detail: (item) => dateTimeToString(item.end_time) },
			{ header: 'รายได้ทั้งหมด', icon: 'caret right', detail: (item) => `${formatComma(_.get(item, 'summary.total_gain'))} บาท` },
			{ header: 'ส่วนลด', icon: 'caret right', detail: (item) => `${formatComma(_.get(item, 'summary.total_discount'))} บาท` },
			{ header: 'กำไร', icon: 'caret right', detail: (item) => `${formatComma(_.get(item, 'summary.total_profit'), true)} บาท` },
			{ header: 'จำนวนใบเสร็จ', icon: 'caret right', detail: (item) => formatComma(_.get(item, 'summary.total_transaction')) },
			{ header: 'จำนวนสินค้าที่ขายได้', icon: 'caret right', detail: (item) => formatComma(_.get(item, 'summary.total_product_count')) },
    ],
    onClick: () => null,
  }

  return (
    <div>
      <ShareReportModal 
        header='สรุปกะการทำงาน'
        open={shareReportOpen} 
        onClose={() => setShareReportOpen(false)} 
        isDateRequire={true}
        useDateRange={true}
        baseURL={URL_REPORT.SHARE_STAFF_SHIFT_REPORT} />

      <StaffShiftReportModal
        open={detailTarget != null}
        target={detailTarget}
        onClose={() => setDetailTarget(null)}/>
      <Form>
        <Media greaterThanOrEqual='md'>
        <Button floated='right' color='teal' content='แชร์รายงาน' icon='mail' onClick={() => setShareReportOpen(true)}/>

        </Media>
        <Form.Group inline>
          <SemanticDatepicker
            datePickerOnly={true}
            maxDate={new Date().setHours(23, 59, 59, 999)}
            style={{ width: '100%' }}
            label='ค้นหา'
            locale='en-US'
            type='range'
            showToday={true}
            format='DD/MM/YY'
            placeholder='เลือกช่วงเวลา...'
            value={dateRange}
            onChange={(_, data) => setDateRange(data.value)}/>
          <Media at='sm'>
            <Form.Button fluid color='blue' icon='search' onClick={() => fetchData()}/>
          </Media>
          <Media greaterThanOrEqual='md'>
            <Form.Button fluid color='blue' icon='search' content='ค้นหา' onClick={() => fetchData()}/>
          </Media>
        </Form.Group>
      </Form>
      <Media at='sm'>
        <Button color='teal' content='แชร์รายงาน' icon='mail' onClick={() => setShareReportOpen(true)}/>
      </Media>
      <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
      { errorMessages && <Message error>{errorMessages}</Message> }
      <Media at='sm'>
        <MobileList
          data={response}
          content={content}
          onPageChange={(page) => fetchData({ page })}/>
      </Media>
      <Media greaterThanOrEqual='md'>
        <PaginationTable
          columns={columns}
          data={response}
          onPageChange={(page) => fetchData({ page })}/>
      </Media>

    </div>
  )
}