import { useFormik } from 'formik';
import React from 'react';
import propTypes from "prop-types";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Button, Dimmer, Form, Icon, Loader, Message, Modal } from 'semantic-ui-react';
import { URL_STAFF, URL_STORE } from '../../constance/urls';
import { GET, POST } from '../../frameworks/HttpClient';
import * as Yup from 'yup';
import * as _ from 'lodash';
import moment from 'moment';
import CustomInputField from '../common/CustomInputField';
import { Toast } from '../../frameworks/Toast';
import useDropdownItem from '../../hooks/useDropdownItem';


export default function ShareReportModal(props) {

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      export_type: 'pdf',
      date: '',
      date_before: '',
      date_after: '',
      email: '',
      staff: '',
    },
    validationSchema: Yup.object().shape({
      date: (props.isDateRequire && !props.useDateRange) ? Yup.string().required('กรุณาเลือกวันที่') : Yup.string().notRequired(),
      date_before: (props.isDateRequire && props.useDateRange) ? Yup.string().required('กรุณาเลือกช่วงวันที่') : Yup.string().notRequired(),
      date_after: (props.isDateRequire && props.useDateRange) ? Yup.string().required('กรุณาเลือกช่วงวันที่') : Yup.string().notRequired(),
      email: Yup.string().required('กรุณากรอกข้อมูล').email('กรอกอีเมลให้ถูกต้อง').nullable(),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      setErrorMessages(null);
      let params = {...props.params, ...values}
      if (!props.isDateRequire || props.useDateRange)
        delete params['date']
      if (!props.isDateRequire || !props.useDateRange) {
        delete params['date_before']
        delete params['date_after']
      }
      
      try {
        await GET(`${props.baseURL}${values.export_type}/`, params)
        Toast.success('ระบบกำลังส่งรายงานให้ทางอีเมลที่กรอกไว้')
        props.onClose();
      } catch(error) {
        setErrorMessages(error.errorMessages);
      } finally {
        setIsLoading(false)
      }
    }
  })

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [staffs, setStaffs] = React.useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await GET(URL_STORE.STORE);
      formik.setFieldValue('email', _.get(response, 'data.email', ''))
    } catch { } finally {
      setIsLoading(false)
    }
  }

  const fetchStaff = async() => {
    setIsLoading(true);
    try{
      const response = await GET(URL_STAFF.STAFF);
      setStaffs(response.data.results.map(item => {
        return(
          {...item,
            key : item.id,
            value : item.id,
            text : `${item.first_name} ${item.last_name}`
          }
        )}))
    } catch { } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if(props.open) {
      formik.resetForm();
      fetchStaff();
      fetchData();
    }
  }, [props.open])

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.onClose}>
        <Modal.Header>
          <Icon name='mail'/>
          {props.header}
        </Modal.Header>
        <Modal.Content>
          <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
          { errorMessages && <Message error>{errorMessages}</Message> }
          <Form>
            <Form.Group widths='equal'>
              {props.isDateRequire && !props.useDateRange && (
                <SemanticDatepicker
                  datePickerOnly={true}
                  maxDate={new Date().setHours(23, 59, 59, 999)}
                  style={{ width: '100%' }}
                  name='date'
                  label='วันที่'
                  locale='en-US'
                  format='DD/MM/YYYY'
                  showToday={true}
                  placeholder='เลือกวันที่...'
                  error={formik.errors.date}
                  onChange={(_, data) => {
                    formik.setFieldValue('date', data.value ? moment(data.value).format('YYYY-MM-DD') : '')
                  }}/>
              )}
              {props.isDateRequire && props.useDateRange && (
                <SemanticDatepicker
                  datePickerOnly={true}
                  maxDate={new Date().setHours(23, 59, 59, 999)}
                  style={{ width: '100%' }}
                  label="ช่วงวันที่"
                  locale='en-US'
                  type='range'
                  showToday={true}
                  format='DD/MM/YY'
                  placeholder='เลือกช่วงเวลา...'
                  error={formik.errors.date_before || formik.errors.date_after}
                  onChange={(_, data) => {
                    formik.setFieldValue('date_before', '')
                    formik.setFieldValue('date_after', '')
                    let valueSize = data.value ? data.value.length : 0
                    if (valueSize == 1) {
                      let before = moment(data.value[0]).format('YYYY-MM-DD')
                      formik.setFieldValue('date_before', before)
                    }
                    else if (valueSize == 2) {
                      let before = moment(data.value[0]).format('YYYY-MM-DD')
                      let after = moment(data.value[1]).format('YYYY-MM-DD')
                      formik.setFieldValue('date_before', before)
                      formik.setFieldValue('date_after', after)
                    }
                  }}
                  />
              )}
              {props.isStaffRequire && <Form.Select
                name='staff'
                label='พนักงาน'
                search
                clearable
                options={staffs}
                placeholder='ชื่อพนักงาน'
                value={formik.values.staff}
                error={formik.errors && formik.errors.staff}
                onChange={(_, data) => {
                  formik.setFieldValue('staff', data.value ? data.value : null)
              }}/>}
              <CustomInputField
                isForm
                name='email'
                label='อีเมล'
                icon='mail'
                placeholder='อีเมล'
                value={formik.values.email}
                error={formik.errors.email}
                onChange={formik.handleChange}/>
              <Form.Dropdown
                label='ประเภทไฟล์'
                selection
                defaultValue='pdf'
                options={[
                  { key: 'pdf', value: 'pdf', text: 'PDF', content: (<div><Icon name='file pdf' color='red'/>pdf</div>) },
                  { key: 'excel', value: 'excel', text: 'Excel', content: (<div><Icon name='file excel' color='green'/>excel</div>) }
                ]}
                onChange={(_, data) => formik.setFieldValue('export_type', data.value)}/>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button icon='cancel' content='ยกเลิก' onClick={props.onClose}/>
          <Button icon='mail' color='blue' content='ส่งอีเมล' onClick={formik.handleSubmit}/>
        </Modal.Actions>
      </Modal>
    </>
  )
}

ShareReportModal.defaultProps = {
  header: "แชร์รายงาน",
  open: false,
  onClose: () => {},
  isDateRequire: true,
  useDateRange: false,
  baseURL: "",
  params: {}
}

ShareReportModal.propTypes = {
  header: propTypes.string,
  open: propTypes.bool,
  onClose: propTypes.func,
  isDateRequire: propTypes.bool,
  useDateRange: propTypes.bool,
  baseURL: propTypes.string,
  params: propTypes.object
}