import React from 'react';

const getWindowDimensions = () => {
  const {innerWidth: width, innerHeight: height} = window;

  const getSize = () => {
    if(width < 768) {
      return 'sm'
    } else if(width < 1024) {
      return 'md'
    } else if(width < 1192) {
      return 'lg'
    }
    return 'xl'
  }

  return {
    width,
    height,
    size: getSize(),
  };
}

export default function useWindowDimensions () {
  const [windowDimension, setWindowDimension] = React.useState(getWindowDimensions);

  React.useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowDimensions);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimension;
} 