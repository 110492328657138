import React from 'react';
import { Button, Form, Icon, Message, Modal, Search } from 'semantic-ui-react';
import { CustomerModel } from '../../constance/models';
import { URL_CUSTOMER, URL_SALE } from '../../constance/urls';
import { POST } from '../../frameworks/HttpClient';
import { Toast } from '../../frameworks/Toast';
import useListItem from '../../hooks/useItemList';
import * as _ from 'lodash';


export default function SelectCustomerModal(props) {
  const {
    open,
    onClose,
    target,
    customer,
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const customerList = useListItem({ url: URL_CUSTOMER.CUSTOMER, usePagination: true, manual: true });
  const [customerTarget, setCustomerTarget] = React.useState(CustomerModel);

  const handleSelectCustomer = async () => {
    setIsLoading(true);
    setErrorMessages(null);
    try {
      await POST(`${URL_SALE.SALE_TRANSACTION}${target}/link_customer/`, {
        customer: _.get(customerTarget, 'id', null)
      });
      Toast.success('สำเร็จ');
      onClose();
    } catch(error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    if(open) {
      setErrorMessages(null);
      setCustomerTarget({...CustomerModel, ...customer});
    }
  }, [open])

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeIcon
      closeOnDimmerClick={false}>
      <Modal.Header><Icon name='user'/>เลือกลูกค้า</Modal.Header>
      <Modal.Content>
        { errorMessages && <Message error>{errorMessages}</Message> }
        <Form>
          <Form.Group inline>
            <Form.Field
              label='ค้นหา'
              width='16'
              style={{ width: '100%' }}
              control={Search}
              name='product'
              placeholder='ค้นหา...'
              minCharacters={3}
              results={customerList.response.results.map(e => ({ ...e, key: e.id, value: e.id, title: e.name }))}
              showNoResults={true}
              onSearchChange={(_, data) => {
                if(customerTarget.id) {
                  setCustomerTarget(customerTarget);
                }
                customerList.fetchData({ search: data.value });
                setCustomerTarget({ ...customerTarget, name: data.value });
              }}
              onResultSelect={(e, data) => {
                setCustomerTarget(data.result);
              }}
              loading={customerList.isLoading}
              value={customerTarget.name}/>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button loading={isLoading} content='ยกเลิก' icon='cancel' onClick={onClose}/>
        <Button loading={isLoading} positive content='ยืนยัน' icon='check' onClick={() => handleSelectCustomer()}/>
      </Modal.Actions>
    </Modal>
  )
}