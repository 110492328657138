import React from "react";
import { Button, Dimmer, Form, Header, Input, Loader, Message } from "semantic-ui-react";
import PaginationTable from "../components/common/PaginationTable";
import { URL_CUSTOMER } from "../constance/urls";
import useListItem from "../hooks/useItemList";
import { getColumnsList } from '../frameworks/Util';
import CustomerModal from "../components/customer/CustomerModal";
import { Media } from "../AppMedia";
import MobileList from "../components/common/MobileList";
import CustomInputField from "../components/common/CustomInputField";
import { useStoreContext } from "../hooks/StoreProvider";


export default function CustomerListView() {

  const { setting } = useStoreContext();
  const { staff } = setting;
  const canEdit = setting.use_staff ? staff.edit_customer : true;

  const [searchFilter, setSearchFilter] = React.useState(null);
  const [customerTarget, setCustomerTarget] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const tableRef = React.useRef();
  const mobileListRef = React.useRef();

  const { response, isLoading, errorMessages, fetchData } = useListItem({ 
    url: URL_CUSTOMER.CUSTOMER,
    params: { search: searchFilter, page: page },
    usePagination: true,
    manual: true,
  });

  const [columns, setColumns] = React.useState([
    ...getColumnsList([
			{ Header: 'ชื่อลูกค้า', accessor: 'name' },
			{ Header: 'เลขผู้เสียภาษี', accessor: 'invoice_no', width: 140 },
			{ Header: 'ที่อยู่', accessor: 'address' },
			{ Header: 'เบอร์โทรศัพท์', accessor: 'telephone', width: 120 },
    ])
  ]);

  const content = {
		header: {
			icon: 'user',
			accessor: 'name',
		},
		descriptions: [
			{
				icon: 'address card',
        header: 'เลขผู้เสียภาษี',
        accessor: 'invoice_no',
			},
			{
				icon: 'phone',
        header: 'เบอร์โทรศัพท์',
        accessor: 'telephone',
			}
		],
		onClick: (item) => setCustomerTarget(item)
	}

  React.useEffect(() => {
    if (canEdit) {
      setColumns(columns => [
        ...columns, ...getColumnsList([{
          Header: '', 
          accessor: 'id', 
          width: 110,
          Cell: ({ original }) => <Button
            basic
            icon='edit'
            color='blue'
            content='แก้ไข'
            onClick={() => setCustomerTarget(original)}/>
        }])
      ])
    }
  }, [staff])

  React.useEffect(() => {
    if (!customerTarget) {
      fetchData();
    }
  }, [customerTarget, page])

  return (
    <div style={{ padding: '2rem' }}>
      <Header dividing style={{textAlign: 'left'}}>ลูกค้า</Header>
      <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
      <CustomerModal 
        open={customerTarget != null}
        onClose={(goToInitalPage=false) => {
          if(goToInitalPage) {
            if (tableRef.current) {
              tableRef.current.initialPage()
            } 
            if (mobileListRef.current) {
              mobileListRef.current.initialPage()
            }
          }
          setCustomerTarget(null);
        }}
        detail={customerTarget}/>
      <Media at='sm'>
        <Form>
          { canEdit &&
            <Button floated='right' color='green' icon='add' onClick={() => setCustomerTarget('add')}/>
          }
          <Form.Group inline unstackable>
            <Form.Field width='16'>
              <label>ค้นหา</label>
              <CustomInputField
                width={{ width: '100%' }}
                placeholder='ค้นหาตามชื่อ เบอร์โทรศัพท์...'
                action={{
                  color: 'blue',
                  icon: 'search',
                  onClick: () => fetchData()
                }}/>
            </Form.Field>
          </Form.Group>
        </Form>
        { errorMessages && <Message error>{errorMessages}</Message> }
        <MobileList
          data={response}
          content={content}
          onPageChange={(page) => setPage(page)}/>
      </Media>
      <Media greaterThanOrEqual='md'>      
        <Form>
          { canEdit &&
            <Button floated='right' color='green' icon='add' onClick={() => setCustomerTarget('add')}/>
          }
          <Form.Group inline>
            <CustomInputField isForm width='8' label='ค้นหา' placeholder='ค้นหาตามชื่อ เบอร์โทรศัพท์...' onChange={(_, data) => setSearchFilter(data.value)}/>
            <Form.Button fluid color='blue' icon='search' content='ค้นหา' onClick={() => fetchData()}/>
          </Form.Group>
        </Form>
        { errorMessages && <Message error>{errorMessages}</Message> }
        <PaginationTable
          ref={tableRef}
          columns={columns}
          data={response}
          onPageChange={(page) => setPage(page)}/>
      </Media>
      </div>
  )
}