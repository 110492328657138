import React from 'react';
import { Divider, Grid, Header, Icon, Menu, Segment } from 'semantic-ui-react';
import { COLOR_SET } from '../../constance/utils';
import { Media } from '../../AppMedia';
import Swipable from '../common/Swipable';
import * as _ from 'lodash';


export default function TextWidgets(props) {
  const {
    widgets
  } = props;

  const [activeIndex, setActiveIndex] = React.useState(0);

  return (
    <>
      <Media at='sm'>
        <Swipable
          style={{ marginBottom: '1rem' }}
          onSwipeLeft={() => setActiveIndex(i => (i < widgets.flat().length-1) ? i+1 : 0)}
          onSwipeRight={() => setActiveIndex(i => (i > 0) ? i-1 : widgets.flat().length-1)}
        >
          <Menu secondary style={{ justifyContent: 'space-evenly' }}> 
            <Menu.Item
              icon='left arrow'
              onClick={() => setActiveIndex(i => (i > 0) ? i-1 : widgets.flat().length-1)}/>
            <Menu.Item
              name={_.get(widgets.flat(), `${activeIndex}.title`)}
              color={_.get(widgets.flat(), `${activeIndex}.color`)}
              icon={_.get(widgets.flat(), `${activeIndex}.icon`)}
              active/>
            <Menu.Item
              icon='right arrow'
              onClick={() => setActiveIndex(i => (i < widgets.flat().length-1) ? i+1 : 0)}/>
          </Menu>
          {widgets.flat().map((widget, index) => activeIndex === index && (
            <Segment>
              <Header as='h4' textAlign='left'>
                <Icon name={widget.icon} color={widget.color}/>
                {widget.title}
              </Header>
              <div style={{ textAlign: 'center' }}>
                <span style={{fontSize: 36, fontWeight: 'bold', color: COLOR_SET[widget.color].code}}>
                {widget.value}
                </span> {widget.unit}
              </div>
            </Segment>
          ))}
        </Swipable>
      </Media>
      <Media greaterThanOrEqual='md'>
        <Grid>
          <Grid.Row>
            { widgets.map(widget => {
              const isArray = Array.isArray(widget);
              return (
                <Grid.Column width={(16/widgets.flat().length) * (Array.isArray(widget) ? widget.length : 1)}>
                  <Segment stackable={isArray}>
                    { isArray ? (
                      <Grid divided columns='equal'>
                        {widget.map(subwidget => (
                          <Grid.Column>
                            <Header as='h4' textAlign='left'>
                            <Icon name={subwidget.icon} color={subwidget.color}/>
                              {subwidget.title}
                            </Header>
                            <div style={{ textAlign: 'center' }}>
                              <span style={{fontSize: 36, fontWeight: 'bold', color: COLOR_SET[subwidget.color].code}}>
                              {subwidget.value}
                              </span> {subwidget.unit}
                            </div>
                          </Grid.Column>
                        ))}
                      </Grid>
                    ) : (
                      <>
                        <Header as='h4' textAlign='left'>
                          <Icon name={widget.icon} color={widget.color}/>
                          {widget.title}
                        </Header>
                        <div style={{ textAlign: 'center' }}>
                          <span style={{fontSize: 36, fontWeight: 'bold', color: COLOR_SET[widget.color].code}}>
                          {widget.value}
                          </span> {widget.unit}
                        </div>
                      </>  
                    ) }
                  </Segment>
                </Grid.Column>
              )
            }) }
          </Grid.Row>
        </Grid>
      </Media>
    </>
  )
}