import moment from 'moment';
import React from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Button, Dimmer, Dropdown, Form, Loader, Message, Menu } from 'semantic-ui-react';
import useListItem from '../../hooks/useItemList';
import * as _ from 'lodash';
import { Media } from '../../AppMedia';
import { URL_REPORT } from '../../constance/urls';
import { dateToString, formatComma, getColumnsList } from "../../frameworks/Util";
import MobileList from '../common/MobileList';
import PaginationTable from '../common/PaginationTable';
import ShareReportModal from './ShareReportModal';
import { useStoreContext } from '../../hooks/StoreProvider';


export default function ReportList(props) {

  const { setting } = useStoreContext();
  const canExport = setting.use_staff ? setting.staff.export_report : true;

  const [isShareReportOpen, setIsShareReportOpen] = React.useState(false);
  const [isShareRangeReportOpen, setShareRangeReportOpen] = React.useState(false);
  const [isShareSaleReportOpen, setShareSaleReportOpen] = React.useState(false);
  const [isShareSaleTaxReportOpen, setShareSaleTaxReportOpen] = React.useState(false);
  const [isPurchaseTypeReportOpen, setPurchaseTypeReportOpen] = React.useState(false);
  const [isSaleEmployeeReportOpen, setSaleEmployeeReportOpen] = React.useState(false);
  const [isSaleProductReportOpen, setSaleProductReportOpen] = React.useState(false);
  const [isSaleCategoryReportOpen, setSaleCategoryReportOpen] = React.useState(false);

  const [dateRange, setDateRange] = React.useState([]);
  const { response, isLoading, errorMessages, fetchData } = useListItem({ 
    url: URL_REPORT.REPORT_LINE_CHART,
    params: {
      date_after: _.get(dateRange, 0, false) ? moment(dateRange[0]).format('YYYY-MM-DD') : null,
			date_before: _.get(dateRange, 1, false) ? moment(dateRange[1]).format('YYYY-MM-DD') : null,
    },
    usePagination: true,
  });

  const columns = React.useMemo(() => [
    ...getColumnsList([
			{ Header: 'วันที่', accessor: 'date', Cell: ({value}) => dateToString(value) },
			{ Header: 'รายได้ทั้งหมด (บาท)', accessor: 'total_gain', Cell: ({value}) => formatComma(value) },
			{ Header: 'ส่วนลด (บาท)', accessor: 'total_discount', Cell: ({value}) => formatComma(value) },
			{ Header: 'กำไร (บาท)', accessor: 'total_profit', Cell: ({value}) => formatComma(value, true) },
			{ Header: 'จำนวนใบเสร็จ', accessor: 'total_transaction', Cell: ({value}) => formatComma(value) },
			{ Header: 'จำนวนสินค้าที่ขายได้', accessor: 'total_product_count', Cell: ({value}) => formatComma(value) },
      { Header: 'ราคารวมสินค้าไม่มีภาษี', accessor: 'total_no_vat', Cell: ({value}) => formatComma(value) },
      { Header: 'ราคารวมสินค้ามีภาษี', accessor: 'total_include_vat', Cell: ({value}) => formatComma(value) },
      { 
        Header: 'ราคารวมหลังหักภาษีมูลค่าเพิ่ม', 
        accessor: 'total_before_vat', 
        show: false,
        Cell: ({value}) => formatComma(value) 
      },
      { 
        Header: 'ภาษีมูลค่าเพิ่ม', 
        accessor: 'total_vat',
        show: false, 
        Cell: ({value}) => formatComma(value) 
      },
    ])
  ]);

  const content = {
    header: {
      icon: 'clock outline',
      header: 'วันที่',
      detail: (item) => dateToString(item.date),
    },
		descriptions: [
			{ header: 'รายได้ทั้งหมด', icon: 'caret right', detail: (item) => `${formatComma(item.total_gain)} บาท` },
			{ header: 'ส่วนลด', icon: 'caret right', detail: (item) => `${formatComma(item.total_discount)} บาท` },
			{ header: 'กำไร', icon: 'caret right', detail: (item) => `${formatComma(item.total_profit, true)} บาท` },
			{ header: 'จำนวนใบเสร็จ', icon: 'caret right', detail: (item) => formatComma(item.total_transaction) },
			{ header: 'จำนวนสินค้าที่ขายได้', icon: 'caret right', detail: (item) => formatComma(item.total_product_count) },
    ]
  }

  const sharedReportMenu = [
    {
      icon: 'chart line',
      text: 'แชร์ยอดขาย',
      onClick: () => setShareRangeReportOpen(true),
    },
    {
      icon: 'file alternate outline',
      text: 'แชร์รายละเอียดบิล',
      onClick: () => setIsShareReportOpen(true)
    },
    {
      icon: 'dollar sign',
      text: 'แชร์รายงานการขาย',
      onClick: () => setShareSaleReportOpen(true)
    },
    {
      icon: 'address book outline',
      text: 'แชร์รายงานภาษีขาย',
      permissions: setting.use_tax_invoice,
      onClick: () => setShareSaleTaxReportOpen(true),
    },  
    {
      icon: 'file alternate outline',
      text: 'รายงานยอดขายแยกตามประเภทสินค้า',
      onClick: () => setSaleCategoryReportOpen(true),
    },    
    {
      icon: 'address book outline',
      text: 'รายงานยอดขายตามพนักงาน',
      onClick: () => setSaleEmployeeReportOpen(true),
    },
    {
      icon: 'chart bar',
      text: 'รายงานยอดขายตามรายการสินค้า',
      onClick: () => setSaleProductReportOpen(true),
    },
    {
      icon: 'dollar sign',
      text: 'แชร์รายงานแยกประเภทการชำระเงิน',
      onClick: () => setPurchaseTypeReportOpen(true),
    }, 
  ]

  const sharedReportMenuButton = () => canExport ? (
    <>
      <Media greaterThanOrEqual='md'>
        <Dropdown
          text='แชร์รายงาน'
          icon='mail'
          className='icon button teal right floated'
          floating
          labeled
          button
        >
          <Dropdown.Menu direction='left'>
            { sharedReportMenu.filter(menu => menu.permissions != false).map(menu => (
              <Dropdown.Item direction='left' icon={menu.icon} text={menu.text} onClick={menu.onClick}/>
            )) }
          </Dropdown.Menu>
        </Dropdown>
      </Media>
      <Media at='sm'>
        <Dropdown
          floating
          text='แชร์รายงาน'
          icon='mail'
          className='icon button teal'
          labeled
          button
        >
          <Dropdown.Menu>
            { sharedReportMenu.filter(menu => menu.permissions != false).map(menu => (
              <Dropdown.Item icon={menu.icon} text={menu.text} onClick={menu.onClick}/>
            )) }
          </Dropdown.Menu>
        </Dropdown>
      </Media>
    </>
  ) : null

  return (
    <div>
      <ShareReportModal 
        header={"แชร์รายละเอียดบิล"}
        open={isShareReportOpen} 
        onClose={() => setIsShareReportOpen(false)} 
        baseURL={URL_REPORT.SHARE_REPORT_SUMMARY} />
      
      <ShareReportModal 
        header={"แชร์ยอดขาย"}
        open={isShareRangeReportOpen} 
        onClose={() => setShareRangeReportOpen(false)} 
        isDateRequire={true}
        useDateRange={true}
        baseURL={URL_REPORT.SHARE_REPORT_DATE_SUMMARY} />

      <ShareReportModal 
        header={"แชร์รายงานขาย"}
        open={isShareSaleReportOpen} 
        onClose={() => setShareSaleReportOpen(false)} 
        isDateRequire={true}
        useDateRange={true}
        baseURL={URL_REPORT.SHARE_SALE_REPORT} />

      <ShareReportModal 
        header={"แชร์รายงานภาษีขาย"}
        open={isShareSaleTaxReportOpen} 
        onClose={() => setShareSaleTaxReportOpen(false)} 
        isDateRequire={true}
        useDateRange={true}
        baseURL={URL_REPORT.SHARE_SALE_TAX_REPORT} />

      <ShareReportModal 
        header={"แชร์รายงานแยกประเภทการชำระเงิน"}
        open={isPurchaseTypeReportOpen} 
        onClose={() => setPurchaseTypeReportOpen(false)} 
        isDateRequire={true}
        useDateRange={true}
        isStaffRequire={true}
        baseURL={URL_REPORT.SHARE_PURCHASE_TYPE_REPORT}/>

      <ShareReportModal 
        header={"แชร์รายงานแยกประเภทของสินค้า"}
        open={isSaleCategoryReportOpen} 
        onClose={() => setSaleCategoryReportOpen(false)} 
        isDateRequire={true}
        useDateRange={true}
        isStaffRequire={true}
        baseURL={URL_REPORT.SHARE_SALE_CATEGORY_REPORT} />

      <ShareReportModal 
        header={"แชร์รายงานแยกตามสินค้า"}
        open={isSaleProductReportOpen} 
        onClose={() => setSaleProductReportOpen(false)} 
        isDateRequire={true}
        useDateRange={true}
        isStaffRequire={true}
        baseURL={URL_REPORT.SHARE_SALE_PRODUCT_REPORT} />

      <ShareReportModal 
        header={"แชร์รายงานแยกตามพนักงานขาย"}
        open={isSaleEmployeeReportOpen} 
        onClose={() => setSaleEmployeeReportOpen(false)} 
        isDateRequire={true}
        useDateRange={true}
        baseURL={URL_REPORT.SHARE_SALE_EMPLOYEE_REPORT} />

      <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
      <Form>
        <Media greaterThanOrEqual='md'>
          {sharedReportMenuButton()}
        </Media>
        <Form.Group inline>
          <SemanticDatepicker
            datePickerOnly={true}
            maxDate={new Date().setHours(23, 59, 59, 999)}
            style={{ width: '100%' }}
            label='ค้นหา'
            locale='en-US'
            type='range'
            showToday={true}
            format='DD/MM/YY'
            placeholder='เลือกช่วงเวลา...'
            value={dateRange}
            onChange={(_, data) => setDateRange(data.value)}/>
          <Media at='sm'>
            <Form.Button fluid color='blue' icon='search' onClick={() => fetchData()}/>
          </Media>
          <Media greaterThanOrEqual='md'>
            <Form.Button fluid color='blue' icon='search' content='ค้นหา' onClick={() => fetchData()}/>
          </Media>
        </Form.Group>
      </Form>
      <Media at='sm'>
          {sharedReportMenuButton()}
        </Media>
      { errorMessages && <Message error>{errorMessages}</Message> }
      <Media at='sm'>
        <MobileList
          data={response}
          content={content}
          onPageChange={(page) => fetchData({ page })}/>
      </Media>
      <Media greaterThanOrEqual='md'>
        <PaginationTable
          columns={columns}
          data={response}
          onPageChange={(page) => fetchData({ page })}/>
      </Media>
    </div>
  )
}
