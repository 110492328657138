import React from 'react';
import { Button, Divider, Header } from 'semantic-ui-react';
import StoreDetail from '../components/store/StoreDetail';
import StoreList from '../components/store/StoreList';
import StoreUserList from '../components/store/StoreUserList';


export default function OfficerStoreView(props) {

  const [storeTarget, setStoreTarget] = React.useState(null);

  return (
    <div style={{ padding: '2rem' }}>
      <Header dividing style={{textAlign: "left"}}>
        { storeTarget &&
          <Button
            style={{ boxShadow: '0 0 0 0' }}
            basic
            onClick={() => setStoreTarget(null)}
            icon='left arrow'/>
        }
        { storeTarget ? `รายละเอียดร้านค้า` : 'ร้านค้า' }
      </Header>
      { storeTarget ? 
        (<div>
          <StoreDetail target={storeTarget} isOfficer/>
          <Divider/>
          <StoreUserList target={storeTarget}/>
        </div>) : 
        <StoreList setStoreTarget={setStoreTarget}/> 
      }
    </div>
  )
}