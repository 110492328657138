import React from 'react';
import PropTypes from "prop-types";
import { Header, Menu } from 'semantic-ui-react';
import DashBoard from '../components/report/DashBoard';
import ReportList from '../components/report/ReportList';
import { useStoreContext } from '../hooks/StoreProvider';
import StaffShiftReport from '../components/report/StaffShiftReport';


export default function ReportView(props) {
  const [activeItem, setActiveItem] = React.useState('dashboard');
  const { setting } = useStoreContext();

  const renderMenu = () => {
    switch(activeItem) {
      case 'dashboard': return <DashBoard/>;
      case 'report': return <ReportList/>;
      case 'shift_report': return <StaffShiftReport/>;
      default: return null;
    }
  }

  return (
    <div style={{ padding: '2rem' }}>
      <Header dividing style={{textAlign: "left"}}>สรุปยอดขาย</Header>
      <Menu pointing secondary>
        <Menu.Item
          icon='chart pie'
          name='ภาพรวม'
          active={activeItem === 'dashboard'}
          onClick={() => setActiveItem('dashboard')}/>
        <Menu.Item
          icon='file outline alternate'
          name='รายงาน'
          active={activeItem === 'report'}
          onClick={() => setActiveItem('report')}/>
        {/* { setting.use_staff  && (
        <Menu.Item
          icon='address card outline'
          name='กะพนักงาน'
          active={activeItem === 'shift_report'}
          onClick={() => setActiveItem('shift_report')}/>
        )} */}
      </Menu>
      {renderMenu()}
    </div>
  )
}
