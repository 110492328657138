import { useFormik } from 'formik';
import React from 'react';
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';
import { Toast } from '../../frameworks/Toast';
import { POST } from '../../frameworks/HttpClient';
import { URL_STORE } from '../../constance/urls';
import CustomInputField from '../common/CustomInputField';


export default function StoreModal(props) {
  const {
    open,
    onClose,
  } = props

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('กรุณากรอกข้อมูล'),
    }),
    onSubmit: async (values) => {
      setErrorMessages(null);
      setIsLoading(true);
      try {
        await POST(URL_STORE.OFFICER_STORE, values)
        Toast.success('สำเร็จ');
        onClose();
      } catch(error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setIsLoading(false)
      }
    }
  })

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeIcon
      closeOnDimmerClick={false}>
      <Modal.Header><Icon name='home'/>สร้างร้านค้า</Modal.Header>
      <Modal.Content>
        { errorMessages && <Message error>{errorMessages}</Message> }
        <Form>
          <CustomInputField
            isForm
            name='name'
            label='ชื่อร้านค้า'
            placeholder='ชื่อร้านค้า'
            value={formik.values.name}
            error={formik.errors.name}
            onChange={formik.handleChange}/>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button loading={isLoading} content='ยกเลิก' icon='cancel' onClick={onClose}/>
        <Button 
          loading={isLoading}
          content='สร้าง'
          color='green'
          icon='add'
          onClick={formik.handleSubmit}/>
      </Modal.Actions>
    </Modal>
  )
}