import React from "react";
import PropTypes from "prop-types";
import { Accordion, Button, Dimmer, Form, Icon, Loader, Menu, Message } from "semantic-ui-react";
import { URL_SALE, URL_REPORT } from "../../constance/urls";
import { dateTimeToString, formatComma, getColumnsList } from "../../frameworks/Util";
import useListItem from "../../hooks/useItemList";
import PaginationTable from "../common/PaginationTable";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import moment from "moment";
import * as _ from 'lodash';
import { Media } from "../../AppMedia";
import MobileList from "../common/MobileList";
import CustomInputField from "../common/CustomInputField";
import ShareReportModal from "../report/ShareReportModal";
import { useStoreContext } from "../../hooks/StoreProvider";

let isActivePage = true
export default function SaleTransactionList(props) {
  const {
    setSaleTransactionTarget,
  } = props;

  const { setting } = useStoreContext();
  const { staff } = setting;
  const canExport = setting.use_staff ? staff.export_report : true;

  const [dateRange, setDateRange] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState('');
  const [filterActive, setFilterActive] = React.useState(false);
  const [isActiveFilter, setActiveFilter] = React.useState(isActivePage);
  const [isShareReportOpen, setIsShareReportOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const { response, isLoading, errorMessages, fetchData } = useListItem({ 
    url: URL_SALE.SALE_TRANSACTION,
    params: {
      date_after: _.get(dateRange, 0, false) ? moment(dateRange[0]).format('YYYY-MM-DD') : null,
			date_before: _.get(dateRange, 1, false) ? moment(dateRange[1]).format('YYYY-MM-DD') : null,
      search: searchFilter,
      is_active: isActiveFilter
    },
    manual: true,
    usePagination: true
  });

  const columns = React.useMemo(() => [
    ...getColumnsList([
			{ Header: 'เลขที่บิล', accessor: 'receipt_no' },
			{ Header: 'ชื่อลูกค้า', accessor: 'customer_detail.name' },
			{ Header: 'เวลาลงบิล', accessor: 'paid_datetime', Cell: ({value}) => dateTimeToString(value) },
			{ Header: 'ราคาบิล (บาท)', accessor: 'net_price', Cell: ({value}) => formatComma(value) },
      { Header: 'ชื่อผู้ขาย', accessor: 'staff_name', show: setting.use_staff},
			{ Header: '', accessor: 'id', Cell: ({value}) => (
        <Button
          basic
          icon='file alternate outline'
          color='blue'
          content='รายละเอียด'
          onClick={() => setSaleTransactionTarget(value)}/>
      )},
    ])
  ])

  const content = {
		header: {
			icon: 'file alternate',
			accessor: 'receipt_no',
		},
		descriptions: [
			{
				icon: 'user',
        header: 'ชื่อลูกค้า',
        detail: (item) => item.customer_detail ? item.customer_detail.name : '-',
			},
			{
				icon: 'clock outline',
        header: 'เวลาลงบิล',
        detail: (item) => dateTimeToString(item.paid_datetime),
			},
      {
				icon: 'money outline',
        header: 'ราคาบิล',
        detail: (item) => `${formatComma(item.net_price)} บาท`,
			}
		],
		onClick: (item) => setSaleTransactionTarget(item.id)
	}

  React.useEffect(() => {
    if (page == 1) {
      fetchData({page: page})
    } else {
      setPage(1)
    }
    isActivePage = isActiveFilter

  }, [isActiveFilter])

  React.useEffect(() => {
    fetchData({page: page})
  }, [page])

  return (
    <div>
      <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
      <Media lessThan='lg'>
        <Accordion fluid>
          <Accordion.Title 
            active={filterActive}
            onClick={() => setFilterActive(state => !state)}>
            <Icon name='dropdown' />
            ค้นหา
          </Accordion.Title>
          <Accordion.Content active={filterActive}>
            <Form>
              <Form.Group widths='equal'>
                <SemanticDatepicker
                  datePickerOnly={true}
                  maxDate={new Date().setHours(23, 59, 59, 999)}
                  style={{ width: '100%' }}
                  locale='en-US'
                  type='range'
                  showToday={true}
                  format='DD/MM/YY'
                  placeholder='เลือกช่วงเวลา...'
                  value={dateRange}
                  onChange={(_, data) => setDateRange(data.value)}/>
                <CustomInputField
                  isForm
                  placeholder='ค้นหาตามเลขที่บิล ลูกค้า...'
                  value={searchFilter}
                  onChange={(_, data) => setSearchFilter(data.value)}/>
                <Form.Button color='blue' icon='search' content='ค้นหา' onClick={() => fetchData()}/>
              </Form.Group>
            </Form>
          </Accordion.Content>
        </Accordion>
      </Media>
      <Media greaterThan='md'>
        <Form>
          <Form.Group inline unstackable>
            <SemanticDatepicker
              datePickerOnly={true}
              maxDate={new Date().setHours(23, 59, 59, 999)}
              style={{ width: '100%' }}
              label='ค้นหา'
              locale='en-US'
              type='range'
              showToday={true}
              format='DD/MM/YY'
              placeholder='เลือกช่วงเวลา...'
              value={dateRange}
              onChange={(_, data) => setDateRange(data.value)}/>
            <CustomInputField
              isForm
              placeholder='ค้นหาตามเลขที่บิล ลูกค้า...'
              value={searchFilter}
              onChange={(_, data) => setSearchFilter(data.value)}/>
            <Form.Button color='blue' icon='search' content='ค้นหา' onClick={() => fetchData()}/>
          </Form.Group>
        </Form>
      </Media>
      { errorMessages && <Message error>{errorMessages}</Message> }
      <Menu pointing secondary>
        <Menu.Item
          name='บิลที่ใช้งาน'
          active={isActiveFilter}
          onClick={() => setActiveFilter(true)}/>
        <Menu.Item
          name='บิลที่ยกเลิก'
          active={!isActiveFilter}
          onClick={() => setActiveFilter(false)}/>
      </Menu>

      <Media at='sm'>
        {canExport && (
          <Button
            color='teal'
            icon='mail'
            content='แชร์รายงาน'
            onClick={() => setIsShareReportOpen(true)}/> 
        )}
        <MobileList
          data={response}
          content={content}
          onPageChange={(page) => setPage(page)}/>
        </Media>
      <Media greaterThanOrEqual='md'>
        {canExport && (
          <Button
            style={{marginBottom: 8}}
            color='teal'
            icon='mail'
            content='แชร์รายงาน'
            labelPosition='right'
            onClick={() => setIsShareReportOpen(true)}/>
        )}
        <PaginationTable
          columns={columns}
          data={response}
          onPageChange={(page) => setPage(page)}/>
      </Media>

      <ShareReportModal 
        open={isShareReportOpen} 
        onClose={() => setIsShareReportOpen(false)} 
        isDateRequire={true}
        useDateRange={true}
        baseURL={URL_REPORT.SHARE_SALE_SUMMARY}
        params={{is_active: isActiveFilter}} />
    </div>
  )
}