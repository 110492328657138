import React from 'react';
import { Button, Dimmer, Form, Input, Loader, Message } from 'semantic-ui-react';
import { Media } from '../../AppMedia';
import { URL_STORE } from '../../constance/urls';
import { getColumnsList } from '../../frameworks/Util';
import useListItem from '../../hooks/useItemList';
import CustomInputField from '../common/CustomInputField';
import MobileList from '../common/MobileList';
import PaginationTable from '../common/PaginationTable';
import StoreModal from './StoreModal';


export default function StoreList(props) {

  const {
    setStoreTarget
  } = props;

  const [createStoreVisible, setCreateStoreVisible] = React.useState(false);
  const [searchFilter, setSearchFilter] = React.useState(null);
  const { response, isLoading, errorMessages, fetchData } = useListItem({ 
    url: URL_STORE.OFFICER_STORE,
    params: { search: searchFilter },
    usePagination: true,
    manual: true,
  });

  const columns = React.useMemo(() => [
    ...getColumnsList([
			{ Header: 'ชื่อร้านค้า', accessor: 'name' },
			{ Header: 'เลขผู้เสียภาษี', accessor: 'tax_id' },
			{ Header: 'เบอร์โทรศัพท์', accessor: 'telephone' },
			{ Header: '', accessor: 'id', Cell: ({ value }) => 
        <Button
          basic
          icon='file outline alternate'
          color='blue'
          content='รายละเอียด'
          onClick={() => setStoreTarget(value)}/>
      },
    ])
  ]);
  
  const content = {
    header: {
			icon: 'home',
			accessor: 'name',
		},
		descriptions: [
			{
				icon: 'address card',
        header: 'เลขผู้เสียภาษี',
        accessor: 'tax_id',
			},
			{
				icon: 'phone',
        header: 'เบอร์โทรศัพท์',
        accessor: 'telephone',
			}
		],
		onClick: (item) => setStoreTarget(item.id)
  }

  React.useEffect(() => {
    if(!createStoreVisible) {
      fetchData();
    }
  }, [createStoreVisible])

  return (
    <div>
      <StoreModal open={createStoreVisible} onClose={() => setCreateStoreVisible(false)}/>
      <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
      <Form>
        <Media at='sm'>
          <Button floated='right' color='green' icon='add' onClick={() => setCreateStoreVisible(true)}/>
          <Form.Group inline>
            <Form.Field width='16'>
              <label>ค้นหา</label>
              <CustomInputField
                width={{ width: '100%' }}
                placeholder='ค้นหาตามชื่อ เบอร์โทรศัพท์...'
                action={{
                  color: 'blue',
                  icon: 'search',
                  onClick: () => fetchData()
                }}/>
            </Form.Field>
          </Form.Group>
        </Media>
        <Media greaterThanOrEqual='md'>
          <Button floated='right' color='green' icon='add' content='สร้าง' onClick={() => setCreateStoreVisible(true)}/>
          <Form.Group inline>
            <CustomInputField isForm width='8' label='ค้นหา' placeholder='ค้นหาตามชื่อหรือเลขผู้เสียภาษี...' onChange={(_, data) => setSearchFilter(data.value)}/>
            <Form.Button fluid color='blue' icon='search' content='ค้นหา' onClick={() => fetchData()}/>
          </Form.Group>
        </Media>
      </Form>
      { errorMessages && <Message error>{errorMessages}</Message> }
      <Media at='sm'>
        <MobileList
          data={response}
          content={content}
          onPageChange={(page) => fetchData({ page })}/>        
      </Media>
      <Media greaterThanOrEqual='md'>
        <PaginationTable
          columns={columns}
          data={response}
          onPageChange={(page) => fetchData({ page })}/>
      </Media>
    </div>
  )
}