import React from 'react';
import { Button, Dimmer, Form, Loader, Message, Checkbox, Confirm } from 'semantic-ui-react';
import UserList from './UserList';
import UserModal from './UserModal';
import ResetPINModal from './ResetPINModal';
import { URL_STORE } from '../../constance/urls';
import { PATCH } from '../../frameworks/HttpClient';
import { useStoreContext } from '../../hooks/StoreProvider';
import * as _ from 'lodash';


export default function StoreUsers() {
	const { enableUseStaff, disableUseStaff, setting } = useStoreContext();

	const [isLoading, setIsLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [editPinTarget, setEditPinTarget] = React.useState(null);
	const [disableConfirmModalOpen, setDisableConfirmModalOpen] = React.useState(false);
	const [temporaryUseStaff, setTemporaryUseStaff] = React.useState(setting.use_staff);
	const listRef = React.useRef()

	// user modal
	const [isUserEmpty, setIsUserEmpty] = React.useState(false);
	const [userTarget, setUserTarget] = React.useState(null);
	const [userModalOpen, setUserModalOpen] = React.useState(false);

	const handleUseStaffCheckbox = (_, data) => {
		if (data.checked) {
			handleEnableStaff()
		} else {
			setDisableConfirmModalOpen(true)
		}
	}

	const handleStaffChange = async (staffs) => {
		const staffCount = _.get(staffs, 'length', 0)
		setIsUserEmpty(staffCount == 0)
		if (temporaryUseStaff) {
			if (staffCount > 0) {
				let found = await staffs.find(e => e.is_manager)
				enableUseStaff(found)
			} else {
				openUserModal()
			}
		}
	}

	const openUserModal = (detail=null) => {
		setUserTarget(detail)
		setUserModalOpen(true)
	}

	const closeUserModal = () => {
		setUserModalOpen(false)
		setUserTarget(false)
		if (listRef.current) {
			listRef.current.reload();
		}
	}

	const handleDisableStaff = async () => {
		setIsLoading(true)
		try {
			await PATCH(URL_STORE.STORE_UPDATE_STAFF, {use_staff: false});
			disableUseStaff();
			setTemporaryUseStaff(false);
			setDisableConfirmModalOpen(false);
		} catch(error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setIsLoading(false);
		}
	}

	const handleEnableStaff = async () => {
		setIsLoading(true)
		try {
			await PATCH(URL_STORE.STORE_UPDATE_STAFF, {use_staff: true});
			setTemporaryUseStaff(true)
		} catch(error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setIsLoading(false);
		}
	}

	React.useEffect(() => {
		if (temporaryUseStaff && listRef.current) {
			listRef.current.reload();
		}
	}, [temporaryUseStaff])

	return (
		<>
			<Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
			{ errorMessages && <Message error>{errorMessages}</Message> }
			<Form.Field
				control={Checkbox}
				label='เปิดการใช้งาน feature ผู้ใช้'
				checked={temporaryUseStaff}
				onChange={handleUseStaffCheckbox}/>
			{ temporaryUseStaff && (
				<div>
					<Button content='เพิ่มผู้ใช้' icon='add' color='green' onClick={() => openUserModal()}/>
					<UserList
						ref={listRef}
						onEdit={(target) => openUserModal(target)}
						onResetPIN={(item) => setEditPinTarget(item)}
						onStaffChange={handleStaffChange}/>
				</div>
			) }

			<UserModal 
				open={userModalOpen}
				onClose={() => closeUserModal()}
				onCancel={() => closeUserModal()}
				detail={userTarget}
				isManager={_.get(userTarget, 'is_manager', false) || isUserEmpty}/>
			
			<ResetPINModal
				open={editPinTarget != null}
				onClose={() => setEditPinTarget(null)}
				detail={editPinTarget}/>

			<Confirm
        open={disableConfirmModalOpen}
        content='ต้องการปิดการใช้งานผู้ใช้ ใช่หรือไม่?'
        cancelButton='ยกเลิก'
        confirmButton='ปิดการใช้งาน'
        onCancel={() => {
					setDisableConfirmModalOpen(false)
				}}
        onConfirm={() => {
					handleDisableStaff()
				}}/>
		</>
	)
}
