import { useFormik } from 'formik';
import React from 'react';
import { Button, Confirm, Form, Icon, Image, Input, Message, Modal } from 'semantic-ui-react';
import { ProductModel } from '../../constance/models';
import { URL_PRODUCT } from '../../constance/urls';
import useDropdownItem from '../../hooks/useDropdownItem';
import * as Yup from 'yup';
import { DELETE, PATCH, POST } from '../../frameworks/HttpClient';
import { Toast } from '../../frameworks/Toast';
import CustomFileField from '../common/CustomFileField';
import { convertNullToEmpty } from '../../frameworks/Util';
import AddProductCategoryModal from './AddProductCategoryModal';
import CustomInputField from '../common/CustomInputField';


export default function OfficerProductModal(props) {
  const {
    open,
    onClose,
    detail,
  } = props;

  const productCategory = useDropdownItem({url: URL_PRODUCT.OFFICER_PRODUCT_CATEGORY, manual: true});
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [deleteConfirmVisible, setDeleteConfirmVisible] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [openAddCategory, setOpenAddCategory] = React.useState(false);

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: ProductModel,
    validationSchema: Yup.object().shape({
      barcode_no: Yup.string().required('กรุณากรอกข้อมูล'),
      name: Yup.string().required('กรุณากรอกข้อมูล'),
    }),
    onSubmit: async (values) => {
      setErrorMessages(null);
      setIsLoading(true);
      try {
        let params = { ...convertNullToEmpty(values), image: file  };
        if (!file) {
          delete params['image'];
        }
        if(detail === 'add') {
          await POST(URL_PRODUCT.OFFICER_PRODUCT, params, true);
        } else {
          await PATCH(`${URL_PRODUCT.OFFICER_PRODUCT}${detail.id}/`, params, true);
        }
        Toast.success('สำเร็จ');
        onClose();
      } catch(error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setIsLoading(false)
      }
    }
  })

  const handleDelete = async () => {
    setDeleteConfirmVisible(false);
    setErrorMessages(null);
    setIsLoading(true);
    try {
      await DELETE(`${URL_PRODUCT.OFFICER_PRODUCT}${detail.id}/`);
      Toast.success('สำเร็จ');
      onClose(true);
    } catch(error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (open) {
      productCategory.fetchData();
      formik.resetForm();
      setFile(null);
      if (detail != 'add') {
        formik.setValues(detail);
      }
    }
  }, [open])

  return (
    <div>
      <AddProductCategoryModal
        open={openAddCategory}
        onClose={() => setOpenAddCategory(false)}
        isOfficer={true}
        onSuccess={(value) => {
          productCategory.fetchData();
          formik.setFieldValue('category', value)
          setOpenAddCategory(false);
        }}/>
      <Confirm 
        open={deleteConfirmVisible}
        content='ต้องการลบสินค้าดังกล่าวใช่หรือไม่?'
        cancelButton='ยกเลิก'
        confirmButton='ลบ'
        onCancel={() => setDeleteConfirmVisible(false)}
        onConfirm={handleDelete}/>
      <Modal
        open={open}
        onClose={() => onClose()}
        closeIcon
        closeOnDimmerClick={false}>
        <Modal.Header><Icon name='box'/>{ detail === 'add' ? 'เพิ่ม' : 'ข้อมูล' }สินค้า</Modal.Header>
        <Modal.Content>
          { errorMessages && <Message error>{errorMessages}</Message> }
          <Form>
            <CustomInputField
              isForm
              fluid
              label='Barcode'
              name='barcode_no'
              icon='barcode'
              iconPosition='left'
              placeholder='Barcode'
              value={formik.values.barcode_no}
              error={formik.errors.barcode_no}
              onChange={formik.handleChange}/>
            <Form.Group widths='equal'>
              <CustomInputField
                isForm
                name='name'
                label='ชื่อสินค้า'
                icon='box'
                iconPosition='left'
                placeholder='ชื่อสินค้า'
                value={formik.values.name}
                error={formik.errors.name}
                onChange={formik.handleChange}/>
              <Form.Field>
                <label>ประเภทสินค้า <a onClick={() => setOpenAddCategory(true)}>+ เพิ่ม</a></label>
                <Form.Select
                  name='category'
                  search
                  clearable
                  options={productCategory.response}
                  placeholder='ประเภทสินค้า'
                  value={formik.values.category}
                  error={formik.errors.category}
                  onChange={(_, data) => {
                    formik.setFieldValue('category', data.value)
                  }}/>
              </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field>
                <label>ราคาทุน</label>
                <CustomInputField
                  name='cost_price'
                  placeholder='ราคาทุน'
                  icon='money bill alternate'
                  iconPosition='left'
                  label={{ tag: true, content: 'บาท' }}
                  labelPosition='right'
                  type='number'
                  step='1'
                  min='0'
                  value={formik.values.cost_price}
                  error={formik.errors.cost_price}
                  onChange={formik.handleChange}/>
              </Form.Field>
              <Form.Field>
                <label>ราคาขาย</label>
                <CustomInputField
                  name='sell_price'
                  placeholder='ราคาขาย'
                  icon='money bill alternate outline'
                  iconPosition='left'
                  label={{ tag: true, content: 'บาท' }}
                  labelPosition='right'
                  type='number'
                  step='1'
                  min='0'
                  value={formik.values.sell_price}
                  error={formik.errors.sell_price}
                  onChange={formik.handleChange}/>
              </Form.Field>
            </Form.Group>
            <Form.TextArea
              name='note'
              label='หมายเหตุ'
              placeholder='หมายเหตุ'
              value={formik.values.note}
              error={formik.errors.note}
              onChange={formik.handleChange}
              onFocus={(event) => event.target.select()}/>
            <Form.Field label='รูปภาพ' />
            <Image size='small' src={file ? URL.createObjectURL(file) : formik.values.image}/>
            <Form.Group widths='2'>
              <CustomFileField
                value={file ? file.name : ''}
                onChange={(file) => setFile(file)}/>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          { detail != 'add' && 
            <Button
              basic
              color='red'
              content='ลบ'
              icon='trash'
              floated='left'
              onClick={() => setDeleteConfirmVisible(true)}/>
          }
          <Button loading={isLoading} content='ยกเลิก' icon='cancel' onClick={onClose}/>
          <Button 
            loading={isLoading}
            content={ detail === 'add' ? 'เพิ่ม' : 'แก้ไข'}
            color={ detail === 'add' ? 'green' : 'blue'}
            icon={ detail === 'add' ? 'add' : 'edit'}
            onClick={formik.handleSubmit}/>
        </Modal.Actions>
      </Modal>
    </div>
  );
}