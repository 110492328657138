import { useFormik } from 'formik';
import React from 'react';
import { Accordion, Button, Form, Header, Icon, Label, Message, Search } from 'semantic-ui-react';
import { URL_PRODUCT, URL_STOCK } from '../../constance/urls';
import useListItem from '../../hooks/useItemList';
import * as Yup from "yup";
import * as _ from 'lodash';
import { GET, POST } from '../../frameworks/HttpClient';
import { Toast } from '../../frameworks/Toast';
import QRCodeReaderModal from '../common/QRCodeReaderModal';
import CustomInputField from '../common/CustomInputField';
import CustomSearchResult from '../common/CustomSearchResult';
import { formatComma } from '../../frameworks/Util';
import { productSearchRenderer } from '../../constance/models/product';


export default function StockInputSegment(props) {
  const {
    fetchData
  } = props;

  const defaultData = { barcode_no: '', name: '', current_amount: 0 }

  const [openQRCodeModal, setOpenQRCodeModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [productData, setProductData] = React.useState(defaultData)
  const productList = useListItem({ url: URL_PRODUCT.PRODUCT, usePagination: true, manual: true, params: { page_size: 5 } });

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      product: '',
      cost_price: 0,
      amount: 0,
    },
    validationSchema: Yup.object().shape({
      product: Yup.string().required('ไม่พบสินค้าในระบบ').nullable(),
      amount: Yup.number().moreThan(0, 'กรุณากรอกข้อมูล'),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      setErrorMessages(null);
      try {
        await POST(URL_STOCK.STOCK_INPUT_TRANSACTION, values)
        Toast.success('สำเร็จ');
        formik.resetForm();
        setProductData(defaultData)
        fetchData();
      } catch(error) {
        setErrorMessages(error.errorMessages);
      } finally {
        setIsLoading(false);
      }
    }
  })

  const fetchProductData = async (barcode_no) => {
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_PRODUCT.PRODUCT_BY_BARCODE}${barcode_no}`)
      setProduct(response.data)
    } catch {
      clearFormValue();
    } finally {
      setIsLoading(false);
    }
  }

  const setProduct = async (data) => {
    await setFormValue(data)
    await setProductData({ 
      barcode_no: data.barcode_no, 
      name: data.name,
      current_amount: _.get(data, 'amount', 0) 
    })
    formik.validateField('product');
  }

  const clearFormValue = () => {
    formik.setFieldValue('product', null);
    formik.setFieldValue('cost_price', 0);
  }

  const setFormValue = (data) => {
    formik.setFieldValue('product', _.get(data, 'id', '') );
    formik.setFieldValue('cost_price', _.get(data, 'cost_price', 0));
  }

  return (
    <Accordion fluid styled style={{ marginBottom: '1rem' }}>
      <Accordion.Title active={active} onClick={() => setActive(prevState => !prevState)}>
        <Header>
          รับเข้าสินค้า
          <Icon name='dropdown' />
        </Header>
      </Accordion.Title>
      <Accordion.Content active={active}>
      { errorMessages && <Message error>{errorMessages}</Message> }
      <QRCodeReaderModal
        open={openQRCodeModal}
        onClose={() => setOpenQRCodeModal(false)}
        onQRCodeSuccess={(text) => {
          fetchProductData(text)
          setOpenQRCodeModal(false)
        }}/>
      <Form>
        <Form.Group widths='2'>
          <Form.Field>
            <label>Barcode</label>
            <CustomInputField
              fluid
              action={
                <Button
                  color='blue'
                  labelPosition='right'
                  icon='qrcode'
                  content='สแกน'
                  type='button'
                  onClick={() => setOpenQRCodeModal(true)}/>}
                  icon='barcode'
                  iconPosition='left'
                  placeholder='Barcode'
                  value={productData.barcode_no}
                  error={formik.errors.product}
                  onChange={(_, data) => {
                    if (formik.values.product) {
                      clearFormValue();
                    }
                    setProductData({ ...defaultData, barcode_no: data.value })
                  }}
                  onKeyPress={e => {
                    if (e.key === "Enter") {
                      let barcode = _.get(productData, 'barcode_no')
                      if (barcode)
                        fetchProductData(barcode);
                    }
                  }}/>
              { formik.errors.product && (
                <Label pointing='above' prompt>{formik.errors.product}</Label>
              ) }
          </Form.Field>
          <Form.Field>
            <label>ชื่อ {productData.current_amount ? `(ยอดเก่า: ${formatComma(productData.current_amount, true)})` : ''}</label>
            <Search
              name='product'
              placeholder='ค้นหาตามชื่อ...'
              minCharacters={3}
              fluid
              resultRenderer={CustomSearchResult}
              results={productList.response.results.map(productSearchRenderer)}
              showNoResults={true}
              onSearchChange={async (_, data) => {
                if (formik.values.product) {
                  clearFormValue();
                  productList.fetchData({ search: data.value })
                  setProductData({ ...defaultData, name: data.value })
                } else {
                  productList.fetchData({ search: data.value })
                  setProductData({ ...productData, name: data.value })
                }
              }}
              onResultSelect={(e, data) => {
                setProduct(data.result)
              }}
              loading={productList.isLoading}
              value={productData.name}
              error={formik.errors.product}/>
          </Form.Field>
        </Form.Group>
        <Form.Group widths='2'>
          <CustomInputField
            isForm
            name='amount'
            label='จำนวนรับเข้า'
            icon='boxes'
            iconPosition='left'
            placeholder='จำนวนรับเข้า'
            type='number'
            step='1'
            min='0'
            value={formik.values.amount}
            error={formik.errors.amount}
            onChange={formik.handleChange}
            onBlur={() => { 
              if(!formik.values.amount) {
                formik.setFieldValue('amount', 0)
              } 
            }}/>
          <Form.Field>
            <label>ราคาทุนต่อหน่วย</label>
            <CustomInputField
              name='cost_price'
              icon='money bill alternate outline'
              iconPosition='left'
              placeholder='ราคาทุนต่อหน่วย'
              label={{ tag: true, content: 'บาท' }}
              labelPosition='right'
              type='number'
              step='1'
              min='0'
              value={formik.values.cost_price}
              error={formik.errors.cost_price}
              onChange={formik.handleChange}/>
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <Button loading={isLoading} color='green' icon='add' type='button' content='รับเข้า' onClick={formik.handleSubmit}/>
        </Form.Field>
      </Form>
      </Accordion.Content>
    </Accordion>
  )
}