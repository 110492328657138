import React from "react";
import { Button, Dimmer, Form, Header, Loader, Message } from "semantic-ui-react";
import PaginationTable from "../components/common/PaginationTable";
import { URL_REPORT, URL_SALE } from "../constance/urls";
import useListItem from "../hooks/useItemList";
import { getColumnsList } from '../frameworks/Util';
import { Media } from "../AppMedia";
import MobileList from "../components/common/MobileList";
import CustomInputField from "../components/common/CustomInputField";
import TaxInvoiceModal from "../components/tax/TaxInvoiceModal";
import ShareReportModal from "../components/report/ShareReportModal";


export default function TaxInvoiceListView() {

  const [searchFilter, setSearchFilter] = React.useState(null);
  const [taxInvoiceTarget, setTaxInvoiceTarget] = React.useState(null);
  const [printTarget, setPrintTarget] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const tableRef = React.useRef();
  const mobileListRef = React.useRef();

  const { response, isLoading, errorMessages, fetchData } = useListItem({ 
    url: URL_SALE.TAX_INVOICE,
    params: { search: searchFilter, page: page },
    usePagination: true,
    manual: true,
  });

  const columns = React.useMemo(() => [
    ...getColumnsList([
			{ Header: 'เลขที่', accessor: 'no', width: 140 },
			{ Header: 'ใบกำกับภาษีอย่างย่อ', accessor: 'sale_transaction_no', width: 140 },
			{ Header: 'ชื่อลูกค้า', accessor: 'customer_name' },
			{ Header: 'ที่อยู่', accessor: 'customer_address' },
			{ 
        Header: '', 
        accessor: 'id', 
        width: 110,
        Cell: ({ original }) => {
          return (
            <div>
              <Button basic icon='edit' color='yellow' onClick={() => setTaxInvoiceTarget(original)}/>
              <Button basic icon='share' color='blue' onClick={() => setPrintTarget(original)}/>
            </div>
          )
        }
      },
    ])
  ]);

	const content = {
		header: {
			icon: 'file text',
			accessor: 'no',
		},
		descriptions: [
      {
				icon: 'time',
        header: 'ใบกำกับภาษีอย่างย่อ',
        accessor: 'sale_transaction_no',
			},
			{
				icon: 'user',
        header: 'ชื่อลูกค้า',
        accessor: 'customer_name',
			},
			{
				icon: 'point',
        header: 'ที่อยู่',
        accessor: 'customer_address',
			}
		],
		onClick: (item) => setTaxInvoiceTarget(item)
	}

  React.useEffect(() => {
    if (!taxInvoiceTarget) {
      fetchData();
    }
  }, [taxInvoiceTarget, page])

  return (
    <div style={{ padding: '2rem' }}>
      <Header dividing style={{textAlign: 'left'}}>ใบกำกับภาษี</Header>
      <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
      <TaxInvoiceModal 
        open={taxInvoiceTarget != null}
        onClose={(goToInitalPage=false) => {
          if(goToInitalPage) {
            if (tableRef.current) {
              tableRef.current.initialPage()
            } 
            if (mobileListRef.current) {
              mobileListRef.current.initialPage()
            }
          }
          setTaxInvoiceTarget(null);
        }}
        detail={taxInvoiceTarget}/>

      <ShareReportModal
        open={printTarget ? true : false} 
        onClose={() => setPrintTarget(null)} 
        baseURL={URL_REPORT.SHARE_TAX_INVOICE}
        isDateRequire={false}
        params={{tax_invoice: printTarget ? printTarget.id : ''}} />

      <Media at='sm'>
        <Form>
          <Button floated='right' color='green' icon='add' onClick={() => setTaxInvoiceTarget('add')}/>
          <Form.Group inline unstackable>
            <Form.Field width='16'>
              <label>ค้นหา</label>
              <CustomInputField
                width={{ width: '100%' }}
                placeholder='ค้นหาเลขที่ใบกำกับภาษี...'
                action={{
                  color: 'blue',
                  icon: 'search',
                  onClick: () => fetchData()
                }}/>
            </Form.Field>
          </Form.Group>
        </Form>
        { errorMessages && <Message error>{errorMessages}</Message> }
        <MobileList
          data={response}
          content={content}
          onPageChange={(page) => setPage(page)}/>
      </Media>
      <Media greaterThanOrEqual='md'>      
        <Form>
          <Button floated='right' color='green' icon='add' content='สร้าง' onClick={() => setTaxInvoiceTarget('add')}/>
          <Form.Group inline>
            <CustomInputField isForm width='8' label='ค้นหา' placeholder='ค้นหาเลขที่ใบกำกับภาษี...' onChange={(_, data) => setSearchFilter(data.value)}/>
            <Form.Button fluid color='blue' icon='search' content='ค้นหา' onClick={() => fetchData()}/>
          </Form.Group>
        </Form>
        { errorMessages && <Message error>{errorMessages}</Message> }
        <PaginationTable
          ref={tableRef}
          columns={columns}
          data={response}
          onPageChange={(page) => setPage(page)}/>
      </Media>
      </div>
  )
}