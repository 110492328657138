import React from 'react';
import { URL_COMMON } from '../constance/urls';
import useDropdownItem from './useDropdownItem';
import * as _ from 'lodash';


export default function useAddressForm({ formik }) {
  const provinceDropdown = useDropdownItem({ 
    url: URL_COMMON.PROVINCE, 
    manual: true,
    text_renderer: (item) => item.name_th
  });
  const districtDropdown = useDropdownItem({ 
    url: `${URL_COMMON.DISTRICT}${formik.values.province}/`,
    manual: true,
    text_renderer: (item) => item.name_th
  });
  const subDistrictDropdown = useDropdownItem({ 
    url: `${URL_COMMON.SUB_DISTRICT}${formik.values.district}/`,
    manual: true,
    text_renderer: (item) => item.name_th
  });

  const handleProvinceChange = () => {
    formik.setFieldValue('district', null)
    formik.setFieldValue('sub_district', null)
    formik.setFieldValue('zip_code', '')
  }

  const handleDistrictChange = () => {
    formik.setFieldValue('sub_district', null)
    formik.setFieldValue('zip_code', '')
  }

  const handleSubDistrictChange = (value) => {
    const subDistrict = subDistricts.find(
      subDistrict => subDistrict.value === value
    );
    formik.setFieldValue('zip_code', subDistrict ? subDistrict.zip_code : '' )
  }

  React.useEffect(() => {
    provinceDropdown.fetchData();
  }, []);

  React.useEffect(() => {
    if(formik.values.province) {
      districtDropdown.fetchData();
    }
  }, [formik.values.province])

  React.useEffect(() => {
    if(formik.values.district) {
      subDistrictDropdown.fetchData();
    }
  }, [formik.values.district])

  const provinces = provinceDropdown.response || [];
  const districts = districtDropdown.response || [];
  const subDistricts = subDistrictDropdown.response || [];

  return {
    provinces,
    districts,
    subDistricts,
    handleProvinceChange,
    handleDistrictChange,
    handleSubDistrictChange,
  };
}
