import { useFormik } from "formik";
import React from "react";
import { Button, Form, Icon, Message, Modal, Divider, Search } from "semantic-ui-react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Toast } from "../../frameworks/Toast";
import { GET, PATCH, POST } from "../../frameworks/HttpClient";
import { URL_CUSTOMER, URL_SALE } from "../../constance/urls";
import CustomInputField from "../common/CustomInputField";
import useListItem from "../../hooks/useItemList";
import { dateTimeToString } from "../../frameworks/Util";
import * as _ from 'lodash';


export default function TaxInvoiceModal(props) {
  const saleList = useListItem({ url: URL_SALE.SALE_TRANSACTION, params: { is_active: true, has_invoice: false } });
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [search, setSearch] = React.useState("")
  const [receiptSelected, setReceiptSelected] = React.useState(null)

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      sale_transaction: null,
      customer_name: "",
      customer_branch: "",
      customer_invoice_no: "",
      customer_address: "",
      customer_telephone: ""
    },
    validationSchema: Yup.object().shape({
      sale_transaction: Yup.string().required('กรุณากรอกข้อมูล').nullable(),
			customer_name: Yup.string().required('กรุณากรอกข้อมูล'),
      customer_branch: Yup.string().notRequired(),
			customer_invoice_no: Yup.string().required('กรุณากรอกข้อมูล'),
			customer_address: Yup.string().required('กรุณากรอกข้อมูล'),
      customer_telephone: Yup.string().notRequired(),
    }),
    onSubmit:  async (values) => {
      setErrorMessages(null);
      setIsLoading(true);
      try {
        if(props.detail === 'add') {
          await POST(URL_SALE.TAX_INVOICE, values);
        } else {
          delete values["sale_transaction"]
          await PATCH(`${URL_SALE.TAX_INVOICE}${props.detail.id}/`, values);
        }
        Toast.success('สำเร็จ');
        props.onClose();
      } catch(error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setIsLoading(false)
      }
    }
  })

  const fetchCustomerTaxInvoice = async (customerId) => {
    try {
      const response = await GET(`${URL_CUSTOMER.CUSTOMER_TAX_INVOICE}${customerId}/`);
      const data = response.data
      if (data) {
        formik.setFieldValue("customer_name", data.name);
        formik.setFieldValue("customer_branch", data.branch);
        formik.setFieldValue("customer_invoice_no", data.invoice_no);
        formik.setFieldValue("customer_address", data.address);
        formik.setFieldValue("customer_telephone", data.telephone);
      }
    } catch(error) {}
  }

  React.useEffect(() => {
    if (receiptSelected && receiptSelected.customer) {
      fetchCustomerTaxInvoice(receiptSelected.customer);
    }
  }, [receiptSelected])

  React.useEffect(() => {
    if (props.open) {
      formik.resetForm();
      if (props.detail != 'add') {
        formik.setValues(props.detail);
        setSearch(props.detail.sale_transaction_no);
      }
    } else {
      setReceiptSelected(null)
      setSearch("")
    }
  }, [props.open])

  const isCreate = props.detail == 'add';

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.onClose()}
        closeIcon
        closeOnDimmerClick={false}>
        <Modal.Header><Icon name='user'/>{ isCreate ? 'เพิ่ม' : 'ข้อมูล' }ใบกำกับภาษี</Modal.Header>
        <Modal.Content>
          { errorMessages && <Message error>{errorMessages}</Message> }
          <Form>
            <Form.Field
              label='เลขที่บิล'
              placeholder='เลขที่'
              minCharacters={6}
              control={Search}
              readOnly={!isCreate ? true : false}
              results={ (saleList.response && saleList.response.results)
                ? saleList.response.results.map(e => ({ ...e, key: e.id, value: e.id, title: e.receipt_no }) )
                : []
              }
              showNoResults={true}
              onSearchChange={(_, data) => {
                if (!isCreate) {
                  return;
                }
                if(receiptSelected) {
                  setReceiptSelected(null);
                  setSearch("")
                }
                if(data.value.length > 5) {
                  saleList.fetchData({ search: data.value })
                }
                setSearch(data.value)
              }}
              onResultSelect={(_, data) => {
                if (!isCreate) {
                  return;
                }
                setReceiptSelected(data.result);
                formik.setFieldValue("sale_transaction", data.result.id)
                setSearch(data.result.receipt_no);
              }}
              error={formik.errors.sale_transaction}
              value={search}
              loading={saleList.isLoading}
            />

            {receiptSelected && (
              <Form.Group widths='equal'>
                <Form.Input
                label='วันลงบิล'
                readOnly
                value={dateTimeToString(receiptSelected.paid_datetime)}/>
                <Form.Input
                  label='ราคารวม'
                  readOnly
                  value={receiptSelected.raw_price}/>
                <Form.Input
                  label='ส่วนลด'
                  readOnly
                  value={receiptSelected.total_discount}/>
                <Form.Input
                  label='ราคาสุทธิ'
                  readOnly
                  value={receiptSelected.net_price}/>
              </Form.Group>
            )}
							
						<Divider horizontal>Detail</Divider>
					
            
            <CustomInputField
              isForm
              name='customer_name'
              label='ชื่อ'
              icon='user'
              iconPosition='left'
              placeholder='ชื่อ'
              value={formik.values.customer_name}
              error={formik.errors.customer_name}
              onChange={formik.handleChange}/>
            <Form.Group widths='equal'>
              <CustomInputField
                isForm
                name='customer_branch'
                label='สาขา'
                icon='sitemap'
                iconPosition='left'
                placeholder=''
                value={formik.values.customer_branch}
                error={formik.errors.customer_branch}
                onChange={formik.handleChange}/>
              <CustomInputField
                isForm
                name='customer_invoice_no'
                label='เลขผู้เสียภาษี'
                icon='id card outline'
                iconPosition='left'
                placeholder='เลขผู้เสียภาษี'
                value={formik.values.customer_invoice_no}
                error={formik.errors.customer_invoice_no}
                onChange={formik.handleChange}/>
              <CustomInputField
                isForm
                name='customer_telephone'
                label='โทรศัพท์'
                icon='phone'
                iconPosition='left'
                placeholder='โทรศัพท์'
                value={formik.values.customer_telephone}
                error={formik.errors.customer_telephone}
                onChange={formik.handleChange}/>
            </Form.Group>
            <Form.TextArea
              name='customer_address'
              label='ที่อยู่'
              placeholder='ที่อยู่'
              value={formik.values.customer_address}
              error={formik.errors.customer_address}
              onChange={formik.handleChange}/>
          </Form>

        </Modal.Content>
        <Modal.Actions>
          <Button loading={isLoading} content='ยกเลิก' icon='cancel' onClick={props.onClose}/>
          <Button 
            loading={isLoading}
            content={ props.detail === 'add' ? 'เพิ่ม' : 'แก้ไข'}
            color={ props.detail === 'add' ? 'green' : 'blue'}
            icon={ props.detail === 'add' ? 'add' : 'edit'}
            onClick={formik.handleSubmit}/>
        </Modal.Actions>
      </Modal>
    </div>
  );
}


TaxInvoiceModal.defaultProps = {
  open: false,
  onClose: () => null,
  detail: null,
}

TaxInvoiceModal.propsTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  detail: PropTypes.any,
}