import React from 'react';
import { Dimmer, Form, Loader, Message, Modal, Popup, Tab } from 'semantic-ui-react';
import { Media } from '../../AppMedia';
import { URL_SALE, URL_STOCK } from '../../constance/urls';
import { dateTimeToString, ellipsisString, formatComma, getColumnsList } from '../../frameworks/Util';
import useListItem from '../../hooks/useItemList';
import MobileList from '../common/MobileList';
import PaginationTable from '../common/PaginationTable';
import { ProductModel } from '../../constance/models';
import PropTypes from "prop-types";


const ProductStockInputList = ({ target }) => {
  const [page, setPage] = React.useState(1);

  const { response, isLoading, errorMessages, fetchData } = useListItem({ 
    url: URL_STOCK.STOCK_INPUT_TRANSACTION,
    params: { product: target, page: page },
    usePagination: true,
    manual: true,
  });

  const columns = React.useMemo(() => [
    ...getColumnsList([
      { Header: 'วันที่', accessor: 'created', Cell: ({value}) => dateTimeToString(value) },
      { Header: 'ทุนต่อหน่วย (บาท)', accessor: 'cost_price', Cell: ({value}) => formatComma(value) },
			{ Header: 'จำนวนรับเข้า', accessor: 'amount', Cell: ({value}) => formatComma(value) },
			{ Header: 'จำนวนตัด', accessor: 'cut_amount', Cell: ({value}) => formatComma(value) },
			{ Header: 'จำนวนคงเหลือ', accessor: 'remain_amount', Cell: ({value}) => formatComma(value) },
      { Header: 'หมายเหตุ', accessor: 'note', Cell: ({value}) => (
        <Popup
          inverted
          trigger={<div>{ellipsisString(value, 10)}</div>}
          content={value}
        />
      )},
    ])
  ])

  const content = {
		header: {
			icon: 'calendar',
      detail: (item) => dateTimeToString(item.created)
		},
		descriptions: [
      { icon: 'money outline', header: 'ทุนต่อหน่วย (บาท)', accessor: 'cost_price', detail: (item) => formatComma(item.cost_price) },
			{ icon: 'truck', header: 'จำนวนรับเข้า', accessor: 'amount', detail: (item) => formatComma(item.amount) },
			{ icon: 'cancel', header: 'จำนวนตัด', accessor: 'cut_amount', detail: (item) => formatComma(item.cut_amount) },
			{ icon: 'boxes', header: 'จำนวนคงเหลือ', accessor: 'remain_amount', detail: (item) => formatComma(item.remain_amount) },
			{ icon: 'file alternate outline', header: 'หมายเหตุ', detail: (item) => item.note ? ellipsisString(item.note, 20) : '-' },
    ],
		onClick: () => null
	}

  React.useEffect(() => {
    fetchData()
  }, [page])

  return (
    <div>
      <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
      { errorMessages && <Message error>{errorMessages}</Message> }
      <Media at='sm'>
        <MobileList
          data={response}
          content={content}
          onPageChange={(page) => setPage(page)}/>
      </Media>
      <Media greaterThanOrEqual='md'>
        <PaginationTable
          columns={columns}
          data={response}
          onPageChange={(page) => setPage(page)}/>
      </Media>
    </div>
  )
}

const ProductStockOutputList = ({ target }) => {
  const [page, setPage] = React.useState(1);

  const { response, isLoading, errorMessages, fetchData } = useListItem({ 
    url: URL_STOCK.STOCK_OUTPUT_TRANSACTION,
    params: { product: target, page: page },
    usePagination: true,
    manual: true,
  });

  const columns = React.useMemo(() => [
    ...getColumnsList([
      { Header: 'วันที่', accessor: 'created', Cell: ({value}) => dateTimeToString(value), width: 200 },
			{ Header: 'จำนวนตัด', accessor: 'amount', Cell: ({value}) => formatComma(value), width: 100 },
			{ Header: 'หมายเหตุ', accessor: 'note' },
    ])
  ])

  const content = {
		header: {
			icon: 'calendar',
      detail: (item) => dateTimeToString(item.created)
		},
		descriptions: [
			{ icon: 'minus circle', header: 'จำนวนตัด', accessor: 'amount', detail: (item) => formatComma(item.amount) },
			{ icon: 'file outline alternate', header: 'หมายเหตุ', accessor: 'note' },
    ],
		onClick: () => null
	}

  React.useEffect(() => {
    fetchData()
  }, [page])

  return (
    <div>
      <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
      { errorMessages && <Message error>{errorMessages}</Message> }
      <Media at='sm'>
        <MobileList
          data={response}
          content={content}
          onPageChange={(page) => setPage(page)}/>
      </Media>
      <Media greaterThanOrEqual='md'>
        <PaginationTable
          columns={columns}
          data={response}
          onPageChange={(page) => setPage(page)}/>
      </Media>
    </div>
  )
}

const SaleHistory = ({ target }) => {
  const [page, setPage] = React.useState(1);

  const { response, isLoading, errorMessages, fetchData } = useListItem({ 
    url: URL_SALE.SALE_PRODUCT,
    params: { product: target, page: page },
    usePagination: true,
    manual: true,
  });

  const columns = React.useMemo(() => [
    ...getColumnsList([
      { Header: 'วันที่', accessor: 'created', Cell: ({value}) => dateTimeToString(value) },
			{ Header: 'ใบเสร็จ', accessor: 'receipt_no' },
			{ Header: 'จำนวนขาย', accessor: 'amount', Cell: ({value}) => formatComma(value) },
      { Header: 'ทุนต่อหน่วย (บาท)', accessor: 'cost_per_unit', Cell: ({value}) => formatComma(value) },
			{ Header: 'ราคาต่อหน่วย (บาท)', accessor: 'price_per_unit', Cell: ({value}) => formatComma(value) },
    ])
  ])

  const content = {
		header: {
			icon: 'calendar',
      detail: (item) => dateTimeToString(item.created)
		},
		descriptions: [
			{ header: 'ใบเสร็จ', accessor: 'receipt_no' },
			{ header: 'จำนวนขาย', accessor: 'amount', detail: (item) => formatComma(item.amount) },
      { header: 'ทุนต่อหน่วย (บาท)', accessor: 'cost_per_unit', detail: (item) => formatComma(item.cost_per_unit) },
			{ header: 'ราคาต่อหน่วย (บาท)', accessor: 'price_per_unit', detail: (item) => formatComma(item.price_per_unit) },
    ],
		onClick: () => null
	}

  React.useEffect(() => {
    fetchData()
  }, [page])

  return (
    <div>
      <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
      { errorMessages && <Message error>{errorMessages}</Message> }
      <Media at='sm'>
        <MobileList
          data={response}
          content={content}
          onPageChange={(page) => setPage(page)}/>
      </Media>
      <Media greaterThanOrEqual='md'>
        <PaginationTable
          columns={columns}
          data={response}
          onPageChange={(page) => setPage(page)}/>
      </Media>
    </div>
  )
}

// const ProductStockEditList = ({ target }) => {
//   const [page, setPage] = React.useState(1);

//   const { response, isLoading, errorMessages, fetchData } = useListItem({ 
//     url: URL_STOCK.STOCK_EDIT_TRANSACTION,
//     params: { product: target, page: page },
//     usePagination: true,
//     manual: true,
//   });

//   const columns = React.useMemo(() => [
//     ...getColumnsList([
//       { Header: 'วันที่', accessor: 'created', Cell: ({value}) => dateTimeToString(value) },
// 			{ Header: 'จำนวนก่อนแก้ไข', accessor: 'current_amount', Cell: ({value}) => formatComma(value) },
// 			{ Header: 'จำนวนที่แก้ไข', accessor: 'amount', Cell: ({value}) => formatComma(value) },
//     ])
//   ])

//   const content = {
// 		header: {
// 			icon: 'calendar',
//       detail: (item) => dateTimeToString(item.created)
// 		},
// 		descriptions: [
// 			{ header: 'จำนวนก่อนแก้ไข', detail: (item) => formatComma(item.current_amount) },
// 			{ header: 'จำนวนที่แก้ไข', detail: (item) => formatComma(item.amount) },
//     ],
// 		onClick: () => null
// 	}

//   React.useEffect(() => {
//     fetchData()
//   }, [page])

//   return (
//     <div>
//       <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
//       { errorMessages && <Message error>{errorMessages}</Message> }
//       <Media at='sm'>
//         <MobileList
//           data={response}
//           content={content}
//           onPageChange={(page) => setPage(page)}/>
//       </Media>
//       <Media greaterThanOrEqual='md'>
//         <PaginationTable
//           columns={columns}
//           data={response}
//           onPageChange={(page) => setPage(page)}/>
//       </Media>
//     </div>
//   )
// }

export default function ProductAmountLogModal(props) {

  const {
    open,
    onClose,
    target,
    detail,
  } = props;

  const menus = [
    { menuItem: 'สต๊อกสินค้า', render: () => <ProductStockInputList target={target}/> },
    { menuItem: 'ตัดยอดสินค้า', render: () => <ProductStockOutputList target={target}/> },
    { menuItem: 'ประวัติการขาย', render: () => <SaleHistory target={target}/> },
    // { menuItem: 'ตรวจนับสินค้า', render: () => <ProductStockEditList target={target}/> },
  ]

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        closeIcon
        centered={false}
      >
        <Modal.Header>รายละเอียดสต๊อกสินค้า</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Input label='สินค้า' value={`${ detail.barcode_no ? `[${detail.barcode_no}] - ` : ''}${detail.name}`}/>
          </Form>
          <Tab menu={{ secondary: true, pointing: true }} panes={menus} />
        </Modal.Content>
      </Modal>
    </div>
  )
}


ProductAmountLogModal.defaultProps = {
  open: false,
  onClose: () => null,
  target: '',
  detail: ProductModel,
}

ProductAmountLogModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  target: PropTypes.string,
  detail: PropTypes.object,
}