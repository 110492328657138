import React from 'react';
import { Button, Message, Modal, Dimmer, Loader, Confirm } from 'semantic-ui-react';
import { DELETE } from '../../frameworks/HttpClient';
import { Toast } from '../../frameworks/Toast';
import { URL_PRODUCT } from '../../constance/urls';
import AddProductCategoryModal from './AddProductCategoryModal';
import useListItem from '../../hooks/useItemList';
import CustomReactTable from '../common/CustomReactTable';
import { getColumnsList } from '../../frameworks/Util';


export default function ManageProductCategoryModal(props) {

  const {
    open,
    onClose
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [editTarget, setEditTarget] = React.useState(null);
  const [deleteTarget, setDeleteTarget] = React.useState(null);

  const columns = React.useMemo(() => [
    ...getColumnsList([
      { Header: 'ประเภท', accessor: 'name' },
      {
        Header: 'Action',
        accessor: 'id',
        width: 220,
        Cell: ({ original }) => (
          <div>
            <Button
              basic
              icon='edit'
              color='blue'
              content='แก้ไข'
              onClick={() => setEditTarget(original)} />
            <Button
              basic
              icon='trash alternate'
              color='red'
              content='ลบ'
              onClick={() => setDeleteTarget(original)} />
          </div>
        )
      }
    ])
  ])

  const { response, isFetchLoading, fetchErrorMessages, fetchData } = useListItem({
    url: URL_PRODUCT.PRODUCT_CATEGORY,
    usePagination: false,
    manual: true,
  });

  const handleDelete = async () => {
    setErrorMessages(null);
    setIsLoading(true);
    try {
      await DELETE(`${URL_PRODUCT.PRODUCT_CATEGORY}${deleteTarget.id}/`);
      Toast.success('สำเร็จ');
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
    finally {
      await fetchData()
      setIsLoading(false)
      setDeleteTarget(null)
    }
  }

  React.useEffect(() => {
    if(open) {
      fetchData()
    }
  }, [open])

  return (
    <div>
      <Dimmer active={isLoading || isFetchLoading} inverted><Loader inverted />
        <Modal
          open={open}
          onClose={onClose}
          closeIcon
          closeOnDimmerClick={false}>
          <Modal.Header>ประเภทสินค้า</Modal.Header>
          <Modal.Content>
            {errorMessages && <Message error>{errorMessages}</Message>}
            <CustomReactTable
              columns={columns}
              data={response}/>
          </Modal.Content>
          <Modal.Actions>
          <Button loading={isLoading} content='ปิด' icon='cancel' onClick={onClose}/>
          </Modal.Actions>

          <AddProductCategoryModal
            category={editTarget}
            open={editTarget != null}
            onClose={() => setEditTarget(null)}
            onSuccess={(value) => {
              fetchData()
              setEditTarget(null)
            }} />

          <Confirm
            open={deleteTarget != null}
            content={`ยืนยันลบประเภทสินค้า "${deleteTarget ? deleteTarget.name : ''}"`}
            onCancel={() => setDeleteTarget(null)}
            onConfirm={handleDelete}
          />
        </Modal>
      </Dimmer>
    </div>
  )
}