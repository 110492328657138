import React from 'react';
import { Header, Icon, Popup } from 'semantic-ui-react';
import StoreDetail from '../components/store/StoreDetail';


export default function StoreSettingView() {
  return (
    <div style={{ padding: '2rem' }}>
      <Header dividing textAlign='left'>
        ตั้งค่า
        { window.location.pathname == '/' && (
          <Popup
            trigger={<a onClick={() => {
              window.location.href = window.location.href
            }}><Icon name='refresh' basic color='blue'/></a>}
            content='รีเฟรชหน้าเพจ'
            inverted/>
        ) }
      </Header>
      <StoreDetail/>
    </div>
  )
}
