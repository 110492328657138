import { useFormik } from "formik";
import React from "react";
import { Button, Form, Icon, Message, Modal } from "semantic-ui-react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Toast } from "../../frameworks/Toast";
import { PATCH } from "../../frameworks/HttpClient";
import { URL_STAFF } from "../../constance/urls";
import CustomInputField from "../common/CustomInputField";

export default function ResetPINModal(props) {
	const [isLoading, setLoading] = React.useState(false)
	const [errorMessages, setErrorMessages] = React.useState(null)

	const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
			pin: "",
			confirm_pin: "",
		},
    validationSchema: Yup.object().shape({
			pin: Yup.string().required('กรุณากรอกข้อมูล').length(6, 'กรุณาใส่ PIN 6 หลัก'),
			confirm_pin: Yup.string().required('กรุณากรอกข้อมูล').length(6, 'กรุณาใส่ PIN 6 หลัก'),
    }),
    onSubmit:  async (values) => {
			if (values.pin != values.confirm_pin) {
				setErrorMessages("ข้อมูล PIN ไม่ตรงกัน!")
				return
			}

      if (!props.detail) {
        setErrorMessages("ไม่พบข้อมูลผู้ใช้งาน กรุณาปิดหน้าต่าง แล้วเปิดใหม่อีกครั้ง")
				return
      }
			
      setErrorMessages(null);
      setLoading(true);
      try {
        await PATCH(`${URL_STAFF.STAFF_UPDATE_PIN}${props.detail.id}/`, {pin: values['pin']});
        Toast.success('สำเร็จ');
        props.onClose();
      } catch(error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setLoading(false)
      }
    }
  })

	React.useEffect(() => {
		formik.resetForm()
	}, [props.open])

	return (
		<div>
			<Modal
        open={props.open}
        onClose={() => props.onClose()}
        closeIcon
				size={"tiny"}
        closeOnDimmerClick={false}>
        <Modal.Header><Icon name='lock'/>{`เปลี่ยน PIN [${props.detail ? props.detail.first_name : "Unknown"}]`}</Modal.Header>
        <Modal.Content>
					<Message warning>{"[ PIN ] ใส่ข้อมูลตัวเลข จำนวน 6 หลัก และ PIN ต้องไม่ซ้ำกับผู้ใช้งานรายอื่น"}</Message>
          { errorMessages && <Message error>{errorMessages}</Message> }
          <Form>
						<CustomInputField
							isForm
							name='pin'
							label='PIN'
							icon='th'
							iconPosition='left'
							placeholder='PIN'
							type={"number"}
							value={formik.values.pin}
							error={formik.errors.pin}
							onChange={formik.handleChange}/>
						<CustomInputField
							isForm
							name='confirm_pin'
							label='ยืนยัน PIN'
							icon='th'
							iconPosition='left'
							placeholder='ยืนยัน PIN'
							type={"number"}
							value={formik.values.confirm_pin}
							error={formik.errors.confirm_pin}
							onChange={formik.handleChange}/>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button loading={isLoading} content='ยกเลิก' icon='cancel' onClick={props.onClose}/>
          <Button 
            loading={isLoading}
            content={"ตกลง"}
            color={"blue"}
            icon={"check"}
            onClick={formik.handleSubmit}/>
        </Modal.Actions>
      </Modal>
		</div>
	)
}

ResetPINModal.defaultProps = {
  open: false,
  onClose: () => null,
  detail: null,
}

ResetPINModal.propsTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  detail: PropTypes.any,
}