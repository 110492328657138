import React from 'react';
import { Accordion, Button, Dimmer, Form, Header, Icon, Input, Loader, Message } from 'semantic-ui-react';
import { Media } from '../AppMedia';
import CustomInputField from '../components/common/CustomInputField';
import MobileList from '../components/common/MobileList';
import PaginationTable from '../components/common/PaginationTable';
import QRCodeReaderModal from '../components/common/QRCodeReaderModal';
import OfficerProductModal from '../components/product/OfficerProductModal';
import { URL_PRODUCT } from '../constance/urls';
import { formatComma, getColumnsList } from '../frameworks/Util';
import useDropdownItem from '../hooks/useDropdownItem';
import useListItem from '../hooks/useItemList';


export default function OfficerProductListView() {
  const [filterActive, setFilterActive] = React.useState(false);
  const [openQRCodeModal, setOpenQRCodeModal] = React.useState(false);
  const [productTarget, setProductTarget] = React.useState(null);
  const productCategory = useDropdownItem({url: URL_PRODUCT.OFFICER_PRODUCT_CATEGORY});
  const [categoryFilter, setCategoryFilter] = React.useState(null);
  const [searchFilter, setSearchFilter] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const tableRef = React.useRef();
  const mobileListRef = React.useRef();

  const { response, isLoading, errorMessages, fetchData } = useListItem({ 
    url: URL_PRODUCT.OFFICER_PRODUCT,
    params: { search: searchFilter, category: categoryFilter, page: page },
    usePagination: true,
    manual: true,
  });

  React.useEffect(() => {
    if (!productTarget) {
      fetchData();
    }
  }, [productTarget, page])

  const columns = React.useMemo(() => [
    ...getColumnsList([
			{ Header: 'Barcode', accessor: 'barcode_no' },
			{ Header: 'สินค้า', accessor: 'name' },
			{ Header: 'ราคาทุน (บาท)', accessor: 'cost_price', Cell: ({value}) => formatComma(value) },
			{ Header: 'ราคาขาย (บาท)', accessor: 'sell_price', Cell: ({value}) => formatComma(value) },
			{ Header: '', accessor: 'id', Cell: ({ original }) => 
        <Button basic icon='edit' color='blue' content='แก้ไข' onClick={() => setProductTarget(original)}/>
      },
    ])
  ])

  const content = {
    header: {
			icon: 'box',
			accessor: 'name',
		},
		descriptions: [
			{
				icon: 'barcode',
        header: 'Barcode',
        accessor: 'barcode_no',
			},
			{
				icon: 'money bill alternate',
        header: 'ราคาทุน',
        detail: (item) => `${formatComma(item.cost_price)} บาท`,
			},
			{
				icon: 'money bill alternate outline',
        header: 'ราคาขาย',
        detail: (item) => `${formatComma(item.sell_price)} บาท`,
			},
    ],
		onClick: (item) => setProductTarget(item)
  }

  return (
    <div style={{ padding: '2rem' }}>
      <QRCodeReaderModal 
        open={openQRCodeModal}
        onClose={() => setOpenQRCodeModal(false)}
        onQRCodeSuccess={(text) => {
          setSearchFilter(text);
          setOpenQRCodeModal(false)
        }}/>
      <Header dividing style={{textAlign: "left"}}>สินค้า</Header>
      <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
      <OfficerProductModal
        open={productTarget != null}
        onClose={(goToInitalPage=false) => {
          if(goToInitalPage) {
            if (tableRef.current) {
              tableRef.current.initialPage()
            } 
            if (mobileListRef.current) {
              mobileListRef.current.initialPage()
            }
          }
          setProductTarget(null);
        }}
        detail={productTarget}/>
      <Media lessThan='lg'>
        <Button
          size='mini'
          floated='right'
          color='green'
          icon='add'
          content='เพิ่ม'
          onClick={() => setProductTarget('add')}/>
        <Accordion fluid>
          <Accordion.Title 
            active={filterActive}
            onClick={() => setFilterActive(state => !state)}>
            <Icon name='dropdown' />
            ค้นหา
          </Accordion.Title>
          <Accordion.Content active={filterActive}>
            <Form>
              <Form.Group widths='2'>
                <Form.Select
                  placeholder='ประเภทสินค้า'
                  clearable
                  options={productCategory.response}
                  onChange={(_, data) => setCategoryFilter(data.value)}/>
                <Form.Field>
                  <CustomInputField
                    action={<Button color='blue' icon='qrcode' type='button' onClick={() => setOpenQRCodeModal(true)} />}
                    placeholder='ค้นหาตามชื่อ barcode...'
                    value={searchFilter}
                    onChange={(_, data) => setSearchFilter(data.value)}/>
                </Form.Field>
                <Form.Button color='blue' icon='search' content='ค้นหา' onClick={() => fetchData()}/>
              </Form.Group>
            </Form>
          </Accordion.Content>
        </Accordion>
      </Media>
      <Media greaterThan='md'>
        <Form>
          <Button floated='right' color='green' type='button' icon='add' content='เพิ่ม' onClick={() => setProductTarget('add')}/>
          <Form.Group inline unstackable>
            <Form.Select
              label='ค้นหา'
              placeholder='ประเภทสินค้า'
              clearable
              options={productCategory.response}
              onChange={(_, data) => setCategoryFilter(data.value)}/>
            <Form.Field>
              <CustomInputField
                action={<Button color='blue' icon='qrcode' type='button' onClick={() => setOpenQRCodeModal(true)} />}
                placeholder='ค้นหาตามชื่อ barcode...'
                value={searchFilter}
                onChange={(_, data) => setSearchFilter(data.value)}/>
            </Form.Field>
            <Form.Button color='blue' icon='search' content='ค้นหา' onClick={() => fetchData()}/>
          </Form.Group>
        </Form>
      </Media>
      { errorMessages && <Message error>{errorMessages}</Message> }
      <Media at='sm'>
        <MobileList
          data={response}
          content={content}
          onPageChange={(page) => setPage(page)}/>
      </Media>
      <Media greaterThanOrEqual='md'>
        <PaginationTable
          ref={tableRef}
          columns={columns}
          data={response}
          onPageChange={(page) => setPage(page)}/>
      </Media>
    </div>
  )
}