import React from 'react';
import ReactTable from 'react-table-v6';
import propTypes from 'prop-types';


const PaginationTable = React.forwardRef((props, ref) => {
  const {
    data,
    columns,
    onPageChange,
    style,
    getTrProps,
  } = props;

  const [page, setPage] = React.useState(0);

  React.useImperativeHandle(ref, () => ({
    initialPage: () => setPage(0),
  }))

  React.useEffect(() => {
    onPageChange(page+1);
  }, [page])

  return (
    <ReactTable
      manual
      noDataText='ไม่มีข้อมูล'
      data={data.results}
      columns={columns}
      showPagination={true}
      defaultPageSize={10}
      showPageSizeOptions={false}
      className='-striped -highlight'
      getTdProps={() => ({
        style: { whiteSpace: 'unset', textAlign: 'center' }
      })}
      page={page}
      pages={data.pages}
      style={{borderRadius: 5, ...style}}
      onPageChange={(pageIndex) => {
        setPage(pageIndex);
      }}
      getTrProps={getTrProps}
    />
  );
})

export default PaginationTable;

PaginationTable.defaultProps = {
  data: {
    links: {
      next: null,
      previous: null,
    },
    total: 0,
    pages: 0,
    results: [],
  },
  columns: [],
  onPageChange: () => {},
  style: {},
}

PaginationTable.propTypes = {
  data: propTypes.object,
  columns: propTypes.array,
  onPageChange: propTypes.func,
  style: propTypes.object,
}