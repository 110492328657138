import React from 'react';
import propTypes from 'prop-types';
import { Modal, Transition } from 'semantic-ui-react';
import QRCodeReader from './QRCodeReader';


const TRANSITION_DURATION = 500;

const QRCodeReaderModal = React.forwardRef((props, ref) => {
  const qrCodeReaderRef = React.useRef(null);
  const {
    open,
    onClose,
    onQRCodeSuccess,
    dimmer,
    style,
    className,
  } = props;

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      setIsModalOpen(open);
    } else {
      if(qrCodeReaderRef.current != null) {
        qrCodeReaderRef.current.cleanup();
      }
      setTimeout(() => setIsModalOpen(open), TRANSITION_DURATION);
    }

  }, [open]);

  React.useImperativeHandle(ref, () => ({
    pause: (isStopVideo) => qrCodeReaderRef.current != null ? qrCodeReaderRef.current.pause(isStopVideo) : null,
    resume: () => qrCodeReaderRef.current != null ? qrCodeReaderRef.current.resume() : null,
  }));

  return (
    <Transition visible={open} animation='scale' duration={TRANSITION_DURATION}>
      <Modal
        closeIcon
        className={className}
        centered={true}
        open={isModalOpen}
        onClose={onClose}
        size='tiny'
        style={style}
      >
        <Modal.Content>
          <QRCodeReader 
            ref={qrCodeReaderRef}
            qrCodeSuccessCallback={onQRCodeSuccess}
          />
        </Modal.Content>
      </Modal>
    </Transition>
  )
})

QRCodeReaderModal.defaultProps = {
  open: false,
  onClose: () => null,
  onQRCodeSuccess: () => null,
  style: {},
  className: '',
};

QRCodeReaderModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  onQRCodeSuccess: propTypes.func,
  style: propTypes.object,
  className: propTypes.string,
};

export default QRCodeReaderModal;
