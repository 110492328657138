import React from 'react';
import { Header, Menu } from 'semantic-ui-react';
import ProductList from '../components/product/ProductList';
import StockInputList from '../components/stock/StockInputList';
import StockOutputList from '../components/stock/StockOutputList';
import { useStoreContext } from '../hooks/StoreProvider';


export default function ProductView(props) {

  const { setting } = useStoreContext();
  const { staff } = setting;

  const menus = [
    { icon: 'box',
      text: 'สินค้าทั้งหมด',
      value: 'product',
      permission: true
    },
    { icon: 'warning',
      text: 'สินค้าใกล้หมด',
      value: 'product_min_stock',
      permission: true
    },
    { icon: 'plus square outline',
      text: 'รับเข้าสินค้า',
      value: 'input_stock',
      permission: setting.use_staff ? staff.input_stock : true,
    },
    { icon: 'minus square outline',
      text: 'ตัดยอดสินค้า',
      value: 'output_stock',
      permission: setting.use_staff ? staff.output_stock : true,
    },
  ]

  const [activeMenu, setActiveMenu] = React.useState('product');

  const renderContext = () => {
    switch (activeMenu) {
      case 'product': return <ProductList/>;
      case 'product_min_stock': return <ProductList minStock={true}/>;
      case 'input_stock': return <StockInputList/>;
      case 'output_stock': return <StockOutputList/>;
    }
  }

  const renderMenu = () => (
    <div className='menu-container'>
      <style>{`
        .menu-container {
          overflow: auto;
          -ms-overflow-style: none;  /* Internet Explorer 10+ */
          scrollbar-width: none;  /* Firefox */
        }
        .menu-container::-webkit-scrollbar {
          display: none;
        }
      `}</style>
      <Menu
        style={{ margin: '0 auto 1rem', paddingRight: '0%' }}
        className='dragable-menu'
        fluid
        secondary
        color='blue'
      >
        { menus.filter(menu => menu.permission != false).map(menu => (
          <Menu.Item
            name={menu.text}
            icon={menu.icon}
            active={activeMenu === menu.value}
            onClick={() => setActiveMenu(menu.value)}/>
        )) }
      </Menu>
    </div>
  )

  return (
    <div style={{ padding: '2rem' }}>
      <Header dividing style={{textAlign: "left"}}>สินค้า</Header>
      {renderMenu()}
      {renderContext()}
    </div>
  )
}