import React from 'react';
import { Form, Input } from 'semantic-ui-react';


export default function CustomInputField(props) {

  const { isForm, ...elementProps } = props;

  const blueOnEnter = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  }

  const handleKeyPress = (e) => {
    if (props.onKeyPress) {
      props.onKeyPress(e)
    } else {
      blueOnEnter(e)
    }
  }

  return isForm ? ( 
    <Form.Input {...elementProps} onKeyPress={handleKeyPress} onFocus={(event) => event.target.select()}/>
  ) : <Input {...elementProps} onKeyPress={handleKeyPress} onFocus={(event) => event.target.select()}/> ;
}