export const SaleTransactionModel = {
  customer: '',
  receipt_no: '',
  cash_paid_amount: 0,
  tranfer_paid_amount: 0,
  special_paid_amount: 0,
  special_payment_type: '',
  paid_datetime: '',
  sale_products: [],
  discount_promotions: [],
  customer_detail: {
    id: '',
    name: '',
    invoice: '',
    telephone: '',
    address: '',
  },
  total_paid: 0,
  raw_price: 0,
  total_discount: 0,
  net_price: 0,
  changes_amount: 0,
  is_active: true,
  total_no_vat: 0,
  total_include_vat: 0,
  total_before_vat: 0,
  total_vat: 0,
  has_vat: false,
}

export const SpecialPaymentType = {
  'gov': 'โครงการรัฐบาล',
  'credit': 'บัตรเครดิต',
  'welfare card': 'โครงการบัตรสวัสดิการรัฐ',
  'other': 'ไม่ระบุช่องทางการชำระเงิน',
}