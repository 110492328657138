import React from "react";
import { Accordion, Button, Confirm, Form, Header, Icon, Message, Popup, Search } from "semantic-ui-react";
import * as _ from "lodash";
import CustomInputField from "../common/CustomInputField";
import useListItem from "../../hooks/useItemList";
import { URL_PRODUCT } from "../../constance/urls";
import CustomSearchResult from "../common/CustomSearchResult";
import { productSearchRenderer } from "../../constance/models/product";
import ProductGroupModal from "./ProductGroupModal";
import CustomReactTable from "../common/CustomReactTable";
import { formatComma } from "../../frameworks/Util";
import { DELETE } from "../../frameworks/HttpClient";


export default function ProductGroupSegment(props) {
  const {
    product,
  } = props;

  const productLinkList = useListItem({
    url: URL_PRODUCT.PRODUCT_LINK,
    usePagination: true,
    manual: true,
    params: { page_size: 100, product: product }
  });
  const [active, setActive] = React.useState(false);
  const [productLinkTarget, setProductLinkTarget] = React.useState(null);
  const [deleteTarget, setDeleteTarget] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const columns = React.useMemo(() => [
    { Header: 'สินค้า', accessor: 'target_name' },
    { Header: 'จำนวน', accessor: 'amount', Cell: ({__, value}) => formatComma(value) },
    { Header: ' ', accessor: 'id', Cell: ({__, value}) => (
      <div>
        <Button basic icon='edit' color='blue' content='แก้ไข' size='mini' onClick={() => setProductLinkTarget(value)}/>
        <Button basic icon='trash alternate' color='red' content='นำออก' size='mini' onClick={() => setDeleteTarget(value)}/>
      </div>
    )},

  ])

  const handleDelete = async () => {
    setIsLoading(true);
    setErrorMessages(null);
    try {
      await DELETE(`${URL_PRODUCT.PRODUCT_LINK}${deleteTarget}/`);
      setDeleteTarget(null);
      productLinkList.fetchData();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    setActive(_.get(productLinkList, 'response.results', []).length > 0)
  }, [productLinkList.response])

  React.useEffect(() => {
    if (product) {
      productLinkList.fetchData();
    }
  }, [product])

  return product ? (
    <div>
      <Accordion fluid styled>
        <Accordion.Title active={active} onClick={() => setActive(prevState => !prevState)}>
          <Header as='h4' color='blue' style={{ marginTop: 0 }}>
            กลุ่มสินค้า
            <Icon name='dropdown'/>
          </Header>
        </Accordion.Title>
        <Accordion.Content active={active}>
          <Popup
            inverted
            flowing
            hoverable
            position='top right'
            content={<p>เมนูกลุ่มสินค้า จะสามารถผูกกับสินค้าอื่น เพื่อจัดการสต็อคสินค้ารวมได้</p>}
            trigger={<Button floated='right' icon='question' circular size='mini'/>}/>
          <Button basic type='button' color='green' icon='plus' content='เพิ่มสินค้าเข้ากลุ่ม' onClick={() => setProductLinkTarget('add')}/>
          <CustomReactTable
            noDataText='ไม่มีข้อมูล'
            loading={productLinkList.isLoading}
            columns={columns}
            data={productLinkList.response.results}
            showPagination={false}
            pageSize={productLinkList.response.total ? productLinkList.response.total : 3}/>
        </Accordion.Content>
      </Accordion>
      <ProductGroupModal
        product={product}
        target={productLinkTarget}
        open={productLinkTarget != null}
        onClose={() => {
          setProductLinkTarget(null)
          productLinkList.fetchData();
        }}/>
      <Confirm 
        open={deleteTarget}
        content={
          <div>
            { errorMessages && <Message error>{errorMessages}</Message> }
            ต้องการนำสินค้าดังกล่าวออกจากกลุ่มใช่หรือไม่?
          </div>
        }
        cancelButton='ยกเลิก'
        confirmButton='นำออก'
        loading={isLoading}
        onCancel={() => setDeleteTarget(null)}
        onConfirm={handleDelete}/>
    </div>
  ) : null
}