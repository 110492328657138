import React from "react";
import { Button, Dimmer, Form, Header, Loader, Message, Segment, Confirm, Label, Icon } from "semantic-ui-react";
import { SaleTransactionModel, SpecialPaymentType } from "../../constance/models";
import { dateTimeToString, getColumnsList } from "../../frameworks/Util";
import CustomReactTable from "../common/CustomReactTable";
import * as _ from 'lodash';
import { GET } from "../../frameworks/HttpClient";
import { URL_SALE } from "../../constance/urls";
import SelectCustomerModal from "./SelectCustomerModal";
import { Toast } from "../../frameworks/Toast";
import { Media } from "../../AppMedia";
import { useStoreContext } from "../../hooks/StoreProvider";
import moment from 'moment';


export default function SaleTransactionDetail(props) {
  const {
    target
  } = props;

  const { setting } = useStoreContext();

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [data, setData] = React.useState(SaleTransactionModel);
  const [selectCustomerVisible, setSelectCustomerVisible] = React.useState(false);
  const [showDisableConfirm, setShowDisableConfirm] = React.useState(false);
  const [enablePrint, setEnablePrint] = React.useState(false);
  const [printLoading, setPrintLoading] = React.useState(false);

  const fetchData = async () => {
    setIsLoading(true)
    setErrorMessages(null)
    try {
      const response = await GET(`${URL_SALE.SALE_TRANSACTION}${target}`)
      setData({...SaleTransactionModel, ...response.data})
    } catch (error) {
      setErrorMessages(error.errorMessages)  
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if(!selectCustomerVisible) {
      fetchData()
    }
  }, [target, selectCustomerVisible])

  const columns = React.useMemo(() => [
    ...getColumnsList([
			{ Header: 'สินค้า', accessor: 'product_name' },
			{ Header: 'จำนวน', accessor: 'amount', width: 160 },
			{ Header: 'ราคาต่อหน่วย', accessor: 'price_per_unit', width: 160 },
			{ Header: 'ราคารวม', accessor: 'total_price', width: 160 },
      {
        Header: "คำนวน VAT",
        accessor: "has_vat",
        width: 70,
        Cell: ({value}) => <Icon style={{margin: "auto"}} name={value ? "check circle" : "times circle"} color={value ? "green" : "red"}/>
      }
    ])
  ])

  const disableSaleTransaction = async () => {
    setIsLoading(true)
    setErrorMessages(null)
    try {
      await GET(`${URL_SALE.DISABLE_SALE_TRANSACTION}${data.id}/`)
      props.onBack()
      Toast.success('ยกเลิกรายการขายสำเร็จ')

      // call JSInterface for cancel transaction
      if(window.JSInterface) {
        const myJSON = JSON.stringify(data);
        window.JSInterface.cancelReceipt(myJSON)
      }
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false)
    }
  }

  const handlePrint = () => {
    setPrintLoading(true)
    setTimeout(() => { setPrintLoading(false) }, 3000);
    if (window.JSInterface) {
      const myJSON = JSON.stringify(data);
      window.JSInterface.printReceipt(myJSON)
    }
  }

  const enableDisable = () => {
    const created = moment(_.get(data, 'paid_datetime', '')).startOf('day')
    return (moment().diff(created, 'days') == 0) && data.is_active
  }

  React.useEffect(() => {
    // Handle print button
    setEnablePrint(window.JSInterface ? true : false)
  }, [])

  return (
    <div>
      <SelectCustomerModal
        open={selectCustomerVisible}
        onClose={() => setSelectCustomerVisible(false)}
        target={target}
        customer={data.customer_detail}/>
      <Segment>
        { errorMessages && <Message error>{errorMessages}</Message> }
        
        <Media greaterThanOrEqual='md'>
          <Header style={{marginBottom: 16}}>
            รายละเอียดบิล
            {data && !data.is_active && (
              <Label color={"red"}>{"บิลถูกยกเลิกแล้ว"}</Label>
            )}

            {data && data.is_active && enablePrint && (
              <Button 
                primary
                loading={printLoading}
                floated='right'
                content='พิมพ์ใบเสร็จ' 
                onClick={() => handlePrint()}/>
            )}
            
            {data && data.is_active && (
              <Button 
                basic
                floated='right'
                content='เลือกลูกค้า'
                color='blue'
                onClick={() => setSelectCustomerVisible(true)}/>
            )}
            {enableDisable() && 
              <Button
                basic
                floated='right'
                content='ยกเลิกบิล'
                color='red'
                onClick={() => setShowDisableConfirm(true)} />
            }
            {/* {!data.is_active &&
              <Button
                basic
                floated='right'
                content='กลับมาใช้บิล'
                color='green'
                onClick={enableSaleTransaction} />
            } */}
          </Header>
        </Media>
        <Media at='sm'>
          <div style={{marginBottom: 16}}>
            {data && !data.is_active && (
              <Label color={"red"}>{"บิลถูกยกเลิกแล้ว"}</Label>
            )}
            {data && data.is_active && (
              <Button 
                basic
                content='เลือกลูกค้า'
                color='blue'
                onClick={() => setSelectCustomerVisible(true)}/>
            )}
            {enableDisable() && 
              <Button
                basic
                content='ยกเลิกบิล'
                color='red'
                onClick={() => setShowDisableConfirm(true)} />
            }
          </div>
        </Media>
        
        <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
        <Form>
          <Form.Group widths='2'>
            <Form.Input
              label='เลขที่บิล'
              readOnly
              value={data.receipt_no}/>
            <Form.Input
              label='วันลงบิล'
              readOnly
              value={dateTimeToString(data.paid_datetime)}/>
          </Form.Group>

          {setting.use_staff && (
            <Form.Group widths='2'>
              <Form.Input
                label='ผู้ขาย'
                readOnly
                value={data.staff_name}/>
            </Form.Group>
          )}
          
          <Form.Group widths='2'>
            <Form.Input
              label='ชื่อลูกค้า'
              readOnly
              value={_.get(data, 'customer_detail.name', '')}/>
            <Form.Input
              label='เบอร์โทรลูกค้า'
              readOnly
              value={_.get(data, 'customer_detail.telephone', '')}/>
          </Form.Group>
          <Form.Group widths='2'>
            <Form.Input
              label='เลขผู้เสียภาษี'
              readOnly
              value={_.get(data, 'customer_detail.invoice_no', '')}/>
          </Form.Group>
          <Form.TextArea
            label='ที่อยู่'
            readOnly
            value={_.get(data, 'customer_detail.address', '')}/>
          <Form.Group widths='equal'>
            <Form.Input
              label='ราคารวม'
              readOnly
              value={data.raw_price}/>
            <Form.Input
              label='ส่วนลด'
              readOnly
              value={data.total_discount}/>
            <Form.Input
              label='ราคาสุทธิ'
              readOnly
              value={data.net_price}/>
          </Form.Group>
          <Form.Group widths={3}>
            <Form.Input
              label='ราคารวมสินค้าไม่มีภาษี'
              readOnly
              value={data.total_no_vat}/>
            {data && !data.has_vat && (
              <Form.Input
                label='ราคารวมสินค้ามีภาษี'
                readOnly
                value={data.total_include_vat}/>
            )}
          </Form.Group>
          {data && data.has_vat && (
            <Form.Group widths={3}>
              <Form.Input
                label='ราคารวมสินค้ามีภาษี'
                readOnly
                value={data.total_include_vat}/>
              <Form.Input
                label='ภาษีมูลค่าเพิ่ม'
                readOnly
                value={data.total_vat}/>
              <Form.Input
                label='ราคารวมหลังหักภาษีมูลค่าเพิ่ม'
                readOnly
                value={data.total_before_vat}/>
            </Form.Group>
          )}
          <Form.Group widths='equal'>
            <Form.Input
              label='ชำระเงินสด'
              readOnly
              value={data.cash_paid_amount}/>
            <Form.Input
              label='ชำระเงินโอน'
              readOnly
              value={data.tranfer_paid_amount}/>
            <Form.Input
              label='ชำระช่องทางพิเศษ'
              readOnly
              value={`${data.special_paid_amount} ${data.special_paid_amount > 0 ? `(${_.get(SpecialPaymentType, data.special_payment_type)})` : '' }`}/>
          </Form.Group>
        </Form>
        <CustomReactTable
          data={data.sale_products}
          columns={columns}/>
      </Segment>

      <Confirm
        open={showDisableConfirm}
        header='ยืนยันยกเลิกบิล'
        content={"คุณต้องการยกเลิกบิลนี้ใช่หรือไม่"}
        confirmButton={"ยืนยัน"}
        cancelButton={"ยกเลิก"}
        size={"tiny"}
        onCancel={() => setShowDisableConfirm(false)}
        onConfirm={() => {setShowDisableConfirm(false); disableSaleTransaction()}}
      />
    </div>
  )
}