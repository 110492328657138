export const StaffShiftModel = {
  start_time: null,
  end_time: null,
  staff: null,
  staff_name: '',
  summary: {
    total_gain: 0,
    total_discount: 0,
    total_profit: 0,
    total_transaction: 0,
    total_product_count: 0,
    total_no_vat: 0,
    total_include_vat: 0,
  }
}