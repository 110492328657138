import { useFormik } from 'formik';
import React from 'react';
import { Accordion, Button, Form, Header, Icon, Label, Message, Search } from 'semantic-ui-react';
import { URL_PRODUCT, URL_STOCK } from '../../constance/urls';
import useListItem from '../../hooks/useItemList';
import * as Yup from "yup";
import * as _ from 'lodash';
import { GET, POST } from '../../frameworks/HttpClient';
import { Toast } from '../../frameworks/Toast';
import QRCodeReaderModal from '../common/QRCodeReaderModal';
import CustomInputField from '../common/CustomInputField';
import CustomSearchResult from '../common/CustomSearchResult';
import { formatComma } from '../../frameworks/Util';


export default function StockOutputSegment(props) {
  const {
    fetchData
  } = props;

  const defaultData = { barcode_no: '', name: '', note: null }

  const [openQRCodeModal, setOpenQRCodeModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [productData, setProductData] = React.useState(defaultData)
  const productList = useListItem({ url: URL_PRODUCT.PRODUCT, usePagination: true, manual: true, params: { page_size: 5 } });

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      product: '',
      note: '',
      amount: 0,
    },
    validationSchema: Yup.object().shape({
      product: Yup.string().required('ไม่พบสินค้าในระบบ').nullable(),
      amount: Yup.number().moreThan(0, 'กรุณากรอกข้อมูล'),
      note: Yup.string().required('กรุณากรอกข้อมูล'),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      setErrorMessages(null);
      try {
        await POST(URL_STOCK.STOCK_OUTPUT_TRANSACTION, values)
        Toast.success('สำเร็จ');
        formik.resetForm();
        setProductData(defaultData)
        fetchData();
      } catch(error) {
        setErrorMessages(error.errorMessages);
      } finally {
        setIsLoading(false);
      }
    }
  })

  const fetchProductData = async (barcode_no) => {
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_PRODUCT.PRODUCT_BY_BARCODE}${barcode_no}`)
      setProduct(response.data)
    } catch {
      clearFormValue();
    } finally {
      setIsLoading(false);
    }
  }

  const setProduct = async (data) => {
    await setFormValue(data)
    await setProductData({ 
      barcode_no: data.barcode_no, 
      name: data.name,
      current_amount: _.get(data, 'amount', 0) 
    })
    formik.validateField('product');
  }

  const clearFormValue = () => {
    formik.setFieldValue('product', null);
  }

  const setFormValue = (data) => {
    formik.setFieldValue('product', _.get(data, 'id', '') );
  }

  return (
    <Accordion fluid styled style={{ marginBottom: '1rem' }}>
      <Accordion.Title active={active} onClick={() => setActive(prevState => !prevState)}>
        <Header>
          ตัดยอดสินค้า
          <Icon name='dropdown' />
        </Header>
      </Accordion.Title>
      <Accordion.Content active={active}>
      { errorMessages && <Message error>{errorMessages}</Message> }
      <QRCodeReaderModal
        open={openQRCodeModal}
        onClose={() => setOpenQRCodeModal(false)}
        onQRCodeSuccess={(text) => {
          fetchProductData(text)
          setOpenQRCodeModal(false)
        }}/>
      <Form>
        <Form.Group widths='2'>
          <Form.Field>
            <label>Barcode</label>
            <CustomInputField
              fluid
              action={
                <Button
                  color='blue'
                  labelPosition='right'
                  icon='qrcode'
                  content='สแกน'
                  type='button'
                  onClick={() => setOpenQRCodeModal(true)}/>}
                  icon='barcode'
                  iconPosition='left'
                  placeholder='Barcode'
                  value={productData.barcode_no}
                  error={formik.errors.product}
                  onChange={(_, data) => {
                    if (formik.values.product) {
                      clearFormValue();
                    }
                    setProductData({ ...defaultData, barcode_no: data.value })
                  }}
                  onKeyPress={e => {
                    if (e.key === "Enter") {
                      let barcode = _.get(productData, 'barcode_no')
                      if (barcode)
                        fetchProductData(barcode);
                    }
                  }}/>
              { formik.errors.product && (
                <Label pointing='above' prompt>{formik.errors.product}</Label>
              ) }
          </Form.Field>
          <Form.Field>
            <label>ชื่อ {productData.current_amount ? `(ยอดเก่า: ${formatComma(productData.current_amount)})` : ''}</label>
            <Search
              name='product'
              placeholder='ค้นหาตามชื่อ...'
              minCharacters={3}
              fluid
              resultRenderer={CustomSearchResult}
              results={productList.response.results.map(e => ({ 
                  ...e, 
                  key: e.id,
                  value: e.id,
                  title: e.name,
                  description: `${_.get(e, 'barcode_no', false) ? `รหัส: ${e.barcode_no}` : ''}
                    ${_.get(e, 'barcode_no', false) && _.get(e, 'category_detail.name', false) ? ', ': ''}
                    ${_.get(e, 'category_detail.name', false) ? `ประเภทสินค้า: ${e.category_detail.name}` : ''}`,
                  price: `${e.cost_price} ฿`,
                })
              )}
              showNoResults={true}
              onSearchChange={async (_, data) => {
                if (formik.values.product) {
                  clearFormValue();
                  productList.fetchData({ search: data.value })
                  setProductData({ ...defaultData, name: data.value })
                } else {
                  productList.fetchData({ search: data.value })
                  setProductData({ ...productData, name: data.value })
                }
              }}
              onResultSelect={(e, data) => {
                setProduct(data.result)
              }}
              loading={productList.isLoading}
              value={productData.name}
              error={formik.errors.product}/>
          </Form.Field>
        </Form.Group>
        <Form.Group widths='2'>
          <CustomInputField
            isForm
            name='amount'
            label='จำนวนตัดยอด'
            icon='boxes'
            iconPosition='left'
            placeholder='จำนวนตัดยอด'
            type='number'
            step='1'
            min='0'
            value={formik.values.amount}
            error={formik.errors.amount}
            onChange={formik.handleChange}
            onBlur={() => { 
              if(!formik.values.amount) {
                formik.setFieldValue('amount', 0)
              } 
            }}/>
          <CustomInputField
            isForm
            name='note'
            label='หมายเหตุ'
            icon='file alternate outline'
            iconPosition='left'
            placeholder='หมายเหตุ'
            value={formik.values.note}
            error={formik.errors.note}
            onChange={formik.handleChange}/>
        </Form.Group>
        <Form.Field>
          <Button loading={isLoading} color='green' icon='minus' type='button' content='ตัดยอด' onClick={formik.handleSubmit}/>
        </Form.Field>
      </Form>
      </Accordion.Content>
    </Accordion>
  )
}