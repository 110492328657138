import { useFormik } from "formik";
import React from "react";
import { Button, Confirm, Form, Icon, Message, Modal } from "semantic-ui-react";
import * as Yup from "yup";
import { CustomerModel } from "../../constance/models";
import PropTypes from "prop-types";
import { Toast } from "../../frameworks/Toast";
import { DELETE, POST, PUT } from "../../frameworks/HttpClient";
import { URL_CUSTOMER } from "../../constance/urls";
import CustomInputField from "../common/CustomInputField";


export default function CustomerModal(props) {
  const {
    open,
    onClose,
    detail,
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [deleteConfirmVisible, setDeleteConfirmVisible] = React.useState(false);

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: CustomerModel,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('กรุณากรอกข้อมูล'),
      telephone: Yup.string().required('กรุณากรอกข้อมูล'),
    }),
    onSubmit:  async (values) => {
      setErrorMessages(null);
      setIsLoading(true);
      try {
        if(detail === 'add') {
          await POST(URL_CUSTOMER.CUSTOMER, values);
        } else {
          await PUT(`${URL_CUSTOMER.CUSTOMER}${detail.id}/`, values);
        }
        Toast.success('สำเร็จ');
        onClose();
      } catch(error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setIsLoading(false)
      }
    }
  })

  const handleDelete = async () => {
    setDeleteConfirmVisible(false);
    setErrorMessages(null);
    setIsLoading(true);
    try {
      await DELETE(`${URL_CUSTOMER.CUSTOMER}${detail.id}/`);
      Toast.success('สำเร็จ');
      onClose(true);
    } catch(error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (open) {
      formik.resetForm();
      if (detail != 'add') {
        formik.setValues(detail);
      }
    }
  }, [open])

  return (
    <div>
      <Confirm 
        open={deleteConfirmVisible}
        content='ต้องการลบลูกค้าดังกล่าวใช่หรือไม่?'
        cancelButton='ยกเลิก'
        confirmButton='ลบ'
        onCancel={() => setDeleteConfirmVisible(false)}
        onConfirm={handleDelete}/>
      <Modal
        open={open}
        onClose={() => onClose()}
        closeIcon
        closeOnDimmerClick={false}>
        <Modal.Header><Icon name='user'/>{ detail === 'add' ? 'เพิ่ม' : 'ข้อมูล' }ลูกค้า</Modal.Header>
        <Modal.Content>
          { errorMessages && <Message error>{errorMessages}</Message> }
          <Form>
            <Form.Group widths='equal'>
              <CustomInputField
                isForm
                name='name'
                label='ชื่อ'
                icon='user'
                iconPosition='left'
                placeholder='ชื่อ'
                value={formik.values.name}
                error={formik.errors.name}
                onChange={formik.handleChange}/>
              <CustomInputField
                isForm
                name='invoice_no'
                label='เลขผู้เสียภาษี'
                icon='id card outline'
                iconPosition='left'
                placeholder='เลขผู้เสียภาษี'
                value={formik.values.invoice_no}
                error={formik.errors.invoice_no}
                onChange={formik.handleChange}/>
              <CustomInputField
                isForm
                name='telephone'
                label='โทรศัพท์'
                icon='phone'
                iconPosition='left'
                placeholder='โทรศัพท์'
                value={formik.values.telephone}
                error={formik.errors.telephone}
                onChange={formik.handleChange}/>
            </Form.Group>
            <Form.TextArea
              name='address'
              label='ที่อยู่'
              placeholder='ที่อยู่'
              value={formik.values.address}
              error={formik.errors.address}
              onChange={formik.handleChange}/>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          { detail != 'add' && 
            <Button
              basic
              color='red'
              content='ลบ'
              icon='trash'
              floated='left'
              onClick={() => setDeleteConfirmVisible(true)}/>
          }
          <Button loading={isLoading} content='ยกเลิก' icon='cancel' onClick={onClose}/>
          <Button 
            loading={isLoading}
            content={ detail === 'add' ? 'เพิ่ม' : 'แก้ไข'}
            color={ detail === 'add' ? 'green' : 'blue'}
            icon={ detail === 'add' ? 'add' : 'edit'}
            onClick={formik.handleSubmit}/>
        </Modal.Actions>
      </Modal>
    </div>
  );
}


CustomerModal.defaultProps = {
  open: false,
  onClose: () => null,
  detail: null,
}

CustomerModal.propsTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  detail: PropTypes.any,
}