export function setupServiceWorkerStatusTransfer () {
  let callBack;
  let status;

  const send = (s) => {
    status = s;
    if (callBack && status) {
      callBack(status);
    }
  }

  const listen = (cb) => {
    callBack = cb;
    send(status);
  }

  return {
    listen,
    onUpdate: () => null
    // onUpdate: sendStatus(send, 'มีเว็บเวอร์ชันใหม่ ปิดและเปิดใหม่ หรือโหลดเว็บใหม่เพื่อรับเวอร์ชันใหม่ได้'),
  }
}

function sendStatus (send, status) {
  return () => send(status);
}
