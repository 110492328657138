import React from "react";
import { useFormik } from "formik";
import { Button, Confirm, Form, Icon, Message, Modal, Checkbox } from "semantic-ui-react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Toast } from "../../frameworks/Toast";
import { DELETE, PATCH, POST } from "../../frameworks/HttpClient";
import { URL_STAFF } from "../../constance/urls";
import CustomInputField from "../common/CustomInputField";

export default function UserModal(props) {
	const [isLoading, setLoading] = React.useState(false)
	const [errorMessages, setErrorMessages] = React.useState(null)
	const [deleteConfirmVisible, setDeleteConfirmVisible] = React.useState(false)

	const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
			first_name: "",
			last_name: "",
			role: props.isManager ? "mgr" : "emp",
			pin: "",
			confirm_pin: "",
			edit_store: true,
			edit_product: true,
			edit_customer: true,
			input_stock: true,
			output_stock: true,
			show_sale_transaction: true,
			show_dashboard: true,
			export_report: true
		},
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required('กรุณากรอกข้อมูล'),
      last_name: Yup.string().required('กรุณากรอกข้อมูล'),
			role: Yup.string().required('กรุณากรอกข้อมูล'),
			pin: props.detail 
				? Yup.string().notRequired() 
				: Yup.string().required('กรุณากรอกเฉพาะตัวเลข 6 หลัก')
					.matches(/^[0-9]+$/, "กรุณากรอกเฉพาะตัวเลข")
					.min(6, "จำเป็นต้องกรอกตัวเลข 6 หลัก")
					.max(6, "จำเป็นต้องกรอกตัวเลข 6 หลัก"),
			confirm_pin: props.detail ? Yup.string().notRequired() : Yup.string().required('กรุณากรอกข้อมูล'),
			edit_store: Yup.bool().required('กรุณากรอกข้อมูล'),
			edit_product: Yup.bool().required('กรุณากรอกข้อมูล'),
			edit_customer: Yup.bool().required('กรุณากรอกข้อมูล'),
			input_stock: Yup.bool().required('กรุณากรอกข้อมูล'),
			output_stock: Yup.bool().required('กรุณากรอกข้อมูล'),
			show_sale_transaction: Yup.bool().required('กรุณากรอกข้อมูล'),
			show_dashboard: Yup.bool().required('กรุณากรอกข้อมูล'),
			export_report: Yup.bool().required('กรุณากรอกข้อมูล')
    }),
    onSubmit:  async (values) => {
			if (props.detail) {
				delete values['pin']
			} else {
				if (values.pin != values.confirm_pin) {
					setErrorMessages("รหัส PIN ไม่ตรงกัน!")
					return
				}
			}
			delete values['confirm_pin']
      setErrorMessages(null);
      setLoading(true);
      try {
        if(props.detail) {
					await PATCH(`${URL_STAFF.STAFF}${props.detail.id}/`, values);
        } else {
          await POST(URL_STAFF.STAFF, values);
        }
        Toast.success('สำเร็จ');
        props.onClose();
      } catch(error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setLoading(false)
      }
    }
  })

	const handleDelete = async () => {
		if (!props.detail) {
			return;
		}
		setDeleteConfirmVisible(false)
		setErrorMessages(null);
		setLoading(true);
		try {
			await DELETE(`${URL_STAFF.STAFF}${props.detail.id}/`);
			Toast.success('สำเร็จ');
			props.onClose();
		} catch(error) {
			setErrorMessages(error.errorMessages)
		} finally {
			setLoading(false)
		}
	}

	const handleCheckbox = (_, data) => {
		formik.setFieldValue(data.name, data.checked)
	}

	React.useEffect(() => {
		formik.resetForm()
		if (props.detail) {
			formik.setValues(props.detail)
		}

		setErrorMessages(null);
	}, [props.open])

	return (
		<div>
			<Confirm 
        open={deleteConfirmVisible}
				header={"ลบผู้ใช้งาน"}
        content={`คุณต้องการลบผู้ใช้งาน [${props.detail ? props.detail.first_name : "Unknown"}] ใช่หรือไม่?`}
        cancelButton='ยกเลิก'
        confirmButton='ลบ'
        onCancel={() => setDeleteConfirmVisible(false)}
        onConfirm={handleDelete}/>
			<Modal
        open={props.open}
        onClose={() => props.onClose()}
        closeIcon={props.isManager ? false : true}
        closeOnDimmerClick={false}>
        <Modal.Header>
					<Icon name='user'/> {`${props.detail ? 'ข้อมูล' : 'เพิ่ม'}ผู้ใช้งาน (${(formik.values.role === 'mgr') ? "ผู้จัดการ" : "พนักงาน"})`}
					{ props.detail && (props.detail.role !== 'mgr') &&
            <Button
              basic
              color='red'
              content='ลบ'
              icon='trash'
              floated='right'
              onClick={() => setDeleteConfirmVisible(true)}
							/>
          }
				</Modal.Header>
        <Modal.Content>
          { errorMessages && <Message error>{errorMessages}</Message> }
          <Form>
            <Form.Group widths='equal'>
              <CustomInputField
                isForm
                name='first_name'
                label='ชื่อ'
                icon='user outline'
                iconPosition='left'
                placeholder='ชื่อ'
                value={formik.values.first_name}
                error={formik.errors.first_name}
                onChange={formik.handleChange}/>
              <CustomInputField
                isForm
                name='last_name'
                label='นามสกุล'
                icon='user outline'
                iconPosition='left'
                placeholder='นามสกุล'
                value={formik.values.last_name}
                error={formik.errors.last_name}
                onChange={formik.handleChange}/>
            </Form.Group>
						{!props.detail && (
							<Form.Group widths='equal'>
								<CustomInputField
									isForm
									name='pin'
									label='PIN'
									icon='th'
									iconPosition='left'
									placeholder='PIN'
									type={"number"}
									value={formik.values.pin}
									error={formik.errors.pin}
									onChange={formik.handleChange}/>
								<CustomInputField
									isForm
									name='confirm_pin'
									label='ยืนยัน PIN'
									icon='th'
									iconPosition='left'
									placeholder='ยืนยัน PIN'
									type={"number"}
									value={formik.values.confirm_pin}
									error={formik.errors.confirm_pin}
									onChange={formik.handleChange}/>
							</Form.Group>
						)}

						<Form.Field label={"สิทธิ์ในการเข้าถึงข้อมูล"} />
						<Form.Group widths={4}>
							<Form.Field 
								label={"ตั้งค่าร้านค้า"}
								control={Checkbox}
								name={"edit_store"}
								checked={formik.values.edit_store}
								error={formik.errors.edit_store}
								disabled={props.isManager || (formik.values.role === 'mgr')}
								onChange={handleCheckbox}/>
							<Form.Field 
								label={"สินค้า"}
								control={Checkbox}
								name={"edit_product"}
								checked={formik.values.edit_product}
								error={formik.errors.edit_product}
								disabled={props.isManager}
								onChange={handleCheckbox}/>
							<Form.Field 
								label={"รับเข้าสินค้า"}
								control={Checkbox}
								name={"input_stock"}
								checked={formik.values.input_stock}
								error={formik.errors.input_stock}
								disabled={props.isManager}
								onChange={handleCheckbox}/>
							<Form.Field 
								label={"ตัดยอดสินค้า"}
								control={Checkbox}
								name={"output_stock"}
								checked={formik.values.output_stock}
								error={formik.errors.output_stock}
								disabled={props.isManager}
								onChange={handleCheckbox}/>
						</Form.Group>
						<Form.Group widths={4}>
							<Form.Field 
								label={"ลูกค้า"}
								control={Checkbox}
								name={"edit_customer"}
								checked={formik.values.edit_customer}
								error={formik.errors.edit_customer}
								disabled={props.isManager}
								onChange={handleCheckbox}/>
							<Form.Field 
								label={"ประวัติการซื้อขาย"}
								control={Checkbox}
								name={"show_sale_transaction"}
								checked={formik.values.show_sale_transaction}
								error={formik.errors.show_sale_transaction}
								disabled={props.isManager}
								onChange={handleCheckbox}/>
							<Form.Field 
								label={"สรุปยอดขาย"}
								control={Checkbox}
								name={"show_dashboard"}
								checked={formik.values.show_dashboard}
								error={formik.errors.show_dashboard}
								disabled={props.isManager}
								onChange={handleCheckbox}/>
							<Form.Field 
								label={"ส่งออกรายงาน"}
								control={Checkbox}
								name={"export_report"}
								checked={formik.values.export_report}
								error={formik.errors.export_report}
								disabled={props.isManager}
								onChange={handleCheckbox}/>
						</Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
					<Button 
						loading={isLoading} 
						content='ยกเลิก' 
						icon='cancel' 
						onClick={props.isManager ? props.onCancel : props.onClose}/>
          
          <Button 
            loading={isLoading}
            content={ props.detail ? 'แก้ไข' : 'เพิ่ม'}
            color={ props.detail ? 'yellow' : 'green'}
            icon={ props.detail ? 'edit' : 'add'}
            onClick={formik.handleSubmit}/>
        </Modal.Actions>
      </Modal>
		</div>
	)
}

UserModal.defaultProps = {
  open: false,
  onClose: () => null,
	onCancel: () => null,
  detail: null,
	isManager: false,
}

UserModal.propsTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
	onCancel: PropTypes.func,
  detail: PropTypes.any,
	isManager: PropTypes.bool, // Handle first staff
}