import React from "react";
import { Link } from 'react-router-dom';
import { Button, Dimmer, Header, Loader, Message } from "semantic-ui-react";
import { URL_AOT_RR } from "../constance/urls";
import { GET } from "../frameworks/HttpClient";
import { getColumnsList, dateTimeToString } from '../frameworks/Util';
import useListItem from "../hooks/useItemList";
import PaginationTable from "../components/common/PaginationTable";


export default function AOTRRView() {
  const [aot, setAOT] = React.useState(null)
  const tableRef = React.useRef();
  const [page, setPage] = React.useState(1);

  const sufixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const getBytes = (bytes) => {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return !bytes && '0 Bytes' || (bytes / Math.pow(1024, i)).toFixed(2) + " " + sufixes[i];
  };

  const { response, isLoading, errorMessages, fetchData } = useListItem({
    url: URL_AOT_RR.AOT_RR_TRANSCATION,
    params: { page: page },
    usePagination: true,
    manual: true,
  });

  const downloadFile = (cell) => {
    if (window.JSInterface) {
      window.JSInterface.downloadAOTRR(cell.file_url, cell.file_name)
    }
  }

  const columns = React.useMemo(() => [
    ...getColumnsList([
      { Header: 'วัน-เวลาสร้าง', accessor: 'created', Cell: ({ value }) => dateTimeToString(value) },
      { Header: 'วัน-เวลาเริ่ม', accessor: 'start_datetime', Cell: ({ value }) => dateTimeToString(value) },
      { Header: 'วัน-เวลาสิ้นสุด', accessor: 'end_datetime', Cell: ({ value }) => dateTimeToString(value) },
      { Header: 'ชื่อไฟล์', accessor: 'file_name' },
      { Header: 'ขนาดไฟล์', accessor: 'file_size', Cell: ({ value }) => getBytes(value) },
      {
        Header: '',
        accessor: 'file_url',
        width: 50,
        Cell: ({ original }) => {
          return (
            <div>
              {window.JSInterface && 
                <Button basic icon='download' color='blue' onClick={() => downloadFile(original)} />
              }
            </div>
          )
        }
      },
    ])
  ]);

  const fetchAOTRR = async () => {
    console.log("Fetching AOT RR")
    const resp = await GET(URL_AOT_RR.AOT_RR);
    if (resp.data.results.length > 0) {
      setAOT(resp.data.results[0])
      fetchData();
    }
  }

  React.useEffect(() => {
    fetchAOTRR()
  }, [])


  return (
    <div style={{ padding: '2rem' }}>
      <Header dividing style={{ textAlign: 'left' }}>AOT-RR Text Files</Header>
      <Dimmer active={isLoading} inverted><Loader inverted /></Dimmer>
      {aot == null && 
        <Header as='h4' style={{ textAlign: 'center' }}>ยังไม่ได้ลงทะเบียน AOT-RR Text File กรุณาติดต่อเจ้าหน้าที่ Line @nubman</Header>
      }
      {aot != null && 
        <Header style={{ textAlign: 'left' }}>สิ้นสุดวัน {aot.end_of_day}</Header>
      }
      {errorMessages && <Message error>{errorMessages}</Message>}
      {aot != null && 
        <PaginationTable
          ref={tableRef}
          columns={columns}
          data={response}
          onPageChange={(page) => setPage(page)} />
      }
    </div>
  )

}

