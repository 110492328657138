import React from "react";
import PropTypes from "prop-types";
import { Button, Header } from "semantic-ui-react";
import SaleTransactionDetail from "../components/sale/SaleTransactionDetail";
import SaleTransactionList from "../components/sale/SaleTransactionList";
import { URL_STORE } from "../constance/urls";
import { GET } from "../frameworks/HttpClient";
import * as _ from 'lodash';

export default function SaleTransactionListView(props) {
  const [saleTransactionTarget, setSaleTransactionTarget] = React.useState(null)
  const [store, setStore] = React.useState(null)

  const fetchStore = async () => {
    try {
      const response = await GET(URL_STORE.STORE);
      setStore(response.data)
    } catch { }
  }

  React.useEffect(() => {
    fetchStore()
  }, [])

  return (
    <div style={{ padding: '2rem' }}>
      <Header dividing style={{textAlign: "left"}}>
        { saleTransactionTarget &&
          <Button
            style={{ boxShadow: '0 0 0 0' }}
            basic
            onClick={() => setSaleTransactionTarget(null)}
            icon='left arrow'/>
        }
        { saleTransactionTarget ? `รายละเอียดบิล` : 'ประวัติการซื้อขาย' }
      </Header>
      { saleTransactionTarget ? 
        <SaleTransactionDetail 
          target={saleTransactionTarget} 
          onBack={() => setSaleTransactionTarget(null)}/> : 
        <SaleTransactionList 
          setSaleTransactionTarget={setSaleTransactionTarget}/> 
      }
    </div>
  )
}
