import React from 'react';
import useListItem from '../../hooks/useItemList';
import { URL_STOCK } from '../../constance/urls';
import MobileList from '../common/MobileList';
import PaginationTable from '../common/PaginationTable';
import { Media } from '../../AppMedia';
import { dateTimeToString, formatComma, getColumnsList } from '../../frameworks/Util';
import { Dimmer, Icon, Loader, Message } from 'semantic-ui-react';
import StockFilter from './StockFilter';
import StockOutputSegment from './StockOutputSegment';


export default function StockOutputList(props) {
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState({});
  const tableRef = React.useRef();
  const mobileListRef = React.useRef();
  const { response, isLoading, errorMessages, fetchData } = useListItem({ 
    url: URL_STOCK.STOCK_OUTPUT_TRANSACTION,
    usePagination: true,
    params: { ...filter, page: page },
    manual: true,
  });

  const columns = React.useMemo(() => [
    ...getColumnsList([
      { Header: 'วันที่', accessor: 'created', Cell: ({value}) => dateTimeToString(value), width: 200 },
			{ Header: 'สินค้า', accessor: 'product_name' },
			{ Header: 'ยอดเก่า', accessor: 'current_amount', Cell: ({value}) => formatComma(value, true), width: 100 },
			{ Header: 'จำนวนตัด', accessor: 'amount', Cell: ({value}) => formatComma(value), width: 100 },
			{ Header: 'ยอดสุทธิ', accessor: 'net_amount', Cell: ({value}) => formatComma(value, true) },
			{ Header: 'หมายเหตุ', accessor: 'note' },
    ])
  ])

  const content = {
		header: {
			icon: 'box',
      accessor: 'product_name',
		},
		descriptions: [
			{
				icon: 'clock outline',
        header: 'วันที่',
        detail: (item) => dateTimeToString(item.created),
			},
			{
				icon: 'minus circle',
        header: 'จำนวนตัด',
        detail: (item) => formatComma(item.amount),
			},
      {
				icon: 'boxes',
        header: 'ยอดสินค้า',
        detail: (item) => <span>{formatComma(item.current_amount)} <Icon name='arrow right'/> {formatComma(item.net_amount)}</span>,
			},
			{
				icon: 'file outline alternate',
        header: 'หมายเหตุ',
        accessor: 'note',
			},
    ],
		onClick: () => null
	}

  React.useEffect(() => {
    fetchData();
  }, [page])

  const listElements = () => (
    <div>
      <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
      <StockFilter filter={filter} setFilter={setFilter} fetchData={fetchData}/>
      <StockOutputSegment fetchData={fetchData} />
      { errorMessages && <Message error>{errorMessages}</Message> }
      <Media at='sm'>
        <MobileList
          ref={mobileListRef}
          data={response}
          content={content}
          onPageChange={(page) => setPage(page)}/>
      </Media>
      <Media greaterThanOrEqual='md'>
        <PaginationTable
          ref={tableRef}
          columns={columns}
          data={response}
          onPageChange={(page) => setPage(page)}/>
      </Media>
    </div>
  )

  return (
    <>
      {listElements()}
    </>
  )
}