import moment from 'moment';
import { th } from 'date-fns/locale'
import React from 'react';
import { Button, Dimmer, Dropdown, Header, Icon, Loader, Message, Form } from 'semantic-ui-react';
import { Media } from '../../AppMedia';
import { ReportSummaryTodayModel } from '../../constance/models';
import { URL_REPORT } from '../../constance/urls';
import { GET } from '../../frameworks/HttpClient';
import { formatComma } from '../../frameworks/Util';
import useListItem from '../../hooks/useItemList';
import LineSegment from './LineSegment';
import LineWidgets from './LineWidgets';
import PieSegment from './PieSegment';
import TextWidgets from './TextWidgets';
import * as _ from 'lodash';
import SemanticDatepicker from 'react-semantic-ui-datepickers';


export default function DashBoard(props) {

  const timeScaleChoice = [
    { key: 'day', text: 'รายวัน', value: 'day' },
    { key: 'week', text: 'รายสัปดาห์', value: 'week' },
    { key: 'month', text: 'รายเดือน', value: 'month' },
    { key: 'year', text: 'รายปี', value: 'year' },
  ]
  const [timeScaleFilter, setTimeScaleFilter] = React.useState('day')
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [summaryDateFilter, setSummaryDateFilter] = React.useState(new Date());
  const [reportSummaryToday, setReportSummaryToday] = React.useState(ReportSummaryTodayModel);
  const lineChartWidget = useListItem({ url: URL_REPORT.REPORT_LINE_CHART, usePagination: true });
  const pieChart = useListItem({ 
    url: URL_REPORT.REPORT_PIE_CHART,
    params: { time_scale: timeScaleFilter },
    usePagination: true,
  });

  const isDataLoading = lineChartWidget.isLoading || pieChart.isLoading || isLoading;

  const fetchTodayData = async () => {
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(URL_REPORT.REPORT_TODAY, { 
        date: summaryDateFilter ? moment(summaryDateFilter).format('YYYY-MM-DD') : null 
      })
      setReportSummaryToday(response.data);
    } catch(error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    fetchTodayData();
  }, [summaryDateFilter])

  React.useEffect(() => {
    pieChart.fetchData();
  }, [timeScaleFilter])

  return (
    <div>
      <Dimmer inverted active={isDataLoading}><Loader inverted/></Dimmer>
      <Form>
        <Form.Group inline>
          <label style={{ fontSize: '1.25em' }}>
            <Icon name='calendar alternate outline'/>
            ยอดขายสรุปวันที่
          </label>
          <SemanticDatepicker
            type='basic'
            format='DD MMMM YYYY'
            formatOptions={{
              locale: th,
            }}
            datePickerOnly={true}
            maxDate={new Date().setHours(23, 59, 59, 999)}
            clearable={false}
            value={summaryDateFilter}
            onChange={(_, data) => setSummaryDateFilter(data.value)}/>
        </Form.Group>
      </Form>
      {errorMessages && <Message error>{errorMessages}</Message>}
      <LineWidgets
        data={lineChartWidget.response.results}
        widgets={[{
          title: 'รายได้',
          value: formatComma(reportSummaryToday.total_gain),
          icon: 'dollar sign',
          color: 'teal',
          labelKey: 'date',
          datasetKey: 'total_gain',
          unit: 'บาท',
        }, {
          title: 'ส่วนลด',
          value: formatComma(reportSummaryToday.total_discount),
          icon: 'tags',
          color: 'olive',
          labelKey: 'date',
          datasetKey: 'total_discount',
          unit: 'บาท',
        }, {
          title: 'กำไร',
          value: formatComma(reportSummaryToday.total_profit, true),
          icon: 'money outline',
          color: 'green',
          labelKey: 'date',
          datasetKey: 'total_profit',
          unit: 'บาท',
        }]}/>
      <TextWidgets
        widgets={[{
          color: 'blue',
          icon: 'file outline alternate',
          title: 'ใบเสร็จ',
          unit: 'รายการ',
          value: formatComma(reportSummaryToday.total_transaction),
        }, {
          color: 'blue',
          icon: 'boxes',
          title: 'สินค้าที่ขายได้',
          unit: 'ชิ้น',
          value: formatComma(reportSummaryToday.total_product_count),
        }, [{
            color: 'blue',
            icon: 'money bill alternate outline',
            title: 'ยอดขายเฉลี่ยต่อใบเสร็จ',
            unit: 'บาท',
            value: formatComma(reportSummaryToday.avg_sales),
          }, {
            color: 'blue',
            icon: 'cube',
            title: 'จำนวนสินค้าเฉลี่ยต่อใบเสร็จ',
            unit: 'รายการ',
            value: formatComma(reportSummaryToday.avg_product_count),
        }]]}/>
      <PieSegment
        widths='2'
        data={reportSummaryToday.ratio}
        timeScale={timeScaleFilter}
        menus={[{
          color: 'teal',
          icon: 'chart pie',
          title: 'รายได้แยกตามการชำระเงิน',
          unit: 'บาท',
          showSubtitle: false,
          datasetKey: 'income_type',
        }, {
          color: 'teal',
          icon: 'cubes',
          title: 'รายได้แยกตามประเภทสินค้า',
          unit: 'บาท',
          showSubtitle: false,
          datasetKey: 'most_valuable_categories',
        }]}/>
      <div style={{ textAlign: 'center', marginBottom: '1rem', marginTop: '1rem' }}>
        <Media greaterThanOrEqual='md'>
          <Button.Group basic>
            { timeScaleChoice.map(choice => (
              <Button
                key={choice.key}
                onClick={() => setTimeScaleFilter(choice.value)} 
                content={choice.text}
                active={timeScaleFilter === choice.value}/>
            )) }
          </Button.Group>
        </Media>
        <Media at='sm'>
          <Dropdown
            button
            basic
            options={timeScaleChoice}
            defaultValue='day'
            onChange={(e, data) => {
              setTimeScaleFilter(data.value)
            }}/>
        </Media>
      </div>
      <LineSegment
        fetchURL={URL_REPORT.REPORT_LINE_CHART}
        timeScale={timeScaleFilter}
        menus={[{
          color: 'teal',
          icon: 'dollar sign',
          title: 'รายได้',
          unit: 'บาท',
          labelKey: 'date',
          datasetKey: 'total_gain',
        }, {
          color: 'blue',
          icon: 'file alternate outline',
          title: 'ใบเสร็จ',
          unit: 'รายการ',
          labelKey: 'date',
          datasetKey: 'total_transaction',
        }, {
          color: 'olive',
          icon: 'tags',
          title: 'ส่วนลด',
          unit: 'บาท',
          labelKey: 'date',
          datasetKey: 'total_discount',
        }, {
          color: 'green',
          icon: 'money outline',
          title: 'กำไร',
          unit: 'บาท',
          labelKey: 'date',
          datasetKey: 'total_profit',
        }]}/>
      <div style={{ marginTop: '1rem' }}>
        <PieSegment
          isList={true}
          data={pieChart.response.results} 
          timeScale={timeScaleFilter}
          menus={[{
            color: 'yellow',
            icon: 'star',
            title: 'สินค้าขายดี',
            unit: 'ชิ้น',
            datasetKey: 'best_selling_products',
            showSubtitle: true,
            labelKey: 'date'
          }, {
            color: 'teal',
            icon: 'dollar sign',
            title: 'มูลค่าที่เกิดขึ้นเยอะที่สุด',
            unit: 'บาท',
            showSubtitle: true,
            datasetKey: 'most_valuable_products',
            labelKey: 'date'
          }, {
            color: 'green',
            icon: 'money bill alternate outline',
            title: 'ตัวที่ทำให้เกิดกำไรเยอะที่สุด',
            unit: 'บาท',
            showSubtitle: true,
            datasetKey: 'most_profitable_products',
            labelKey: 'date'
          }]}/>
      </div>
    </div>
  )
}
