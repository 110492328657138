import React from "react";
import { Icon, Message, Modal, Header, Dimmer, Loader, Button, Grid, Segment } from "semantic-ui-react";
import PropTypes from "prop-types";
import ReactCodeInput from "react-code-input";
import { GET, POST } from "../../frameworks/HttpClient";
import { URL_STAFF } from "../../constance/urls";
import * as _ from 'lodash';
import { useStoreContext } from "../../hooks/StoreProvider";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ellipsisString } from "../../frameworks/Util";


var lastClick = 0;
var delay = 30;
export default function LoginPINModal(props) {
  const {
    onLoginSuccess
  } = props;

  const [isLoading, setLoading] = React.useState(false)
	const [errorMessages, setErrorMessages] = React.useState(null)
  const [isPinCodeValid, setIsPinCodeValid] = React.useState(true);
  const [pinCode, setPinCode] = React.useState("");
  const [showUser, setShowUser] = React.useState(true)
  const [userSelected, setUserSelected] = React.useState(null)
  const [staffs, setStaffs] = React.useState([]);

  const { setting } = useStoreContext();
  const isOpen = setting.use_staff && !setting.staff.id

  const { size } = useWindowDimensions();

  const fetchStaff = async () => {
    setLoading(true);
    setErrorMessages(false);
    try {
      const response = await GET(URL_STAFF.STAFF)
      setStaffs(_.get(response, 'data.results', []))
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async (data) => {
    // prevent function from execution multiple times
    if (lastClick >= (Date.now() - delay))
      return

    lastClick = Date.now()
    if (!userSelected) {
			return;
		}
    let params = {
      staff_id: userSelected.id,
      pin: data
    }
    try {
			const response = await POST(`${URL_STAFF.AUTHENTICATE}`, params);
      onLoginSuccess(response.data)
      setShowUser(true)
		} catch(error) {
      setIsPinCodeValid(false)
		} finally {
      setPinCode('')
    }
  }

  const handlePinChange = pinCode => {
    setPinCode(pinCode);
  };

  React.useEffect(() => {
    if (isOpen) {
      setErrorMessages(null)
      fetchStaff()
    }
  }, [isOpen])

  React.useEffect(() => {
    if (showUser)
      setPinCode("")
  }, [showUser])

  React.useEffect(() => {
    if (pinCode && (pinCode.length === 6)) {
      handleSubmit(pinCode)
    } else {
      if (!isPinCodeValid) setIsPinCodeValid(true)
    }
  }, [pinCode])

	return (
		<div>
			<Modal
        open={isOpen}
        onClose={() => null}
				size={"tiny"}
        closeOnDimmerClick={false}>
        <Modal.Header><Icon name="lock"/>เข้าสู่ระบบ</Modal.Header>
        <Modal.Content>
          <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
          {showUser && (
            <div>
              <Header textAlign="center">เลือกผู้ใช้</Header>
              <Grid columns={size == "sm" ? "3" : "4"}>
                {staffs.map((item) => (
                  <Grid.Column 
                    key={item.id}
                    onClick={() => {
                      setUserSelected(item)
                      setShowUser(false)
                    }}>
                    <Segment textAlign="center">
                      <div>
                        <Icon style={{marginBottom: '0.5rem'}} name="user circle" size="big"/>
                      </div>
                      <label>{size == "sm" ? ellipsisString(item.first_name, 7) : item.first_name}</label>
                    </Segment>
                  </Grid.Column>
                ))}
              </Grid>
            </div>
          )}

          {!showUser && (
            <div style={{textAlign: "center", marginBottom: 16}}>
              <Header>
                <Header.Content>PIN Code</Header.Content>
                <Header.Subheader>{userSelected.first_name}</Header.Subheader>
              </Header>
              <ReactCodeInput
                type='password'
                pattern="[0-9]*"
                inputMode="numeric"
                fields={6}
                isValid={isPinCodeValid}
                value={pinCode}
                onChange={handlePinChange}
              />
            </div>
          )}

          { errorMessages && <Message error>{errorMessages}</Message> }

          {!showUser && (
            <Button color={"blue"} onClick={() => setShowUser(true)}>ผู้ใช้</Button>
          )}
        </Modal.Content>
      </Modal>
		</div>
	)
}

LoginPINModal.defaultProps = {
  onLoginSuccess: (data) => null,
}

LoginPINModal.propsTypes = {
  onLoginSuccess: PropTypes.func,
}