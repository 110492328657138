import { useFormik } from "formik";
import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import * as _ from "lodash";
import { ProductLinkModel, productSearchRenderer } from "../../constance/models/product";
import { Button, Dimmer, Form, Loader, Message, Modal, Search } from "semantic-ui-react";
import useListItem from "../../hooks/useItemList";
import { URL_PRODUCT } from "../../constance/urls";
import CustomSearchResult from "../common/CustomSearchResult";
import CustomInputField from "../common/CustomInputField";
import { GET, PATCH, POST } from "../../frameworks/HttpClient";
import { Toast } from "../../frameworks/Toast";


export default function ProductGroupModal (props) {
  const {
    open,
    onClose,
    product,
    target,
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const isCreate = target == 'add';

  const productList = useListItem({
    url: URL_PRODUCT.PRODUCT,
    usePagination: true,
    manual: true,
    params: { page_size: 5, exclude: product }
  });

  const formik = useFormik({
    validateOnChange: false,
    initialValues: ProductLinkModel,
    validationSchema: Yup.object().shape({
      product: Yup.string().required('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง').nullable(),
      target: Yup.string().required('กรุณาเลือกสินค้า').nullable(),
      amount: Yup.number().required('กรุณากรอกจำนวน')
        .moreThan(0, 'กรุณากรอกจำนวนมากกว่า 0').nullable()
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      setErrorMessages(null);
      try {
        if (isCreate) {
          await POST(URL_PRODUCT.PRODUCT_LINK, values)
        } else {
          await PATCH(`${URL_PRODUCT.PRODUCT_LINK}${target}/`, values)
        }
        Toast.success('สำเร็จ')
        onClose();
      } catch (error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setIsLoading(false)
      }
    }
  })

  const fetchData = async () => {
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_PRODUCT.PRODUCT_LINK}${target}/`)
      formik.setValues(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (open) {
      if (isCreate) {
        formik.setFieldValue('product', product)
      } else {
        fetchData();
      }
    } else {
      formik.resetForm();
    }
  }, [open])

  React.useEffect(() => {
    if (formik.errors.product) {
      setErrorMessages(formik.errors.product)
    }
  }, [formik.errors])

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeIcon
      centered={false}
    >
      <Modal.Header>เพิ่มสินค้าเข้ากลุ่ม</Modal.Header>
      <Modal.Content>
        <Dimmer inverted active={isLoading}>
          <Loader inverted content/>
        </Dimmer>
        { errorMessages && <Message error>{errorMessages}</Message> }
        <Form>
          <Form.Field
            label='สินค้า'
            fluid
            control={Search}
            placeholder='ค้นหาตามชื่อ Barcode...'
            minCharacters={3}
            resultRenderer={CustomSearchResult}
            showNoResults={true}
            results={productList.response.results.map(productSearchRenderer)}
            onSearchChange={async (_, data) => {
              if (formik.values.target) {
                formik.setFieldValue('target', null)
              }
              productList.fetchData({ search: data.value })
              formik.setFieldValue('target_name', data.value)
            }}
            onResultSelect={(e, data) => {
              formik.setFieldValue('target', _.get(data, 'result.id'))
              formik.setFieldValue('target_name', _.get(data, 'result.name'))
            }}
            onBlur={() => {
              if (!formik.values.target) {
                formik.setFieldValue('target_name', '')
              }
            }}
            value={formik.values.target_name}
            error={formik.errors.target}/>
          <CustomInputField
            isForm
            name='amount'
            type='number'
            label='จำนวนบรรจุ'
            value={formik.values.amount}
            error={formik.errors.amount}
            onChange={formik.handleChange}/>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button icon='cancel' content='ยกเลิก' onClick={onClose}/>
        <Button color={isCreate ? 'green' : 'blue'} icon='check' content={isCreate ? 'เพิ่ม' : 'แก้ไข'} onClick={formik.handleSubmit}/>
      </Modal.Actions>
    </Modal>
    
  )
}

ProductGroupModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  product: PropTypes.string,
  target: PropTypes.string,
}


ProductGroupModal.defaultProps = {
  open: false,
  onClose: () => null,
  product: '',
  target: '',
}