import React from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Dimmer, Header, Form, Loader, Message, Divider, Input, Icon, Accordion, Button } from 'semantic-ui-react';
import PaginationTable from '../components/common/PaginationTable';
import StockInputSegment from '../components/stock/StockInputSegment';
import { URL_STOCK } from '../constance/urls';
import { dateTimeToString, formatComma, getColumnsList } from '../frameworks/Util';
import useListItem from '../hooks/useItemList';
import * as _ from 'lodash';
import moment from 'moment';
import QRCodeReaderModal from '../components/common/QRCodeReaderModal';
import { Media } from '../AppMedia';
import MobileList from '../components/common/MobileList';
import CustomInputField from '../components/common/CustomInputField';


export default function StockListView() {
  const [dateRange, setDateRange] = React.useState([]);
  const [filterActive, setFilterActive] = React.useState(false);
  const [searchFilter, setSearchFilter] = React.useState(null);
  const [openQRCodeModal, setOpenQRCodeModal] = React.useState(false);
  const { response, isLoading, errorMessages, fetchData } = useListItem({ 
    url: URL_STOCK.STOCK_INPUT_TRANSACTION,
    params: { 
      date_after: _.get(dateRange, 0, false) ? moment(dateRange[0]).format('YYYY-MM-DD') : null,
			date_before: _.get(dateRange, 1, false) ? moment(dateRange[1]).format('YYYY-MM-DD') : null,
      search: searchFilter 
    },
    usePagination: true
  });

  const columns = React.useMemo(() => [
    ...getColumnsList([
      { Header: 'วันที่', accessor: 'created', Cell: ({value}) => dateTimeToString(value), width: 200 },
			{ Header: 'สินค้า', accessor: 'product_name' },
			{ Header: 'ยอดเก่า', accessor: 'current_amount', Cell: ({value}) => formatComma(value), width: 100 },
			{ Header: 'จำนวนรับเข้า', accessor: 'amount', Cell: ({value}) => formatComma(value), width: 100 },
			{ Header: 'ยอดสุทธิ', Cell: ({original}) => formatComma(Number(original.current_amount) + Number(original.amount)), width: 100 },
			{ Header: 'ทุนต่อหน่วย (บาท)', accessor: 'cost_price', Cell: ({value}) => formatComma(value), width: 100 },
    ])
  ])

  const content = {
		header: {
			icon: 'box',
      accessor: 'product_name',
		},
		descriptions: [
			{
				icon: 'clock outline',
        header: 'วันที่',
        detail: (item) => dateTimeToString(item.created),
			},
			{
				icon: 'add circle',
        header: 'จำนวนรับเข้า',
        detail: (item) => formatComma(item.amount),
			},
      {
				icon: 'file outline alternate',
        header: 'ยอดสินค้า',
        detail: (item) => <span>{formatComma(item.current_amount)} <Icon name='arrow right'/> {formatComma(Number(item.current_amount) + Number(item.amount))}</span>,
			},
			{
				icon: 'money bill alternate outline',
        header: 'ทุนต่อหน่วย',
        detail: (item) => `${formatComma(item.cost_price)} บาท`,
			},		],
		onClick: () => null
	}

  return (
    <div style={{ padding: '2rem' }}>
      <QRCodeReaderModal 
        open={openQRCodeModal}
        onClose={() => setOpenQRCodeModal(false)}
        onQRCodeSuccess={(text) => {
          setSearchFilter(text);
          setOpenQRCodeModal(false)
        }}/>
      <Header dividing style={{textAlign: "left"}}>รับเข้าสินค้า</Header>
      <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
      <Media lessThan='lg'>
        <Accordion fluid>
          <Accordion.Title 
            active={filterActive}
            onClick={() => setFilterActive(state => !state)}>
            <Icon name='dropdown' />
            ค้นหา
          </Accordion.Title>
          <Accordion.Content active={filterActive}>
            <Form>
              <Form.Group widths='equal'>
                <SemanticDatepicker
                  datePickerOnly={true}
                  maxDate={new Date().setHours(23, 59, 59, 999)}
                  style={{ width: '100%' }}
                  locale='en-US'
                  type='range'
                  showToday={true}
                  format='DD/MM/YY'
                  placeholder='เลือกช่วงเวลา...'
                  value={dateRange}
                  onChange={(_, data) => setDateRange(data.value)}/>
                <Form.Field width='16'>
                  <CustomInputField
                    action={<Button color='blue' icon='qrcode' type='button' onClick={() => setOpenQRCodeModal(true)} />}
                    placeholder='ค้นหาตามชื่อ barcode...'
                    value={searchFilter}
                    onChange={(_, data) => setSearchFilter(data.value)}/>
                </Form.Field>
                <Form.Button color='blue' icon='search' content='ค้นหา' onClick={() => fetchData()}/>
              </Form.Group>
            </Form>
          </Accordion.Content>
        </Accordion>
      </Media>
      <Media greaterThan='md'>
        <Form>
          <Form.Group inline unstackable>
            <SemanticDatepicker
              datePickerOnly={true}
              maxDate={new Date().setHours(23, 59, 59, 999)}
              label='ค้นหา'
              style={{ width: '100%' }}
              locale='en-US'
              type='range'
              showToday={true}
              format='DD/MM/YY'
              placeholder='เลือกช่วงเวลา...'
              value={dateRange}
              onChange={(_, data) => setDateRange(data.value)}/>
            <Form.Field>
              <CustomInputField
                action={<Button color='blue' icon='qrcode' type='button' onClick={() => setOpenQRCodeModal(true)} />}
                placeholder='ค้นหาตามชื่อ barcode...'
                value={searchFilter}
                onChange={(_, data) => setSearchFilter(data.value)}/>
            </Form.Field>
            <Form.Button fluid color='blue' icon='search' content='ค้นหา' onClick={() => fetchData()}/> 
          </Form.Group>
        </Form>
      </Media>
      { errorMessages && <Message error>{errorMessages}</Message> }
      <StockInputSegment fetchData={fetchData}/>
      <Media at='sm'>
        <MobileList
          data={response}
          content={content}
          onPageChange={(page) => fetchData({ page })}/>
      </Media>
      <Media greaterThanOrEqual='md'>
        <PaginationTable
          columns={columns}
          data={response}
          onPageChange={(page) => fetchData({ page })}/>        
      </Media>
    </div>
  )
}