import React from 'react';
import useDropdownItem from '../../hooks/useDropdownItem';
import { URL_PRODUCT, URL_REPORT } from '../../constance/urls';
import useListItem from '../../hooks/useItemList';
import { formatComma, getColumnsList } from '../../frameworks/Util';
import * as _ from 'lodash';
import { Accordion, Button, Dimmer, Divider, Dropdown, Form, Icon, Label, Loader, Message } from 'semantic-ui-react';
import QRCodeReaderModal from '../common/QRCodeReaderModal';
import ProductModal from './ProductModal';
import { Media } from '../../AppMedia';
import CustomInputField from '../common/CustomInputField';
import MobileList from '../common/MobileList';
import PaginationTable from '../common/PaginationTable';
import ShareReportModal from '../report/ShareReportModal';
import { useStoreContext } from '../../hooks/StoreProvider';
import ProductAmountLogModal from './ProductAmountLogModal';
import { ProductModel } from '../../constance/models';
import ManageProductCategoryModal from './ManageProductCategoryModal';


export default function ProductList(props) {
  const {
    minStock
  } = props;

  const { setting } = useStoreContext();
  const { staff } = setting;
  const canExport = setting.use_staff ? staff.export_report : true;
  const canEdit = setting.use_staff ? staff.edit_product : true;

  const [productTarget, setProductTarget] = React.useState(null);
  const [productHistoryTarget, setProductHistoryTarget] = React.useState(ProductModel);
  const productCategory = useDropdownItem({url: URL_PRODUCT.PRODUCT_CATEGORY});
  const [filterActive, setFilterActive] = React.useState(false);
  const [filter, setFilter] = React.useState({});
  const [openQRCodeModal, setOpenQRCodeModal] = React.useState(false);
  const [isShareReportOpen, setIsShareReportOpen] = React.useState(false);
  const [openEditCategoryModal, setOpenEditCategoryModal] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const tableRef = React.useRef();
  const mobileListRef = React.useRef();
  const { response, isLoading, errorMessages, fetchData } = useListItem({ 
    url: URL_PRODUCT.PRODUCT,
    params: { ...filter, is_out_of_stock: minStock, page: page },
    usePagination: true,
    manual: true,
  });
  const [columns, setColumns] = React.useState(() => [
    ...getColumnsList([
      { Header: 'สินค้า', accessor: 'name' },
      { Header: 'ประเภท', accessor: 'category_detail.name' },
			{ Header: 'Barcode', accessor: 'barcode_no', width: 140 },
			{ 
        Header: 'จำนวน', 
        accessor: 'amount', 
        width: 80,
        Cell: ({original, value}) => {
          const min_stock = Number(_.get(original, 'min_stock', 0))
          const amount = Number(_.get(original, 'amount', 0))
          return <span>{ min_stock >= amount ? <Label basic color='red'><Icon name='warning circle'/>{formatComma(value, true)}</Label> : formatComma(value)}</span>
        }
      },
			{ Header: 'ราคาขาย (บาท)', accessor: 'sell_price', width: 100, Cell: ({value}) => formatComma(value) },
      { 
        Header: 'คำนวณ VAT', 
        accessor: 'has_vat',
        width: 70,
        Cell: ({value}) => <Icon style={{margin: 'auto'}} name={value ? 'check circle' : 'times circle'} color={value ? 'green' : 'red'}/>
      },
    ])
  ])

  const content = {
    header: {
			icon: 'box',
			accessor: 'name',
		},
		descriptions: [
      { 
        icon: 'barcode',
        header: 'Barcode',
        accessor: 'barcode_no',
      },
			{
				icon: 'boxes',
        header: 'จำนวน',
        detail: (item) => {
          const min_stock = Number(_.get(item, 'min_stock', 0))
          const amount = Number(_.get(item, 'amount', 0))
          return min_stock >= amount ? (
            <Label basic color='red'><Icon name='warning circle'/>{formatComma(item.amount, true)}</Label>
          ) : formatComma(item.amount)
        },
			},
			{
				icon: 'money bill alternate outline',
        header: 'ราคาขาย',
        detail: (item) => `${formatComma(item.sell_price)} บาท`,
			},
      {
				icon: 'tags',
        header: 'คำนวณ VAT',
        detail: (item) => <Icon name={item.has_vat ? 'check circle' : 'times circle'} color={item.has_vat ? 'green' : 'red'}/>,
			},
		],
		onClick: (item) => canEdit ? setProductTarget(item.id) : null
  }

  const handleFilterChange = (_, data) => {
    setFilter((prevFilter) => ({ ...prevFilter, [data.name]: data.value }))
  }

  const resetListPage = () => {
    setPage(1);
    if(tableRef.current) {
      tableRef.current.initialPage();
    }
    if(mobileListRef.current) {
      mobileListRef.current.initialPage();
    }
  }

  React.useEffect(() => {
    if (canEdit) {
      setColumns(columns => [
        ...columns, ...getColumnsList([{
          Header: '', 
          accessor: 'id', 
          width: 220,
          Cell: ({ original }) => (
            <div>
              <Button
                basic
                icon='edit'
                color='blue'
                content='แก้ไข'
                onClick={() => setProductTarget(original.id)}/>
              <Button
                basic
                icon='eye'
                color='teal'
                content='ประวัติ'
                onClick={() => setProductHistoryTarget(original)}/>
            </div>
          )
        }])
      ])
    }
  }, [staff])

  React.useEffect(() => {
    if (!productTarget) {
      fetchData();
    }
  }, [productTarget, page])

  React.useEffect(() => {
    if (page === 1) {
      fetchData();
    }
    resetListPage();
  }, [minStock])

  const filterElements = () => (
    <>
      <Form.Select
        name='category'
        placeholder='ประเภทสินค้า'
        clearable
        options={productCategory.response}
        value={_.get(filter, 'category', '')}
        onChange={handleFilterChange}/>
      <Form.Select
        name='has_vat'
        placeholder='การคำนวณภาษี'
        clearable
        options={[
          { key: 'no_vat', value: false, text: 'สินค้าไม่มีภาษี' },
          { key: 'vat', value: true, text: 'สินค้ามีภาษี' }
        ]}
        value={_.get(filter, 'has_vat', '')}
        onChange={handleFilterChange}/>
      <Form.Field>
        <CustomInputField
          name='search'
          placeholder='ค้นหาตามชื่อ barcode...'
          value={_.get(filter, 'search')}
          action={
            <Button
              color='blue'
              icon='qrcode'
              type='button'
              onClick={() => setOpenQRCodeModal(true)} />
          }
          onChange={handleFilterChange}/>
      </Form.Field>
      <Form.Button color='blue' icon='search' content='ค้นหา' onClick={() => fetchData()}/>
    </>
  )

  const listElements = () => (
    <div>
      <Media at='sm'>
        <MobileList
          ref={mobileListRef}
          data={response}
          content={content}
          onPageChange={(page) => setPage(page)}/>
      </Media>
      <Media greaterThanOrEqual='md'>
        <PaginationTable
          ref={tableRef}
          columns={columns}
          data={response}
          onPageChange={(page) => setPage(page)}/>
      </Media>
    </div>
  )

  const buttonMenu = () => (
    <div>
      <Media lessThan='lg'>
        <Button.Group color='blue' floated='right'>
          <Dropdown
            text='เมนูสินค้า'
            icon='dropdown'
            floating
            labeled
            button
            className='icon'
          >
            <Dropdown.Menu>
              <Dropdown.Header><Icon color='blue' name='box'/>เมนูสินค้า</Dropdown.Header>
              <Dropdown.Divider/>
              { canEdit && (
                <Dropdown.Item onClick={() => setProductTarget('add')}><Icon color='green' name='plus'/>เพิ่ม</Dropdown.Item>
              ) }
              { canExport && (
                <Dropdown.Item onClick={() => setIsShareReportOpen(true)}><Icon color='teal' name='mail'/>แชร์รายงาน</Dropdown.Item>
              ) }
              <Dropdown.Item onClick={() => setOpenEditCategoryModal(true)}><Icon color='yellow' name='edit'/>จัดการประเภทสินค้า</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Button.Group>

      </Media>
      <Media greaterThanOrEqual='lg'>
        { canEdit && (
          <Button floated='right' color='green' icon='add' content='เพิ่ม' type='button' onClick={() => setProductTarget('add')}/>
        )}
        { canExport && (
          <Button floated='right' color='teal' icon='mail' type='button' content='แชร์รายงาน' onClick={() => setIsShareReportOpen(true)}/> 
        ) }
        <Button floated='right' color='yellow' icon='edit' type='button' content='จัดการประเภทสินค้า' onClick={() => setOpenEditCategoryModal(true)} />
      </Media>  
    </div>
  )

  return (
    <div>
      <QRCodeReaderModal 
        open={openQRCodeModal}
        onClose={() => setOpenQRCodeModal(false)}
        onQRCodeSuccess={(text) => {
          handleFilterChange(null, {name: 'search', value: text})
          setOpenQRCodeModal(false)
        }}/>
      <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
      <ProductModal
        open={productTarget != null}
        onClose={() => setProductTarget(null)}
        target={productTarget}/>
      <ProductAmountLogModal
        open={productHistoryTarget.id != null}
        onClose={() => setProductHistoryTarget(ProductModel)}
        target={productHistoryTarget.id}
        detail={productHistoryTarget}/>
      <Form>
        {buttonMenu()}
        <Accordion fluid>
          <Accordion.Title 
            active={filterActive}
            onClick={() => setFilterActive(state => !state)}>
            <Icon name='dropdown'/>
            ค้นหา
          </Accordion.Title>
          <Accordion.Content active={filterActive}>
            <Form.Group widths={2}>
              {filterElements()}
            </Form.Group>
          </Accordion.Content>
        </Accordion>
        <Divider clearing hidden style={{marginTop: 0}}/>
      </Form>
      { errorMessages && <Message error>{errorMessages}</Message> }
      {listElements()}
      <ShareReportModal
        open={isShareReportOpen}
        onClose={() => setIsShareReportOpen(false)} 
        baseURL={URL_REPORT.SHARE_PRODUCT_SUMMARY}
        isDateRequire={false}
        params={minStock ? {is_out_of_stock: true} : {}} />
      <ManageProductCategoryModal 
        open={openEditCategoryModal}
        onClose={() => {
          setOpenEditCategoryModal(false)
          productCategory.fetchData()
        }}
      />
    </div>
  )
}