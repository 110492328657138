import React from 'react';
import { Html5QrcodeScanner } from "html5-qrcode";
import PropTypes from 'prop-types';


const QRCodeReader = React.forwardRef((props, ref) => {
  const DIV_ID = "QRCODE-DIV"
  const [scanner, setScanner] = React.useState(null);
  

  React.useEffect(() => {
    const sc = new Html5QrcodeScanner(DIV_ID, props.config, props.verbose)
    sc.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback)
    setScanner(sc)

    return (() => {
      if (scanner != null) {
        scanner.clear()
        setScanner(null)
      }
    })
  }, [])

  React.useImperativeHandle(ref, () => ({
    pause: (isStopVideo) => scanner != null ? scanner.pause(isStopVideo) : null,
    resume: () => scanner != null ? scanner.resume() : null,
    // Important!! Please call cleanup on finish to close camera
    cleanup: () => scanner != null ? scanner.clear() : null,
  }));

  return(
    <div id={DIV_ID}/>
  )
})

QRCodeReader.defaultProps = {
  config: {
    fps: 20,  // camera framerate
    aspectRatio: 1.333334,  // Float, Example 1.777778 for 16:9 aspect ratio
    qrbox: { width: 250, height: 250 },  // (Optional) QRCode Dimension { width: 250, height: 250 }
    disableFlip: false,  // (Optional) Boolean, default = false
  },
  verbose: false,
  qrCodeSuccessCallback: (decodedText, result) => null,
  qrCodeErrorCallback: (errorMessage, error) => null,
}

QRCodeReader.propTypes = {
  config: PropTypes.object,  // QR-Reader configurations
  verbose: PropTypes.bool,  // Print all log?
  qrCodeSuccessCallback: PropTypes.func,  // Callback on QRCode captured successfully
  qrCodeErrorCallback: PropTypes.func,  // Callback on QRCode captured error
}

export default QRCodeReader;