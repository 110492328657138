import React from 'react';
import { GET } from '../frameworks/HttpClient';


// usage ==> const { response, isLoading, errorMessages, fetchData } = useListItem({url: '/api/items/'});
export default function useListItem ({url, params, manual = false, usePagination = false}) {
  const [response, setResponse] = React.useState(
    usePagination ? 
    { 
      links: {
        next: null,
        previous: null,
      },
      total: 0,
      pages: 0,
      results: [] 
    } : []
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [fetched, setFetched] = React.useState(0);

  React.useEffect(() => {
    if(!manual) {
      fetchData()
    }
  },[]);

  const fetchData = async (additional_params) => {
    setErrorMessages(null);
    setIsLoading(true);
    try {
      const response = await GET(url, { ...params, ...additional_params });
      setResponse(response.data)
      setFetched(prev => prev + 1)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  };

  return { response, isLoading, errorMessages, fetchData, fetched };
}