import { useFormik } from 'formik';
import React from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';
import { URL_PRODUCT } from '../../constance/urls';
import { POST, PATCH } from '../../frameworks/HttpClient';
import { Toast } from '../../frameworks/Toast';
import CustomInputField from '../common/CustomInputField';


export default function AddProductCategoryModal(props) {

  const {
    category,
    open,
    onClose,
    isOfficer,
    onSuccess
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: { name: null },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('กรุณากรอกข้อมูล')
    }),
    onSubmit: async (values) => {
      setErrorMessages(null);
      setIsLoading(true);
      try {
        const url = isOfficer ? URL_PRODUCT.OFFICER_PRODUCT_CATEGORY : URL_PRODUCT.PRODUCT_CATEGORY;
        if (category != null) {
          const response = await PATCH(`${url}${category.id}/`, values);
          Toast.success('สำเร็จ');
          onSuccess(response.data.id);
        }
        else {
          const response = await POST(url, values);
          Toast.success('สำเร็จ');
          onSuccess(response.data.id);
        }
      } catch(error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setIsLoading(false)
      }
    }
  })

  React.useEffect(() => {
    if(open) {
      formik.resetForm();
      if (category != null) {
        formik.setFieldValue('name', category.name)
      }
    }
  }, [open])

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        closeIcon
        closeOnDimmerClick={false}>
        <Modal.Header>{`${category != null ? "แก้ไข" : "เพิ่ม"}ประเภทสินค้า`}</Modal.Header>
        <Modal.Content>
          <Form>
            <CustomInputField
              isForm
              name='name'
              label='ชื่อประเภทสินค้า'
              icon='list'
              iconPosition='left'
              placeholder='ชื่อประเภทสินค้า'
              value={formik.values.name}
              error={formik.errors.name}
              onChange={formik.handleChange}/>
          </Form>
        </Modal.Content>
        <Modal.Actions>
         <Button loading={isLoading} content='ยกเลิก' icon='cancel' onClick={onClose}/>
          <Button 
            loading={isLoading}
            content={category != null ? 'แก้ไข' : 'เพิ่ม'}
            color={category != null ? 'yellow' : 'green'}
            icon={category != null ? 'edit' : 'add'}
            onClick={formik.handleSubmit}/>
         </Modal.Actions>
      </Modal>
    </div>
  )
}