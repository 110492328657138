import React from 'react';
import { Button, Dimmer, Divider, Header, Loader, Message, Modal, Segment } from 'semantic-ui-react';
import { URL_STORE } from '../../constance/urls';
import { POST } from '../../frameworks/HttpClient';
import { Toast } from '../../frameworks/Toast';
import { dateToString, getColumnsList } from '../../frameworks/Util';
import useListItem from '../../hooks/useItemList';
import PaginationTable from '../common/PaginationTable';
import QRCodeReaderModal from '../common/QRCodeReaderModal';


export default function StoreUserList(props) {
  const {
    target
  } = props;

  const { response, isLoading, errorMessages, fetchData } = useListItem({ 
    url: `${URL_STORE.OFFICER_STORE}${target}/users/`,
    usePagination: true,
    manual: true,
  });
  const [removeTarget, setRemoveTarget] = React.useState(null);
  const [removeConfirmVisible, setRemoveConfirmVisible] = React.useState(false);
  const [segmentLoading, setSegmentLoading] = React.useState(false);
  const [segmentErrorMessages, setSegmentErrorMessages] = React.useState(null);
  const [openAddUser, setOpenAddUser] = React.useState(false);

  const columns = React.useMemo(() => [
    ...getColumnsList([
			{ Header: 'รหัสเครื่อง', accessor: 'username' },
			{ Header: 'วันที่เริ่มใช้งาน', accessor: 'created', Cell: ({value}) => dateToString(value) },
			{ Header: '', accessor: 'id', width: 120, Cell: ({ value }) => 
        <Button
          basic
          icon='remove'
          color='red'
          content='นำออก'
          onClick={() => setRemoveUser(value)}/>
      },
    ])
  ]);

  const handleAddUser = async (value) => {
    setOpenAddUser(false);
    try {
      await POST(`${URL_STORE.OFFICER_STORE}${target}/add_user/`, { username: value });
      Toast.success('สำเร็จ');
      fetchData();
    } catch(error) {
      Toast.error(error.errorMessages)
    } finally {
      setSegmentLoading(false)
    }
  }

  const handleRemove = async () => {
    setSegmentErrorMessages(null);
    setSegmentLoading(true);
    try {
      await POST(`${URL_STORE.OFFICER_STORE}${target}/remove_user/`, { user: removeTarget });
      Toast.success('สำเร็จ');
      fetchData();
      setRemoveConfirmVisible(false);
      removeTarget(null);
    } catch(error) {
      setSegmentErrorMessages(error.errorMessages)
    } finally {
      setSegmentLoading(false)
    }
  }

  const setRemoveUser = (value) => {
    setRemoveConfirmVisible(true);
    setRemoveTarget(value);
  }

  const clearRemoveUser = () => {
    setRemoveConfirmVisible(false);
    setRemoveTarget(null);
  }

  React.useEffect(() => {
    if(!openAddUser) {
      fetchData();
    }
  }, [openAddUser])

  return (
    <div>
      <QRCodeReaderModal
        open={openAddUser}
        onClose={() => setOpenAddUser(false)}
        onQRCodeSuccess={(text) => {
          handleAddUser(text);
        }}/>
      <Modal
        open={removeConfirmVisible}
        onClose={clearRemoveUser}>
        <Modal.Content>
          { segmentErrorMessages && <Message error>{segmentErrorMessages}</Message> }
          ต้องการนำผู้ใช้งานดังกล่าวออกใช่หรือไม่?
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={segmentLoading}
            onClick={clearRemoveUser}
            content='ยกเลิก'/>
          <Button
            color='blue'
            loading={segmentLoading}
            onClick={handleRemove}
            content='นำออก'/>
        </Modal.Actions>
      </Modal> 
      <Segment>
        <Header>
          เครื่องที่ใช้งาน
          <Button
            loading={segmentLoading}
            floated='right'
            icon='add'
            content='เพิ่มเครื่องใช้งาน'
            color='green'
            onClick={() => setOpenAddUser(true)}/>
        </Header>
        <Divider clearing hidden/>
        <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
        { errorMessages && <Message error>{errorMessages}</Message> }
        <PaginationTable
          columns={columns}
          data={response}
          onPageChange={(page) => fetchData({ page })}/>        
      </Segment>
    </div>
  )
}