export const COLOR_SET = {
	blue: {
		code: "#2185d0",
    lineColor: "rgba(33, 133, 208, 1)",
    backgroundColor: "rgba(33, 133, 208, 0.2)",
  },
  yellow: {
		code: "#fbbd08",
    lineColor: "rgba(251, 189, 8, 1)",
    backgroundColor: "rgba(251, 189, 8, 0.2)",
  },
  teal: {
		code : "#00b5ad",
    lineColor: "rgba(0, 181, 173, 1)",
    backgroundColor: "rgba(0, 181, 173, 0.2)",
  },
  green: {
		code: "#21ba45",
    lineColor: "rgba(33, 186, 69, 1)",
    backgroundColor: "rgba(33, 186, 69, 0.2)",
  },
  orange: {
		code: "#f26202",
    lineColor: "rgba(242, 113, 28, 1)",
    backgroundColor: "rgba(242, 113, 28, 0.2)",
	},
	olive: {
		code: "#b5cc18",
		lineColor: "rgba(181, 204, 24, 1)",
		backgroundColor: "rgba(181, 204, 24, 0.2)",
	},
	black: {
		code: "#000000",
		lineColor: "rgba(0, 0, 0, 1)",
		backgroundColor: "rgba(0, 0, 0, 0.2)",
	},
	grey: {
		code: "#767676",
		lineColor: "rgba(118, 118, 118, 1)",
		backgroundColor: "rgba(118, 118, 118, 0.2)",
	},
	violet: {
		code: "#6435c9",
		lineColor: "rgba(100, 53, 201, 1)",
		backgroundColor: "rgba(100, 53, 201, 0.2)",
	},
	purple: {
		code: "#a333c8",
		lineColor: "rgba(163, 51, 200, 1)",
		backgroundColor: "rgba(163, 51, 200, 0.2)",
	},
	pink: {
		code: "#e03997",
		lineColor: "rgba(224, 57, 151, 1)",
		backgroundColor: "rgba(224, 57, 151, 0.2)",
	},
	red: {
		code: "#db2828",
		lineColor: "rgba(219, 40, 40, 1)",
		backgroundColor: "rgba(219, 40, 40, 0.2)",
	},
	white: {
		code: "#ffffff",
		lineColor: "rgba(255, 255, 255, 1)",
		backgroundColor: "rgba(255, 255, 255, 0.8)",
		noneColor: "rgba(255, 255, 255, 0)",
	}
};

export const TH_MONTH = [
	"มกราคม", 
	"กุมภาพันธ์", 
	"มีนาคม",
	"เมษายน",
	"พฤษภาคน",
	"มิถุนายน",
	"กรกฎาคม",
	"สิงหาคม",
	"กันยายน",
	"ตุลาคม",
	"พฤศจิกายน",
	"ธันวาคม"
]
