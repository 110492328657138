import { useFormik } from 'formik';
import React from 'react';
import { Button, Checkbox, Form, Icon, Image, Message, Modal, Popup, Confirm } from 'semantic-ui-react';
import { URL_PRODUCT } from '../../constance/urls';
import { GET, POST, PATCH, DELETE } from '../../frameworks/HttpClient';
import useDropdownItem from '../../hooks/useDropdownItem';
import * as Yup from 'yup';
import { ProductCategoryModel, ProductModel } from '../../constance/models';
import QRCodeReaderModal from '../common/QRCodeReaderModal';
import AddProductCategoryModal from './AddProductCategoryModal';
import CustomInputField from '../common/CustomInputField';
import * as _ from 'lodash';
import { resizeImage } from '../../frameworks/Util';
import { Toast } from '../../frameworks/Toast';
import ProductAmountLogModal from './ProductAmountLogModal';
import CustomFileField from '../common/CustomFileField';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useStoreContext } from '../../hooks/StoreProvider';
import ProductGroupSegment from './ProductGroupSegment';


export default function ProductModal(props) {
  const {
    open,
    onClose,
    target,
  } = props;

  const { setting } = useStoreContext();

  const { size } = useWindowDimensions();
  const productCategory = useDropdownItem({url: URL_PRODUCT.PRODUCT_CATEGORY, manual: true});
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [openQRCodeModal, setOpenQRCodeModal] = React.useState(false);
  const [openAddCategory, setOpenAddCategory] = React.useState(false);
  const [openAmountLog, setOpenAmountLog] = React.useState(false);
  const [fetchedCategory, setFetchedCagetory] = React.useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);
  const [file, setFile] = React.useState(null);
  // const [fileName, setFileName] = React.useState(null);

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: ProductModel,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('กรุณากรอกข้อมูล'),
    }),
    onSubmit: async (values) => {
      setErrorMessages(null);
      setIsLoading(true);
      try {
        delete values['image'];
        const params = {...values}
        if (file) { params['image'] = await resizeImage(file) }
        if (isCreate) {
          await POST(URL_PRODUCT.PRODUCT, params);
        } else {
          await PATCH(`${URL_PRODUCT.PRODUCT}${formik.values.id}/`, params);
        }
        Toast.success('สำเร็จ');
        onClose();
      } catch (error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setIsLoading(false);
      }
    }
  })

  const fetchData = async () => {
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_PRODUCT.PRODUCT}${target}/`)
      formik.setValues(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchProductByBarcode = async (barcode_no, isInitialCreate) => {
    if (!isInitialCreate) {
      return;
    }
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_PRODUCT.PRODUCT_BY_BARCODE}${barcode_no}/`);
      formik.setValues(response.data)
      // if product categroy template then add to extra for render
      const extraCategory = _.get(response.data, 'category_detail', ProductCategoryModel)
      if (extraCategory.is_template) {
        setFetchedCagetory(_.get(response.data, 'category_detail'))
      } else {
        setFetchedCagetory(null)
      }
    } catch {
      formik.setValues({...ProductModel, barcode_no})
      setFetchedCagetory(null)
    } finally {
      setIsLoading(false)
    }
  }

  const getCategory = () => {
    let renderCategory = {};
    productCategory.response.forEach(element => {
      renderCategory[element.text] = {...element}
    });
    if (fetchedCategory) {
      renderCategory[_.get(fetchedCategory, 'name')] = { 
        ...fetchedCategory, 
        key: _.get(fetchedCategory, 'id'), 
        value: _.get(fetchedCategory, 'id'), 
        text: _.get(fetchedCategory, 'name'), 
      }
    }
    return Object.entries(renderCategory).map(e => e[1])
  }

  const handleDelete = async () => {
    setShowConfirmDelete(false)
    setErrorMessages(null)
    try {
      setIsLoading(true)
      const id = formik.values['id']
      await DELETE(`${URL_PRODUCT.PRODUCT}${formik.values.id}/`);
      Toast.success('สำเร็จ');
      onClose();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false)
    }
  }

  const isInitialCreate = target === 'add'
  const isCreate = isInitialCreate && !formik.values.store;

  const debouncedFetch = React.useCallback(
		_.debounce((...params) => fetchProductByBarcode(...params), 1000),
		[],
	);

  React.useEffect(() => {
    if (open) {
      productCategory.fetchData();
      setErrorMessages(null);
      setFile(null);
      // setFileName(null);
      if (!isCreate) {
        fetchData(target)
      }
    } else {
      formik.resetForm();
    }
  }, [open])

  return (
    <div>
      <ProductAmountLogModal
        open={openAmountLog}
        onClose={() => setOpenAmountLog(false)}
        target={target}
        detail={formik.values}/>
      <AddProductCategoryModal
        open={openAddCategory}
        onClose={() => setOpenAddCategory(false)}
        onSuccess={(value) => {
          productCategory.fetchData();
          formik.setFieldValue('category', value)
          setOpenAddCategory(false);
        }}/>
      <QRCodeReaderModal 
        open={openQRCodeModal}
        onClose={() => setOpenQRCodeModal(false)}
        onQRCodeSuccess={(text) => {
          formik.setFieldValue('barcode_no', text);
          fetchProductByBarcode(text, isInitialCreate);
          setOpenQRCodeModal(false)
        }}/>
      <Modal
        open={open}
        onClose={() => onClose()}
        closeIcon
        closeOnDimmerClick={false}>
        <Modal.Header><Icon name='box'/>{ isCreate ? 'เพิ่ม' : 'ข้อมูล' }สินค้า</Modal.Header>
        <Modal.Content>
          { errorMessages && <Message error>{errorMessages}</Message> }
          <Form>
            <Form.Field>
              <label>Barcode</label>
              <CustomInputField
                fluid
                action={ isCreate ? (
                  <Button
                    color='blue'
                    labelPosition='right'
                    icon='qrcode'
                    content='สแกน'
                    type='button'
                    onClick={() => setOpenQRCodeModal(true)}/>
                  ) : null
                }
                name='barcode_no'
                icon='barcode'
                iconPosition='left'
                placeholder='Barcode'
                value={formik.values.barcode_no}
                onChange={(e, data) => {
                  formik.handleChange(e, data)
                  debouncedFetch(data.value, isInitialCreate)
                }}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    let barcode = formik.values.barcode_no
                    if (barcode)
                      fetchProductByBarcode(barcode, isInitialCreate);
                  }
                }}/>
            </Form.Field>
            <Form.Group widths='equal'>
              <CustomInputField
                isForm
                name='name'
                label='ชื่อสินค้า'
                icon='box'
                iconPosition='left'
                placeholder='ชื่อสินค้า'
                value={formik.values.name}
                error={formik.errors && formik.errors.name}
                onChange={formik.handleChange}/>
              <Form.Field>
                <label>ประเภทสินค้า  <a onClick={() => setOpenAddCategory(true)}>+ เพิ่ม</a></label>
                <Form.Select
                  name='category'
                  search
                  clearable
                  options={getCategory()}
                  placeholder='ประเภทสินค้า'
                  value={formik.values.category}
                  error={formik.errors && formik.errors.category}
                  onChange={(_, data) => {
                    formik.setFieldValue('category', data.value ? data.value : null)
                  }}/>
              </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field>
                <label>ราคาทุน</label>
                <CustomInputField
                  name='cost_price'
                  placeholder='ราคาทุน'
                  icon='money bill alternate'
                  iconPosition='left'
                  label={{ tag: true, content: 'บาท' }}
                  labelPosition='right'
                  type='number'
                  step='1'
                  min='0'
                  value={formik.values.cost_price}
                  error={formik.errors.cost_price}
                  onChange={formik.handleChange}/>
              </Form.Field>
              <Form.Field>
                <label>ราคาขาย</label>
                <CustomInputField
                  name='sell_price'
                  placeholder='ราคาขาย'
                  icon='money bill alternate outline'
                  iconPosition='left'
                  label={{ tag: true, content: 'บาท' }}
                  labelPosition='right'
                  type='number'
                  step='1'
                  min='0'
                  value={formik.values.sell_price}
                  error={formik.errors.sell_price}
                  onChange={formik.handleChange}/>
              </Form.Field>
            </Form.Group>
            { setting.use_image_product && (
              <Form.Field>
                <label>รูปภาพสินค้า</label>
                { formik.values.image ? 
                  <Image src={formik.values.image} size='small'/> :
                  <Icon name='image outline' color='grey' size='massive' />
                }
                {/* <CustomFileField
                  isBase64
                  width={size == 'sm' ? '16' : '8'}
                  value={fileName ? fileName : ''}
                  onChange={(file, name) => {
                    formik.setFieldValue('image', file)
                    console.log(file)
                    setFile(file)
                    setFileName(name)
                  }}/> */}
                <CustomFileField
                  width={size == 'sm' ? '16' : '8'}
                  value={file ? file.name : ''}
                  onChange={(file) => setFile(file)} />
              </Form.Field>
            ) }
            <Form.TextArea
              name='note'
              label='หมายเหตุ'
              placeholder='หมายเหตุ'
              value={formik.values.note}
              error={formik.errors.note}
              onChange={formik.handleChange}/>
            <Form.Group widths='equal'>
              <CustomInputField
                isForm
                readOnly
                name='amount'
                label={
                  <label>
                    จำนวนในสต็อก 
                    { formik.values.id && formik.values.store && !isInitialCreate && 
                      <a onClick={() => setOpenAmountLog(true)}> <Icon name='eye'/>รายละเอียด</a> 
                    }
                  </label>
                }
                icon='boxes'
                action={
                  <Popup
                    inverted
                    hoverable
                    content='การแก้ไขจำนวนสามารถทำได้ผ่านการรับเข้าและตัดยอดสินค้า ที่เมนู "รับเข้าสินค้า" และ "ตัดยอดสินค้า"'
                    trigger={<Button basic icon='question'/>}/>
                }
                iconPosition='left'
                placeholder='จำนวนในสต็อก'
                type='number'
                value={formik.values.amount}/>
              <CustomInputField
                isForm
                name='min_stock'
                label='จำนวนแจ้งเตือนเมื่อใกล้หมด'
                icon='warning'
                iconPosition='left'
                placeholder='จำนวนแจ้งเตือนเมื่อใกล้หมด'
                type='number'
                step='1'
                min='0'
                value={formik.values.min_stock}
                error={formik.errors.min_stock}
                onChange={formik.handleChange}/>
            </Form.Group>
            <Form.Field 
              label={"สินค้ามีภาษีมูลค่าเพิ่ม"}
              control={Checkbox}
              checked={formik.values.has_vat}
              error={formik.errors.has_vat}
              onChange={(_, data) => formik.setFieldValue('has_vat', data.checked)}/>
            <ProductGroupSegment product={target != 'add' ? target : null} formik={formik}/>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button.Group floated="left">
            {!isCreate &&
              <Button
                loading={isLoading}
                content='ลบ'
                color='red'
                icon='trash alternate'
                onClick={() => setShowConfirmDelete(true)} />
            }
          </Button.Group>
          <Button loading={isLoading} content='ยกเลิก' icon='cancel' onClick={onClose} />
          <Button 
            type="submit"
            loading={isLoading}
            content={ isCreate ? 'เพิ่ม' : 'แก้ไข'}
            color={ isCreate ? 'green' : 'blue'}
            icon={ isCreate ? 'add' : 'edit'}
            onClick={formik.handleSubmit}/>
        </Modal.Actions>
        
        <Confirm
          open={showConfirmDelete}
          content={`ยืนยันลบสินค้า "${formik.values.name}"`}
          onCancel={() => setShowConfirmDelete(false)}
          onConfirm={handleDelete}
        />

      </Modal>
    </div>
  )
}