import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Header, Image } from 'semantic-ui-react';
import logo from '../logo.png';
import { PATH } from '../routes/CustomRoutes';


export default function NotFoundScreen() {

  const navigate = useNavigate();

  return (
    <div className='not-found'>
      <style>
        {`
          body > div,
          body > div > div,
          body > div > div > div.not-found {
            height: 100%;
          }
        `}
      </style>
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle' centered>
          <Grid.Column textAlign='center'>
            <Image src={logo} size='medium' centered/>
            <Header color='red' style={{fontSize: '6rem'}}>404</Header>
            <Header size='large' color='grey'>ไม่พบหน้าดังกล่าว</Header>
            <Button 
              content={'Back'}
              color='blue'
              size='big'
              icon='arrow left'
              onClick={() => navigate(PATH.MAIN)}/>
          </Grid.Column>
        </Grid>
    </div>
  )
}