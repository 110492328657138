import React from 'react';
import { Divider, Grid, Header, Icon, Menu, Segment } from 'semantic-ui-react';
import { COLOR_SET, TH_MONTH } from '../../constance/utils';
import { ellipsisString, formatComma } from '../../frameworks/Util';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, plugins } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import * as _ from 'lodash';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Media } from '../../AppMedia';
import Swipable from '../common/Swipable';


ChartJS.register(ArcElement, Tooltip, Legend, plugins);

const ratioColorSet = [
  'teal',
  'blue',
  'olive',
  'green',
  'yellow',
  'orange',
  'violet',
  'purple',
  'pink',
  'red',
  'grey',
  'black',
].map(color => COLOR_SET[color].code)


const Pie = (props) => {
  const {
    data,
    title,
    showSubtitle,
    timeScale,
    datasetKey,
    icon,
    color,
    unit,
  } = props;

  const { size } = useWindowDimensions();
  const [chartData, setChartData] = React.useState({ datasets: [] })
  const [centerText, setCenterText] = React.useState('');
  const totalAmount = _.get(_.reverse(data), datasetKey, []).map(e => _.get(Object.entries(e), '0.1', 0)).reduce((p, c) => p + c, Number(0));
  const [centerAmount, setCenterAmount] = React.useState(0);
  const chartRef = React.useRef();

  const getCenterAmountText = () => {
    if (!data || data.length === 0) {
      return '';
    }
    return `${formatComma(centerAmount, true)} ${unit}`
  }

  const getCenterTitleText = () => {
    if (!data || data.length === 0) {
      return '';
    } else if (centerText) {
      return centerText;
    }
    return 'ทั้งหมด'; 
  }

  const renderSubtitle = () => {
    const date = _.get(data, 'date', '')
    const [year, month, day] = date.split('-');
    const pointDate = new Date(year, month-1, day)
    if (timeScale === 'day') {
      return (
        <div>
          <div>รายวัน</div>
          {pointDate.toLocaleDateString('en-GB')}
        </div>
      )
    } else if (timeScale === 'week') {
      const today = new Date();
      today.setDate(today.getDate() - 1);
      return (
        <div>
          <div>รายสัปดาห์</div>
          {`${pointDate.toLocaleDateString('en-GB')} - ${today.toLocaleDateString('en-GB')}`}
        </div>
      )
    } else if (timeScale === 'month') {
      return (
        <div>
          <div>รายเดือน</div>
          {`${TH_MONTH[pointDate.getMonth()]} ${pointDate.getFullYear()}`}
        </div>
      )
    } else if (timeScale === 'year') {
      return (
        <div>
          <div>รายปี</div>
          {`${pointDate.getFullYear()}`}
        </div>
      )
    }
    return ''
  }

  const options = {
    onHover: (e, element) => {
      if(element.length > 0) {
        let index = _.get(element, '0.index', 0)
        setCenterText(_.get(chartData, `labels.${index}`, ''))
        setCenterAmount(_.get(chartData, `datasets.0.data.${index}`, ''))
      } else {
        setCenterText('');
        setCenterAmount(totalAmount);
      }
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: size === 'sm' ? false : true,
        position: (size === 'md' || size === 'sm')? 'top' : 'left',
        labels: {
          fontSize: size === 'sm' ? 12 : 18,
          usePointStyle: true,
          boxWidth: 12,
          generateLabels: (chart) => {
            if (chart.data.labels.length > 0) {
              return chart.data.labels.map((label, index) => {
                const datasets = chart.data.datasets[0]
                const meta = chart.getDatasetMeta(0)

                const data = datasets.data[index];
                const text = `${ellipsisString(label)} (${formatComma(Number(data/totalAmount*100).toFixed(9))}%)`;
                const fillStyle = datasets.backgroundColor[index];
                const strokeStyle = datasets.backgroundColor[index];
                const hidden = meta.data[index].hidden;

                return {
                  text,
                  fillStyle,
                  strokeStyle,
                  hidden,
                  index,
                };
              })
            } else {
              return [];
            }
          }
        }
      },
      tooltips: {
        bodyFontSize: 16,
        callbacks: {
          label: (tooltipItem) => `
            ${_.get(chartData, `labels.${tooltipItem.index}`, '')}: 
            ${formatComma(_.get(chartData, `datasets.0.data.${tooltipItem.index}`, ''), true)} ${unit}
          `, 
        }
      },
    },
    elements: {
      center: {
        amountText: getCenterAmountText(), 
        titleText: getCenterTitleText(),
      }
    },
    cutout: '65%',
  };

  const plugins = {
    beforeDraw: (chart) => {
      if (chart.config.options.elements.center) {
        const ctx = chart.ctx;
        const centerConfig = chart.config.options.elements.center;
        const fontStyle = centerConfig.fontStyle || 'Lato';
        const titleTxt = centerConfig.titleText;
        const titleColor = '#c0c1c2';
        const amountTxt = centerConfig.amountText;
        const amountColor = '#343a40';
        const innerRadius = _.get(chart, '_metasets.0.controller.innerRadius', 0)

        let maxFontSize = centerConfig.maxFontSize || 75;
        let sidePadding = centerConfig.sidePadding || 20;
        let sidePaddingCalculated = (sidePadding / 100) * (innerRadius * 2)

        ctx.font = `30px ${fontStyle}`;
        let elementHeight = (innerRadius * 2);
        let elementWidth = (innerRadius * 2) - sidePaddingCalculated;

        let stringWidth = Math.max(ctx.measureText(titleTxt).width, ctx.measureText(amountTxt).width);
        let amountWidth = ctx.measureText(amountTxt).width

        let widthRatio = elementWidth / stringWidth;
        let amountWidthRatio = elementWidth / amountWidth;

        let newFontSize = Math.floor(30 * widthRatio);
        let amountFontSize = Math.floor(30 * amountWidthRatio);

        let minFontSize = centerConfig.minFontSize || 36;
        let titlefontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize, minFontSize);
        let amountfontSizeToUse = Math.min(amountFontSize, elementHeight, maxFontSize, minFontSize);
        let textRatio = amountfontSizeToUse / titlefontSizeToUse
        let lineHeight = centerConfig.lineHeight || 36;

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
        let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);

        ctx.font = `${amountfontSizeToUse}px ${fontStyle}`;
        ctx.fillStyle = amountColor;
        ctx.fillText(amountTxt, centerX, centerY);

        ctx.font = `${titlefontSizeToUse/(2/textRatio)}px ${fontStyle}`;
        ctx.fillStyle = titleColor;

        const maxString = {
          'xl': 20,
          'lg': 15,
          'md': 10,
          'sm': 10,
        }

        ctx.fillText(ellipsisString(titleTxt, maxString[size]), centerX, centerY + (lineHeight+(2/textRatio)));
      }
    }
  }

  React.useEffect(() => {
    setCenterAmount(totalAmount)
    setChartData({
      labels: _.get(_.reverse(data), datasetKey, []).map(e => _.get(Object.keys(e), 0, '')),
      datasets: [{
        data: _.get(_.reverse(data), datasetKey, []).map(e => _.get(Object.entries(e), '0.1', 0)),
        backgroundColor: ratioColorSet,
        hoverBackgroundColor: ratioColorSet,
      }]
    })

    if (chartRef.current) {
      chartRef.current.resize(chartRef.current.width, 350);
    }
  }, [data])

  return (
    <Segment>
      <Header as='h4'>
        <Icon color={color} name={icon}/>
        <Header.Content>
          {title}
          <Header.Subheader>
            {showSubtitle ? renderSubtitle() : null}
          </Header.Subheader>
        </Header.Content>
      </Header>
      <div>
        <Doughnut
          ref={chartRef}
          data={chartData}
          options={options}
          plugins={[plugins]}/>
      </div>
    </Segment>
  )
}


export default function PieSegment(props) {
  const {
    isList,
    widths,
    data,
    timeScale,
    menus,
  } = props;

  const [activeIndex, setActiveIndex] = React.useState(0);

  return (
    <>
      <Media lessThan='lg'>
        <Swipable
          style={{ marginBottom: '1rem' }}
          onSwipeLeft={() => setActiveIndex(i => (i < menus.flat().length-1) ? i+1 : 0)}
          onSwipeRight={() => setActiveIndex(i => (i > 0) ? i-1 : menus.flat().length-1)}
        >
          <Menu secondary style={{ justifyContent: 'space-evenly' }}> 
            <Menu.Item
              icon='left arrow'
              onClick={() => setActiveIndex(i => (i > 0) ? i-1 : menus.flat().length-1)}/>
            <Menu.Item
              name={_.get(menus.flat(), `${activeIndex}.title`)}
              color={_.get(menus.flat(), `${activeIndex}.color`)}
              icon={_.get(menus.flat(), `${activeIndex}.icon`)}
              active/>
            <Menu.Item
              icon='right arrow'
              onClick={() => setActiveIndex(i => (i < menus.flat().length-1) ? i+1 : 0)}/>
          </Menu>
        </Swipable>
      </Media>
      <Media greaterThanOrEqual='lg'>
        <Grid columns={widths ? widths : '3'} stackable>
          { menus.map(menu => (
            <Grid.Column>
              <Pie
                data={isList ? {..._.get(data, 0, {})} : {...data}}
                timeScale={timeScale}
                {...menu}/>
            </Grid.Column>
          )) }
        </Grid>
      </Media>
      <Media lessThan='lg'>
        <Pie
          data={isList ? {..._.get(data, 0, {})} : {...data}}
          timeScale={timeScale}
          {..._.get(menus, activeIndex, {})}/>
      </Media>
    </>
  )
}