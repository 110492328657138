import React from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-table-v6/react-table.css'
import { toast } from 'react-toastify';
import { Dimmer, Loader } from 'semantic-ui-react';
import { BrowserRouter as Router } from "react-router-dom";
import { MediaContextProvider } from "./AppMedia";
import CustomRoutes from './routes/CustomRoutes';
import { Toast } from './frameworks/Toast';
import StoreProvider from './hooks/StoreProvider';


toast.configure();

function App({ listen }) {

  React.useEffect(() => {
    listen(status => {
      Toast.info(status);
    })    
  });

  return (
    <MediaContextProvider>
      <StoreProvider>
        <React.Suspense fallback={
          <Dimmer active>
            <Loader>loading...</Loader>
          </Dimmer>
        }>
          <Router>
            <CustomRoutes/>
          </Router>
        </React.Suspense>
      </StoreProvider>
    </MediaContextProvider>
  );
}

export default App;