import React from "react";
import { Button, Popup } from "semantic-ui-react";
import MenuBar from "../components/common/MenuBar";
import ShareDocumentModal from "../components/common/ShareDocumentModal";
import { URL_COMMON } from "../constance/urls";
import { PATH } from "../routes/CustomRoutes";
import OfficerProductListView from "../views/OfficerProductListView";
import OfficerStoreView from "../views/OfficerStoreView";
import ManualButton from "../components/common/ManualButton";


export default function OfficerMainScreen() {
  const [activeItem, setActiveItem] = React.useState('store');

  const menus = [
    { value: 'store', text: 'ร้านค้า' },
    // { value: 'product', text: 'สินค้า' },
    // { value: 'report', text: 'สรุปยอดขาย' },
  ]

  const renderContext = () => {
    switch(activeItem) {
      case 'store': return <OfficerStoreView/>;
      case 'product': return <OfficerProductListView/>;
      default: return null;
    }
  }

  return (
    <div>
      <MenuBar activeItem={activeItem} setActiveItem={setActiveItem} items={menus} logOutPath={PATH.OFFICER_LOGIN} >
        {renderContext()}
      </MenuBar>
      <ManualButton isOfficer/>
    </div>
  )
}