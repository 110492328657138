import React from 'react';
import MenuBar from '../components/common/MenuBar';
import CustomerListView from '../views/CustomerListView';
import ReportView from '../views/ReportView';
import SaleTransactionView from '../views/SaleTransactionView';
import StoreSettingView from '../views/StoreSettingView';
import * as _ from 'lodash';
import TaxInvoiceListView from '../views/TaxInvoiceListView';
import ProductView from '../views/ProductViews';
import { useStoreContext } from '../hooks/StoreProvider';


export default function MainScreen(props) {

  const { setting } = useStoreContext()
  const { staff } = setting;

  const [activeItem, setActiveItem] = React.useState(null);

  const menus = [
    { value: 'product', text: 'สินค้า' },
    { value: 'customer', text: 'ลูกค้า' },
    {
      value: 'sale',
      text: 'ประวัติการซื้อขาย',
      permissions: setting.use_staff ? staff.show_sale_transaction : true
    },
    {
      value: 'tax_invoice',
      text: 'ใบกำกับภาษี',
      permissions: (setting.use_staff ? staff.show_sale_transaction : true) &&  setting.use_tax_invoice
    },
    {
      value: 'report',
      text: 'สรุปยอดขาย',
      permissions: setting.use_staff ? staff.show_dashboard : true
    },
    {
      value: 'setting',
      text: 'ตั้งค่า',
      permissions: setting.use_staff ? staff.edit_store : true
    },
  ]

  const renderContext = () => {
    switch (activeItem) {
      case 'product': return <ProductView/>;
      case 'customer': return <CustomerListView/>;
      case 'sale': return <SaleTransactionView/>;
      case 'tax_invoice': return <TaxInvoiceListView/>;
      case 'report': return <ReportView/>;
      case 'setting': return <StoreSettingView/>;
      default: return null;
    }
  }

  const initialActiveMenu = () => {
    if (!activeItem) {
      if (setting.use_staff && staff.show_dashboard) {
        setActiveItem('report');
      } else {
        setActiveItem('product');
      }
    } else {
      if (setting.use_staff && !staff.is_manager)
        setActiveItem('product')
    }
  }

  React.useEffect(() => {
    initialActiveMenu();
  }, [staff])

  return (
    <div>
      <MenuBar 
        activeItem={activeItem} 
        setActiveItem={setActiveItem} 
        items={menus}>
        {renderContext()}

      </MenuBar>
    </div>
  )
}
