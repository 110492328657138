import React from "react";
import Cookies from 'js-cookie'
import { Navigate, Outlet } from "react-router-dom";
import { PATH } from "./CustomRoutes";
import * as _ from 'lodash';
import NoStoreScreen from "../screens/NoStoreScreen";
import ManualButton from "../components/common/ManualButton";
import { useStoreContext } from "../hooks/StoreProvider";
import LoginPINModal from "../components/common/LoginPinModal";


export default function PrivateOutlet() {
  const auth = Cookies.get('token') != null;
  const { setting, fetchStoreSetting, setStaff } = useStoreContext()
  const { staff } = setting

  const isUserHasStore = () => {
    try {
      const items = _.get(JSON.parse(Cookies.get('user')), 'store', null)
      return items != null ? true : false
    } catch(error){
      Cookies.remove('user')
      return false
    }  
  }

  React.useEffect(() => {
    fetchStoreSetting()
  }, [])

  return auth ? 
    isUserHasStore() ? 
      <>
        <LoginPINModal
          needStaffLogin={setting.use_staff && !staff.id}
          onLoginSuccess={setStaff}
          data={staff}/>
        <ManualButton/>
        <Outlet /> 
      </>
      : <NoStoreScreen/> 
    : <Navigate to={PATH.LOGIN} />;
}