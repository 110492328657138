import React from "react";
import { Dimmer, Form, List, Loader, Message, Modal, Segment } from "semantic-ui-react";
import { URL_SALE, URL_STAFF } from "../../constance/urls";
import { StaffShiftModel } from "../../constance/models";
import { GET } from "../../frameworks/HttpClient";
import { dateTimeToString, formatComma, getColumnsList } from "../../frameworks/Util";
import moment from "moment";
import useListItem from "../../hooks/useItemList";
import * as _ from "lodash";
import PaginationTable from "../common/PaginationTable";


export default function StaffShiftReportModal(props) {
  const {
    open,
    target,
    onClose,
  } = props;

  const [isLoading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
  const [detail, setDetail] = React.useState(StaffShiftModel)
  const [page, setPage] = React.useState(1);
  const saleTransactionList = useListItem({ 
    url: URL_SALE.SALE_TRANSACTION,
    params: {
      date_after: _.get(detail, 'start_time', false) ? moment(detail.start_time).format('YYYY-MM-DD') : null,
			date_before: _.get(detail, 'end_time', false) ? moment(detail.end_time).format('YYYY-MM-DD') : null,
      staff: _.get(detail, 'staff', null),
    },
    usePagination: true,
  });

  const columns = React.useMemo(() => [
    ...getColumnsList([
			{ Header: 'เลขที่บิล', accessor: 'receipt_no' },
			{ Header: 'เวลาลงบิล', accessor: 'paid_datetime', Cell: ({value}) => dateTimeToString(value) },
			{ Header: 'ราคาบิล (บาท)', accessor: 'net_price', Cell: ({value}) => formatComma(value) },
			{ Header: 'สินค้า', accessor: 'sale_products', width: 250, Cell: ({value}) => (
        <List as='ul'>
          { value.map(sale_product => (
            <List.Item as='li'>{_.get(sale_product, 'product_name')} : {formatComma(_.get(sale_product, 'amount', 0))}</List.Item>
          )) }
        </List>
      ) },
    ])
  ])

  const fetchData = async () => {
    setLoading(true)
    setErrorMessages(null)
    try {
      const response = await GET(`${URL_STAFF.STAFF_SHIFT}${target}/`)
      setDetail(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (open) {
      fetchData()
    } else {
      setPage(1);
    }
  }, [open])

  React.useEffect(() => {
    if (detail.id) {
        saleTransactionList.fetchData();
    }
  }, [detail.id])

  React.useEffect(() => {
    if (detail.id) {
      saleTransactionList.fetchData({page: page})
    }
  }, [page])

  return (
    <Modal open={open} onClose={onClose} closeIcon>
      <Modal.Header>ข้อมูลกะการทำงาน</Modal.Header>
      <Modal.Content>
        <Dimmer active={isLoading || saleTransactionList.isLoading} inverted><Loader inverted/></Dimmer>
        { errorMessages && <Message error>{errorMessages}</Message> }
        <Segment>
          <Form>
            <Form.Group>
              <Form.Input label='พนักงาน' transparent value={detail.staff_name} />
              <Form.Input label='เริ่มกะ' transparent value={dateTimeToString(detail.start_time)} />
              <Form.Input label='ออกกะ' transparent value={dateTimeToString(detail.end_time)} />
            </Form.Group>
            <Form.Group>
              <Form.Input label='รายได้ทั้งหมด (บาท)' transparent value={formatComma(_.get(detail, 'summary.total_gain', 0))} />
              <Form.Input label='ส่วนลด (บาท)' transparent value={formatComma(_.get(detail, 'summary.total_discount', 0))} />
              <Form.Input label='กำไร (บาท)' transparent value={formatComma(_.get(detail, 'summary.total_profit', 0))} />
            </Form.Group>
            <Form.Group>
              <Form.Input label='จำนวนใบเสร็จ' transparent value={formatComma(_.get(detail, 'summary.total_transaction', 0))} />
              <Form.Input label='จำนวนสินค้าที่ขายได้' transparent value={formatComma(_.get(detail, 'summary.total_product_count', 0))} />
              <Form.Input label='ราคารวมสินค้าไม่มีภาษี' transparent value={formatComma(_.get(detail, 'summary.total_no_vat', 0))} />
              <Form.Input label='ราคารวมสินค้ามีภาษี' transparent value={formatComma(_.get(detail, 'summary.total_include_vat', 0))} />
            </Form.Group>
          </Form>
        </Segment>
        { saleTransactionList.errorMessages && <Message error>{saleTransactionList.errorMessages}</Message> }
        <PaginationTable
          columns={columns}
          data={saleTransactionList.response}
          onPageChange={(page) => setPage(page)}/>
      </Modal.Content>
    </Modal>
  )

}