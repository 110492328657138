import React from 'react';
import { Confirm, Grid, Icon, Image, Menu, Sidebar } from 'semantic-ui-react';
import { Media } from '../../AppMedia';
import logo from '../../logo.png';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { PATH } from '../../routes/CustomRoutes';
import { useStoreContext } from '../../hooks/StoreProvider';


export default function MenuBar(props) {
  const { children, items, activeItem, setActiveItem, logOutPath } = props;

  const { setting, removeStoreSetting } = useStoreContext();
  const { staff } = setting;

  const navigate = useNavigate();
  const [isMenuVisible, setIsMenuVisible] = React.useState(false);
  const [logoutVisible, setLogoutVisible] = React.useState(false);

  const handleLogout = () => {
    setLogoutVisible(false);
    Cookies.remove('token');
    Cookies.remove('user');
    removeStoreSetting()
    navigate(logOutPath ? logOutPath : PATH.LOGIN);
  }

  const renderMenuItem = () => {
    return [
      ...items.filter(e => e.permissions != false).map(e => (
        <Menu.Item
          key={`${e.value}_menu`}
          active={e.value === activeItem}
          style={{ fontSize: 16, padding: 16 }}
          name={e.text}
          onClick={() => setActiveItem(e.value)}/>
      )),
      <Menu.Item
        key='logout_menu'
        style={{ fontSize: 16, padding: 16 }}
        name='ออกจากระบบ'
        onClick={() => setLogoutVisible(true)}/>
    ]
  }

  const ToggleMenuLayout = () => {
    return (
      <Grid style={{ height: '100vh', margin: 0 }}>
        <Grid.Row style={{ padding: 0 }} columns='1'>
          <Sidebar.Pushable style={{ padding: 0 }} as={Grid.Column}>
            <Sidebar
              as={Menu}
              animation='overlay'
              direction='left'
              icon='labeled'
              inverted
              onHide={() => setIsMenuVisible(false)}
              vertical
              visible={isMenuVisible}
              width='thin'>
              {renderMenuItem()}
            </Sidebar>
            <Sidebar.Pusher dimmed={isMenuVisible}>
              <Menu inverted pointing style={{height: '100%', borderRadius: 0}}>
                <Menu.Item>
                  <Icon size='big' name='bars' onClick={() => setIsMenuVisible(true)}/>
                </Menu.Item>
                <Menu.Item>
                  <Image style={{ height: 32 }} centered src={logo}/>
                </Menu.Item>
                {staff.first_name && (
                  <Menu.Item position={"right"}>{staff.first_name}</Menu.Item>
                )}
                
              </Menu>
              {children}
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Grid.Row>
      </Grid>
    )
  }

  const SideBarLayout = () => {
    return (
      <Grid style={{ height: '100vh', margin: 0 }}>
        <Grid.Row style={{ padding: 0 }} columns='2'>
          <Grid.Column width='3' style={{padding: 0}} textAlign='left'>
            <Menu fluid vertical inverted style={{height: '100%', borderRadius: 0}}>
              <Menu.Item textAlign={"center"}>
                <Image src={logo} style={{width: '50%', margin: 'auto', padding: 0 }} />
                {staff.first_name && (
                  <p style={{marginTop: 12, textAlign: "center"}}>{staff.first_name}</p>
                )}               
              </Menu.Item>
              {renderMenuItem()}
            </Menu>
          </Grid.Column>
          <Grid.Column width='13'>
            {children}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  return (
    <div>
      <Confirm 
        open={logoutVisible}
        content='ต้องการออกจากระบบใช่หรือไม่?'
        cancelButton='ยกเลิก'
        confirmButton='ออกจากระบบ'
        onCancel={() => setLogoutVisible(false)}
        onConfirm={handleLogout}/>
      <Media at='sm'>
        {ToggleMenuLayout()}
      </Media>
      <Media greaterThan='sm'>
        {SideBarLayout()}
      </Media>
    </div>
  )
}

MenuBar.defaultProps = {
  children: null,
  items: [],
  activeItem: '',
  setActiveItem: () => null,
}

MenuBar.propTypes = {
  children: PropTypes.object,
  items: PropTypes.array,
  activeItem: PropTypes.string,
  setActiveItem: PropTypes.func,
}