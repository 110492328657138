import * as _ from "lodash";


export const ProductCategoryModel = {
  id: null,
  is_template: false,
  name: '',
}

export const ProductModel = {
  id: null,
  name: '',
  barcode_no: '',
  store: null,
  image: null,
  note: '',
  category: null,
  cost_price: 0,
  sell_price: 0,
  amount: 0,
  min_stock: 0,
  is_active: true,
  has_vat: true,
  is_active: true,
  category_detail: null,
  sub_products: [],
}

export const ProductLinkModel = {
  id: null,
  product: null,
  target: null,
  target_name: '',
  amount: 1,
}

export const productSearchRenderer = (e) => ({
  ...e, 
  key: e.id,
  value: e.id,
  title: e.name,
  description: `${_.get(e, 'barcode_no', false) ? `รหัส: ${e.barcode_no}` : ''}
    ${_.get(e, 'barcode_no', false) && _.get(e, 'category_detail.name', false) ? ', ': ''}
    ${_.get(e, 'category_detail.name', false) ? `ประเภทสินค้า: ${e.category_detail.name}` : ''}`,
  price: `${e.cost_price} ฿`,
})